.map-block {
    position: relative;

    &__text {
        padding: 30px;
        position: relative;

        .green & {
            background-color: $green; }

        .blue & {
            background-color: $brightblue; }

        .purple & {
            background-color: $purple; }

        .red & {
            background-color: $red; }

        .yellow & {
            background-color: $yellow; }

        h2,
        p,
        a {
            color: $white;

            .yellow & {
                color: $purple; } }

        p {
            margin-bottom: 20px; } }

    &__map {
        position: relative; }

    @include breakpoint-context($tablet) {
        height: 33em;
        overflow: hidden;

        &__text {
            height: calc(100% - 120px);
            padding: 60px;

            .green & {
                background: linear-gradient(to right, rgba($green, 1) 0%, rgba($green, 1) 80%, rgba(0, 0, 0, 0) 100%); }

            .blue & {
                background: linear-gradient(to right, rgba($brightblue, 1) 0%, rgba($brightblue, 1) 80%, rgba(0, 0, 0, 0) 100%); }

            .purple & {
                background: linear-gradient(to right, rgba($purple, 1) 0%, rgba($purple, 1) 80%, rgba(0, 0, 0, 0) 100%); }

            .red & {
                background: linear-gradient(to right, rgba($red, 1) 0%, rgba($red, 1) 80%, rgba(0, 0, 0, 0) 100%); }

            .yellow & {
                background: linear-gradient(to right, rgba($yellow, 1) 0%, rgba($yellow, 1) 80%, rgba(0, 0, 0, 0) 100%); } }

        &__map {
            @include position(absolute, 0 null null 0);
            height: 100% !important;
            margin-left: 295px;
            width: calc(100% - 295px) !important; }

        &__container {
            @include position(absolute, -300px 55% -300px 0);
            border-bottom-right-radius: 50%;
            border-top-right-radius: 50%;
            padding: 300px 50px 300px 0;
            z-index: 999;

            .green & {
                background-color: rgba($green, .9); }

            .blue & {
                background-color: rgba($brightblue, .9); }

            .purple & {
                background-color: rgba($purple, .9); }

            .red & {
                background-color: rgba($red, .9); }

            .yellow & {
                background-color: rgba($yellow, .9); } } } }
