// progress bar
//
// Markup: ./progress-bar.hbs
//
// Styleguide components.progress bar

.progress-bar {
    $this: &;
    background: $lily-white;
    border-radius: 5px;
    height: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &__complete {
        @include position(absolute, 0 null null 0);
        background: $apple;
        border-radius: 5px;
        content: '';
        height: 10px;
        width: 10%; } }
