.accordion-content {
    padding-top: 60px;
    padding-bottom: 80px;

    @media (min-width: 768px) {
        padding-top: 90px;
        padding-bottom: 90px; }

    &__intro {
        text-align: center;

        + * {
            margin-top: 85px; }

        h2 {
            font-size: 36px;

            @media (min-width: 768px) {
                font-size: 50px; }

            + * {
                margin-top: 32px; } } }

    &__item > h2 {
        margin-bottom: 0; }

    &__list {
        border-bottom: 1px solid #070707; }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        font-size: 28px;
        font-family: $heading-font-family;
        color: $rose;
        background-color: transparent;
        border: 0;
        border-top: 1px solid #070707;
        border-radius: 0;
        padding: 20px 0;
        cursor: pointer;

        @media (min-width: 768px) {
            font-size: 36px;
            padding: 30px 0; }

        > * {
            pointer-events: none; } }

    &__icon {
        position: relative;
        width: 30px;
        height: 30px;
        background-color: $rose;
        color: #fff;
        border-radius: 50%;

        @media (min-width: 768px) {
            width: 46px;
            height: 46px; }

        span:before,
        span:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 18px;
            height: 2px;
            background-color: currentColor;
            transform: translate(-50%, -50%);
            transition: transform 250ms ease-out;

            @media (min-width: 768px) {
                width: 25px; } }


        span:after {
            transform: translate(-50%, -50%) rotate(-90deg); } }

    &__item.is-active &__icon span::after {
        transform: translate(-50%, -50%);
        transition-duration: 400ms; }

    &__container {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height 250ms ease-out; }

    &__item.is-active &__container {
        height: auto;
        transition-duration: 400ms; }

    &__content {
        padding-bottom: 26px; } }
