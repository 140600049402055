// unclassed typography
//
// Default typography styles, applied to the body
//
// Markup:
// Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet labore, quo odit debitis natus sit omnis aliquam earum, perspiciatis possimus!
//
// Styleguide elements.typography.unclassed

body {
    color: $mine-shaft;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height; }

// headings
//
// N.B. The class `.heading` does not exist, please use one of the modifiers below
//
// .heading--1 - Heading 1
// .heading--2 - Heading 2
// .heading--3 - Heading 3
// .heading--4 - Heading 4
// .heading--secondary - Heading secondary
// .heading--purple - Heading purple
// .heading--thunderbird - Heading thunderbird
// .heading--orange - Heading orange
// .heading--light - Heading light
//
// Markup: <h1 class="{{modifier_class}}">Heading Text</h1>
//
// Styleguide elements.typography.headings

// headings decoration
//
// .heading--secondary - Heading secondary
// .heading--purple - Heading purple
// .heading--deepcerulean - Heading deepcerulean
// .heading--thunderbird - Heading thunderbird
// .heading--orange - Heading orange
// .heading--rose - Heading rose
//
// Markup: <h1 class="heading--has-icon {{modifier_class}}"><object data="/application/themes/rawnet/app/images/icons/flower.svg" type="image/svg+xml" class="heading__icon"></object>Heading Text</h1>
//
// Styleguide elements.typography.headings decoration

// TODO: Remove these lines when elements are converted
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $rose;
    font-family: $heading-font-stack;
    line-height: 1.5; }

h1,.h1 {
    font-size: 2.66667em; }

h2 {
    font-size: 1.66667em;
    line-height: 1.5em; }

h3 {
    font-size: 1.33333em; }

h4 {
    font-size: 1.22222em;
    line-height: 1.36364em; }

h5,
h6 {
    font-size: 1em; }

%heading {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    &--1 {
        @extend %heading; }

    &--2 {
        @extend %heading; }

    &--3 {
        @extend %heading; }

    &--4 {
        @extend %heading; } }

.heading {
    $this: &;

    &--1 {
        @extend %heading--1;
        font-size: rem(42);

        @media(min-width: 1024px) {
            font-size: rem(50); } }

    &--2 {
        @extend %heading--2;
        font-size: rem(36); }

    &--3 {
        @extend %heading--3; }

    &--4 {
        @extend %heading--4;
        font-size: rem(18); }

    &--secondary,
    &--blue {
        color: $cerulean;

        .heading__icon {
            fill: $cerulean; } }

    &--deepcerulean {
        color: $deepcerulean;

        .heading__icon {
            fill: $deepcerulean; } }

    &--purple {
        color: $affair;

        .heading__icon {
            fill: $affair; } }

    &--orange {
        color: $christine;

        .heading__icon {
            fill: $christine; } }

    &--thunderbird,
    &--red {
        color: $thunderbird;

        .heading__icon {
            fill: $thunderbird; } }

    &--rose {
        color: $rose;

        .heading__icon {
            fill: $rose; } }

    &--rio-grande {
        color: $rio-grande; }

    &--light,
    &--white {
        color: $white; }

    &--has-icon {
        align-items: center;
        display: flex;

        #{$this}__icon {
            display: block;
            height: 50px;
            margin-right: 15px;
            width: 65px; } } }

// TODO: Remove these 2 lines when elements are replaced
.page_title {
    margin-bottom: 1.5em; }

// links
//
// .anchor--has-arrow - An anchor link with an arrow
//
// Markup: ./anchor-link.hbs
//
// Styleguide elements.typography.links

a,
.anchor {
    color: $link-color;
    outline: none;
    text-decoration: none;
    transition-duration: .2s;
    transition-property: background-color, color;

    &:hover {
        color: $link-hover-color; }

    &--has-arrow {
        align-items: center;
        display: flex; } }

// paragraph
//
// Markup: <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet labore, quo odit debitis natus sit omnis aliquam earum, perspiciatis possimus!</p>
//
// Styleguide elements.typography.paragraph

p {
    margin-bottom: 1.5em; }

// strong/bold
//
// Markup: <p><strong>Lorem ipsum dolor sit amet</strong>, consectetur adipisicing elit. <b>Amet labore</b>, quo odit debitis natus sit omnis aliquam earum, perspiciatis possimus!</p>
//
// Styleguide elements.typography.strong/bold

strong,
b {
    font-weight: 700; }

// em/i
//
// Markup: <p><em>Lorem ipsum dolor sit amet</em>, consectetur adipisicing elit. <i>Amet labore</i>, quo odit debitis natus sit omnis aliquam earum, perspiciatis possimus!</p>
//
// Styleguide elements.typography.em/i

em,
i {
    font-style: italic; }

.text-small {
    font-size: 12px;
    line-height: 1.5; }

.text {
    &--light {
        color: $white; }

    &--large {
        font-size: 18px; } }

// Resets a button to a text link
.text-link {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &__icon {
        height: 7px;

        &--rose {
            fill: $rose; }

        &--deepcerulean {
            fill: $deepcerulean; }

        &--cerulean2 {
            fill: $cerulean2; }

        &--purple {
            fill: $affair; }

        &--orange {
            fill: $christine; }

        &--thunderbird {
            fill: $thunderbird; } }

    &--deepcerulean {
        color: $deepcerulean; }

    &--cerulean2 {
        color: $cerulean2; }

    &--purple {
        color: $affair; }

    &--orange {
        color: $christine; }

    &--thunderbird {
        color: $thunderbird; }

    &--rose {
        color: $rose; }

    &--white {
        color: $white; }

    &--small {
        font-size: rem(12); }

    &--large {
        font-size: rem(18); }

    &--has-icon {
        align-items: center;
        display: flex; } }

// Screen Reader Only
//
// Apply this class when you require something for screen readers but do not want it visible on the page
//
// Markup:
// <h1 class="sr-only">screen reader only</h1>
//
// Styleguide utilities.typography.screen reader only
.sr-only {
    @include size(1px);
    border: 0;
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; }
.thank-you-text {
    font-size: 1.125rem; }
.thank-you-title {
    font-size: 2.25rem; }
