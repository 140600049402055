// Default form styling
//****************************************************************************

#form_wrapper {
  @extend %gradient_purple;
  @include padding-leader(2);
  @include padding-trailer(3); }

.basic_form {
  @include legacy-pie-clearfix;

  fieldset {
    @include box-sizing('border-box');
    width: 80%;
    margin-left: 5.71429%;
    margin-right: 5.71429%;

    &.buttons {
      @include leader(.8); }

    &.with_border {
      border-top: 2px solid $darkpurple; }

    fieldset.inline_fs {
      margin: 0;
      padding: 0; } }

  .form-group {
    margin-bottom: 10px; }

  .container {
    width: 710px;
    max-width: 100%;
    margin: 0 auto; }

  legend {
    font-family: $heading-font-stack;
    @include adjust-font-size-to(36px);
    @include padding-trailer(.5);
    color: $white;
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  label {
    @include trailer(.2);
    display: inline-block;
    color: $white;
    a {
      color: $white;
      text-decoration: underline; } }

  span.text-muted.small {
    display: inline-block;
    font-size: 0;

    &::after {
      content: '*';
      color: white;
      font-size: 18px; } }

  .field {
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  input,
  textarea {
    @extend %default-font;
    @include adjust-font-size-to($base-font-size, 1);
    @include box-sizing(border-box);
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.2));
    @include transition-property('all');
    @include transition-duration(200ms);
    width: 100%;
    background-color: #fff;
    color: $text-color;
    border: none;
    padding: 12px 10px;
    margin: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    &:focus {
      border-color: $primary-color;
      outline: none; }
    &.parsley-error {
      background: red; } }

  select {
    &.parsley-error {
      &+ .customSelect {
        background: red; } } }

  input {
    height: 50px;

    &[type=radio],
    &[type=checkbox] {
      @include box-shadow( none );
      display: inline-block;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent; }

    &[type=radio] {
      position: relative;
      float: left;
      width: auto;

      &::before {
        content: "";
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 10px 0 0;
        box-shadow: 0 8px 8px rgba(0,0,0,.3); }

      &:checked::after {
        content: "";
        background: #233e91;
        height: 15px;
        width: 15px;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 8px; } } }

  textarea {
    height: rhythm(5.5); }

  select {
    max-width: 100%;
    width: 100%; }

  span.required {
    @extend %bold-font;
    color: $green;
    padding-left: 3px; }

  input[type='file'] {
    line-height: 1em;
    font-size: 16px; }

  .note {
    @include adjust_font_size_to(14px);
    // margin-left: gutter-span()
    color: $white;
    margin-top: 6px;
    margin-bottom: -6px; }

  .field_with_errors {
    input, textarea, select {
      border: 3px solid  $error-color;
      background-color: $palepink; } }

  .field {
    @include padding-trailer(0.5);
    @include padding-leader(0.5); }

  .clear {
    clear: both; }

  .inline {
    display: inline-block;
    margin-right: gutter-span();
    vertical-align: top;
    width: auto; }

  .wrap_label {
    padding-left: 1.5em;
    position: relative;
    input {
      position: absolute;
      left: 1px;
      top: 3px;
      display: inline-block; } }


  .button {
    @extend %button;
    @include adjust_font_size_to(20px);

    &.center {
      display: block;
      margin: 0px auto; }

    &.secondary {
      background-color: lighten($button-color, 20%);
      &:hover {
        background-color: $button-hover-color; } }

    &--right {
      float: right; } }

  .input_btn {
    @extend %input_btn_arrow; }

  // custom selects

  .custom_select {
    position: relative;
    select {
      background-color: $darkblue;
      font-size: 14px!important;
      color: $white; } }

  .customSelect {
    @include box-sizing(border-box);
    max-width: 100%;
    height: 50px!important;
    background-color: $darkblue; }

  .customSelectInner {
    @include box-sizing(border-box);
    @include adjust-font-size-to(16px);
    @include svg-background('../images/icons/select_arrow');
    background-position: right center;
    background-repeat: no-repeat;
    height: 50px!important;
    padding: 12px 16px;
    text-align: left;
    color: $white; }

  .hasCustomSelect {
    option {
      color: $white; } }

  .breaker {
    @include leader(1);
    @include trailer(1);
    background-color: $darkpurple;
    display: block;
    margin-left: gutter-span();
    margin-right: gutter-span();
    height: 2px; } }

.form-input-radio {
  input {
    height: 50px;

    &[type=radio],
    &[type=checkbox] {
      display: none;
      + label {
        @include box-shadow( none );
        display: inline-block;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent; }
      &:checked + label::after {
        content: "";
        background: #233e91;
        height: 15px;
        width: 15px;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 8px; } }

    &[type=radio]+label {
      position: relative;
      float: left;
      width: auto;
      &::before {
        content: '';
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 10px 0 0;
        box-shadow: 0 8px 8px rgba(0,0,0,.3); } } } }

.date_selector {
  .customSelect {
    @include trailer(.5); } }

// custom checkboxes & radios
.custom_check {
  margin-bottom: 15px;

  &.parsley-error {
    label:before {
      background: red; } }

  input[type=checkbox] {
    display: none;
    &:checked + label:before {
      background-image: url("../images/icons/checkbox_tick.svg");
      background-position: center;
      background-repeat: no-repeat; } }

  label {
    color: $black;
    padding-left: 56px;
    &:before {
      content: "";
      color: $black;
      display: block;
      float: left;
      width: 36px;
      height: 36px;
      background: $white;
      margin-left: -56px;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4); } } }

#form_wrapper {
  .custom_check {
    label {
      color: $white; } } }

.has-js {}
.wrap_label {
  padding-left: 36px;
  background-position: 1px 4px;
  background-repeat: no-repeat; }

.has-js {
  .wrap_label {
    @include svg-background('../images/backgrounds/radio_off'); } }
.has-js {
  .checks {
    .wrap_label {
      @include svg-background('../images/backgrounds/check_off'); } } }
.has-js {
  .checks {
    label.c_on {
      @include svg-background('../images/backgrounds/check_on'); } } }
.has-js {
  label.r_on {
    @include svg-background('../images/backgrounds/radio_on'); } }


.has-js {
  .wrap_label {
    input {
      &[type=radio],
      &[type=checkbox] {
        position: absolute;
        left: -9999em; } } } }
.has-js {
  .toggle_boxes {
    .wrap_label,
    label.r_on {
      @include transition-property("background-color, color");
      @include transition-duration(200ms);
      @include padding-leader(.65);
      @include padding-trailer(.6);
      @include adjust-font-size-to(14px);
      @extend %upcase;
      padding-left: 0;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      background-image: none;
      cursor: pointer;
      padding: 5px 15px;
      box-sizing: border-box;
      margin-right: 10px;

      &.parsley-error {
        background: red; } }

    .wrap_label {
      background-color: #644082;
      color: #fff;
      width: 100%;

      &:hover {
        background-color: $darkpurple; } }
    label.r_on {
      @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
      background-color: $button-color;
      color: $white;
      &:hover {
        background-color: $midblue; } } } }

.toggle_boxes {
  @include trailer(.5);
  margin-left: gutter-span();
  margin-right: gutter-span(); }

.giftaid {
  @include trailer(2);
  .legend {
    background: transparent url("../images/logos/giftaid.png") 0 0 no-repeat;
    width: 150px;
    height: 0;
    display: block;
    padding-top: 85px;
    overflow: hidden; }
  .field {
    padding-top: 0;
    padding-bottom: 0; }
  p {
    @include leader(.5);

    .ccm-block-express-form {
      color: $white;
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .giftaid_options {
    @include leader(1);
    @include trailer(1);
    p {
      @include leader(1);
      @include trailer(1); } } }



// text links within forms

.forgotten_password,
.download_application_form {
  @include leader(1);
  text-decoration: underline;
  color: $white;
  display: inline-block;
  &:hover {
    color: $white;
    text-decoration: none; } }

.download_application_form {
  margin-left: gutter-span(); }

#volunteer_form,
#donation_form,
#main_checkout_form,
#submit_event {
  .button {
    @extend %input_btn_arrow; } }

.custom_upload {
  input {
    height: auto;
    line-height: 0; } }

#donation_form {
  .card_details {
    @include adjust-font-size-to(14px);
    @include trailer(1);
    color: $white;
    margin-left: gutter-span();
    margin-right: gutter-span();
    span.title {
      font-size: 18px;
      display: block; }
    span.cards {
      display: block;
      height: 30px;
      width: auto;
      margin-top: 10px;
      background: transparent url("../images/backgrounds/cards.png") 0 0 no-repeat;
      background-size: contain; } } }

.secured_by {
  @include leader(1);
  margin-left: gutter-span();
  display: block;
  background: transparent url("../images/logos/Securedby_Horizontal_2.png") 0 0 no-repeat;
  width: 189px;
  height: 0;
  overflow: hidden;
  padding-top: 70px; }



// Errors
//****************************************************************************
.form_errors {
  @include trailer(2);
  background: $error-color;
  padding: rhythm(1);
  color: $white;
  clear: both;
  margin-left: gutter-span()/2;
  margin-right: gutter-span()/2;
  h2 {
    @include trailer(.5);
    color: $white; }
  ul {
    margin-bottom: 0!important; }
  li {
    @include trailer(.2);
    list-style: none;
    background-image: none!important;
    padding-left: 0!important; } }

.error-paragraph {
  color: $red;
  margin-bottom: 10px; }

#main_flash {
  @extend fieldset;
  .flash {
    @extend .form_errors; } }

// Responsive
//****************************************************************************
@include breakpoint-context('all') {
  .basic_form {
    legend {
      margin-left: gutter-span();
      margin-right: gutter-span(); }

    .inline {
      margin-right: gutter-span(); }

    .field {
      margin-left: gutter-span();
      margin-right: gutter-span(); }

    .note {
 } }      // margin-left: gutter-span()

  .form_errors {
    margin-left: gutter-span();
    margin-right: gutter-span(); }


  #donation_form {
    .giftaid {
      p {
        margin-left: gutter-span();
        margin-right: gutter-span(); } }

    .card_details {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .download_application_form {
    margin-left: gutter-span(); }


  .secured_by {
    margin-left: gutter-span(); } }


@include breakpoint-context($tablet) {

  .basic_form {
    fieldset {
      margin-left: gutter-span()*2;
      margin-right: gutter-span()*2;
      .inset {
        margin-left: gutter-span()*3;
        margin-right: gutter-span()*3;
        .half {
          vertical-align: bottom;
          margin-bottom: 8px; }
        .toggle_boxes {
          @include trailer(0); } } }
    .field {
      $gutter-styles: split;
      margin-left: gutter-span();
      margin-right: gutter-span();
      &.half {
        width: 50% - gutter-span()*2;
        display: inline-block;
        vertical-align: top; }
      &.third {
        width: 33%;
        display: inline-block;
        vertical-align: bottom; }
      &.half,
      &.third {
        &.clear {
          display: block; } } }

    .button {
      width: column-span(3,0,6) - gutter-span();
      margin-left: gutter-span(); } }

  .form_errors {
    margin-left: gutter-span()*3;
    margin-right: gutter-span()*3; }

  #main_flash {
    margin-left: gutter-span()*2;
    margin-right: gutter-span()*2;
    .flash {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .date_selector {
    .custom_select {
      @include trailer(0);
      display: inline-block;
      width: 30%;
      margin-right: 10px; } } }

@include breakpoint-context($desktop) {

  .basic_form {
    fieldset {
      width: column-span(7,0,9);
      margin-left: auto;
      margin-right: auto;

      .inset {
        margin-left: gutter-span()*4;
        margin-right: gutter-span()*4; } } }

  .form_errors {
    @include box-sizing('border-box');
    width: column-span(7,0,9) - gutter-span();
    margin-left: auto;
    margin-right: auto; }

  #main_flash {
    .flash {
      margin-left: gutter-span();
      margin-right: gutter-span();
      width: auto; } }

  .giftaid {
    p {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .card_details {
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  .download_application_form {
    margin-left: gutter-span(); } }
