// button grid
//
// .button-grid--align-center - Align buttons centrally
//
// Markup: ./button-grid.hbs
//
// Styleguide blocks.button grid

.button-grid {
    display: flex;

    .button + .button {
        margin-left: 10px; }

    &--align-center {
        justify-content: center; }

    &--align-right {
        justify-content: flex-end; } }
