#outer_wrapper {
  .newsletter_page & {
    @include padding-trailer(4);
    background-color: $purple;
    h1,
    .ugc {
      color: $white; }

    h1 {
      text-align: center; } } }

