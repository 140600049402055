// link-arrow
//
// Markup: ./link-arrow.hbs
//
// Styleguide components.link-arrow

.link-arrow {
    $this: &;
    color: $cerulean;
    display: flex;

    &:after {
        background: url('/application/themes/rawnet/app/images/interface/arrow-blue.svg') no-repeat center center;
        content: '';
        display: block;
        height: 33px;
        transform: rotate(-90deg);
        width: 30px; } }
