// sticky cta
//
// Markup: ./sticky-cta.hbs
//
// Styleguide blocks.sticky cta

.sticky-cta {
    $this: &;
    background: linear-gradient(0deg, rgba(118,34,130,1) 0%, rgba(129,73,151,1) 100%);
    border-radius: 12px;
    box-sizing: border-box;
    color: $white;
    margin: -100px auto 0;
    max-width: 290px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%; }
