#donate_reasons {
  @include padding-leader(2);
  @include padding-trailer(3);
  background-color: $purple;
  text-align: center;

  h2 {
    @include trailer(1);
    margin-left: gutter-span()*2;
    margin-right: gutter-span()*2;
    color: $white; }

  .donate_reason {
    @include trailer(1);
    margin-left: gutter-span();
    margin-right: gutter-span();

    &:nth-child(odd) {
      h3 {
        color: $yellow; }
      &.sun {
        .donate_reason_image {
          @include svg-background('../images/icons/yellow_sun'); } }
      &.leaf {
        .donate_reason_image {
          @include svg-background('../images/icons/yellow_leaf'); } }
      &.flower {
        .donate_reason_image {
          @include svg-background('../images/icons/yellow_flower'); } } }

    &:nth-child(even) {
      h3 {
        color: $green; }
      &.sun {
        .donate_reason_image {
          @include svg-background('../images/icons/green_sun'); } }
      &.leaf {
        .donate_reason_image {
          @include svg-background('../images/icons/green_leaf'); } }
      &.flower {
        .donate_reason_image {
          @include svg-background('../images/icons/green_flower'); } } } }

  .donate_reason_image {
    @include trailer(.3);
    height: 180px;
    display: block;
    background-position: center center;
    background-repeat: no-repeat; }

  .button {
    @extend %button;
    @extend %input_btn_arrow;
    @include leader(1);
    @include padding-leader(.5); } }

// Responsive
//********************************************************
@include breakpoint-context('all') {
  #donate_reasons {
    .donate_reason {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }

@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet) {
  #donate_reasons {
    .donate_reason {
      width: column-span(2,0,4);
      vertical-align: top;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
      h3 {
        margin-left: gutter-span()*2;
        margin-right: gutter-span()*2; } }

    .button {
      margin-left: 0;
      margin-right: 0;
      width: column-span(4,0,6); } } }


@include breakpoint-context($tablet) {
  #donate_reasons {
    .donate_reason {
      $gutter-styles: split;
      width: column-span(2,0,6); }

    .button {
      width: column-span(3,0,9) + gutter-span()*2; } } }


@include breakpoint-context($desktop) {
  #donate_reasons {
    .donate_reason {
      width: column-span(3,0,9); } } }
