//  Text footer
//****************************************************************************
#text_footer,
#back_to_wrapper,
#contact_us_link_wrapper {
  @extend %gradient_red;
  @include padding-leader(3);
  @include padding-trailer(3);
  clear: both;

  .back_to_listing_link,
  .contact_us_link {
    @extend %upcase;
    @include box-sizing("border-box");
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
    @include trailer(1);
    @include svg-background('../images/icons/arrow_left', $background-size: 18px auto);
    background-position: left 25px center;
    background-repeat: no-repeat;
    background-color: $darkblue;
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    span {
      @include transition-property("background-color, color");
      @include transition-duration(200ms);
      width: calc(100% - 80px);
      margin-left: 80px;
      display: inline-block;
      padding: rhythm(.6) gutter-span() rhythm(.5) 0;
      background-color: $button-color;
      color: #fff; }
    &:hover {
      span {
        background-color: $button-hover-color; } } }

  .contact_us_link {
    @include svg-background('../images/icons/arrow_right', $background-size: 18px auto);
    background-position: right 25px center;
    span {
      margin-left: 0;
      margin-right: 80px;
      padding: rhythm(.6) 0 rhythm(.5) gutter-span(); } } }

// Responsive
//********************************************************

@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet);

@include breakpoint-context($tablet) {
  #text_footer,
  #back_to_wrapper,
  #contact_us_link_wrapper {
    .back_to_listing_link,
    .contact_us_link {
      width: 400px;
      display: block;
      margin-left: auto;
      margin-right: auto; } } }

@include breakpoint-context($desktop);
