#outer_wrapper {
  .shop-online & {
    @extend %gradient_dark_blue;
    h1,
    .ugc {
      color: $white; } } }

#shop_listing {
  @include padding-trailer(2);

  .product_stub_link {
    @include trailer(1);
    @include transition-property('padding');
    @include transition-duration(200ms);
    display: block;
    padding-top: 5px;
    padding-bottom: 5px; }

  .product_stub_content {
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
    @include transition-property('margin');
    @include transition-duration(200ms);
    background-color: $egg;
    padding: 20px;
    margin: 0;
    &:hover {
      background-color: $white;
      margin-top: -5px;
      margin-bottom: 5px; } }

  .product_image_wrapper {
    @include trailer(.5);
    width: 100%;
    margin: 0;
    .product_stub_image {
      width: 100%; } }

  .product_title,
  .product_price {
    font-family: $heading-font-stack;
    @include adjust-font-size-to(32px); }

  .product_price,
  .abstract {
    color: $text-color; }

  .product_title {
    color: $primary-color;
    @include trailer(.5); }

  .product_price {
    @include trailer(.5); } }


// Responsive
//********************************************************

@include breakpoint-context($small-tablet) {
  #shop_listing {
    margin-left: - gutter-span();
    margin-right: - gutter-span();

    .product_item {
      margin-left: gutter-span()/2;
      margin-right: gutter-span()/2; }

    .product_stub_content {
      overflow: auto; }

    .product_image_wrapper {
      @include trailer(0);
      float: left;
      width: 200px;
      margin-right: gutter-span();
      padding: 0; } } }

@include breakpoint-context($tablet) {
  #shop_listing {
    margin-left: - gutter-span()/2;
    margin-right: - gutter-span()/2; } }

