.center {
  // +background-grid($color: #ffbbbb)
  @include clearfix;
  margin: 0 auto; }


// Responsive
//********************************************************

@include breakpoint-context($desktop) {
  .center {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px; } }
