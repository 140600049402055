.tac {
  .scroll {
    height: 190px;
    overflow: auto;
    background: #fff;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4); }

  p {
    font-size: 16px;
    line-height: 1.5; }

  .actions {
    max-width: 380px;
    margin: 0px auto; } }
