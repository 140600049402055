.c-shops-sticky-btn {
    position: fixed;
    z-index: 999;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

    &.is-hidden {
        opacity: 0;
        transform: translateX(-50%) translateY(-10px); }

    &__btn {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        background-color: $cerulean;
        color: #fff !important;
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;
        padding: 13px 24px;
        border-radius: 16px;
        gap: 10px;
        transition: background-color 250ms ease-out;

        &:hover {
            background-color: $cerulean2;
            transition-duration: 400ms; } }

    svg {
        width: 24px;
        height: 24px;
        transform: rotate(90deg);
        transition: transform 250ms ease-out; }

    &__btn:hover svg {
        transition-duration: 400ms;
        transform: translateY(3px) rotate(90deg); } }
