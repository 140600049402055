%gradient_dark_blue {
  background-color: $brightblue;
  @include filter-gradient($brightblue, $darkblue, vertical);
  @include background(linear-gradient(top, $brightblue 40%, $darkblue 100%)); }


%gradient_blue {
  background-color: $brightblue;
  @include filter-gradient($brightblue, $darkblue, vertical);
  @include background(linear-gradient(top, $brightblue 40%, $darkblue 100%)); }

%gradient_yellow {
  background-color: $yellow;
  @include filter-gradient($yellow, $darkyellow, vertical);
  @include background(linear-gradient(top, $yellow 40%, $darkyellow 100%)); }

%gradient_red {
  background-color: $red;
  @include filter-gradient($red, $darkred, vertical);
  @include background(linear-gradient(top, $red 40%, $darkred 100%)); }

%gradient_purple {
  background-color: $purple;
  @include filter-gradient($purple, $darkpurple, vertical);
  @include background(linear-gradient(top, $purple 40%, $darkpurple 100%)); }

%gradient_green {
  background-color: $green;
  @include filter-gradient($green, $darkgreen, vertical);
  @include background(linear-gradient(top, $green 40%, $darkgreen 100%)); }

%gradient_pink {
  background-color: $lightpink;
  @include filter-gradient($lightpink, $darkpink, vertical);
  @include background(linear-gradient(top, $lightpink 40%, $darkpink 100%)); }

