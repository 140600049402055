body {
  margin: 0;
  padding: 0; }

.social-media {
  @include clearfix;
  &__tile {
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background: $white;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 25px;
      left: 25px;
      content: "";
      border-radius: 50%; }

    &--twitter {
      background-color: #4099ff; }

    &--facebook {
      background-color: #3b5998; }

    &--medium,
    &--large {
      display: none; } }

  &__link {
    display: block; }

  &__content {
    position: absolute;
    bottom: 0;
    top: 40px;
    padding: 40px 25px;
    color: $white;
    word-break: break-word; }

  &__icon {
    width: 26px;
    height: 26px;
    position: absolute;
    fill: $black;

    &--instagram {
      top: 34px;
      left: 34px; }

    &--facebook {
      top: 34px;
      left: 40px; }

    &--twitter {
      top: 30px;
      left: 30px; } } }

@media(min-width: 480px) {
  .social-media {
    &__link {
      width: 50%;
      float: left; } } }

@media(min-width: 800px) {
  .social-media {
    &__link {
      width: 33.3333%; } } }

@media(min-width: 1200px) {
  .social-media {
    &__link {
      width: 25%; }
    &__tile {
      &--medium {
        display: block; } } } }


@media(min-width: 1500px) {
  .social-media {
    &__link {
      width: 20%; }

    &__tile {
        &--large {
          display: block; } } } }
