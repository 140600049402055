.main_image_wrapper {
  height: 220px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  -ms-behavior: asset-url('../images/vendor/backgroundsize.min.htc'); }


// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($small-tablet) {
  .main_image_wrapper {
    height: 300px; } }

@include breakpoint-context($tablet) {
  .main_image_wrapper {
    height: 400px; } }

@include breakpoint-context($desktop) {
  .main_image_wrapper {
    height: 600px; } }

@include breakpoint( 1200px ) {
  .main_image_wrapper {
    height: 800px; } }
