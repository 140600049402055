// event form
//
// Markup: ./event-form.hbs
//
// Styleguide components.event form

.event-form {
    $this: &;
    background: linear-gradient(0deg, rgba(118,34,130,1) 0%, rgba(129,73,151,1) 100%);
    border-radius: 12px;
    box-sizing: border-box;
    color: $white;
    height: calc(100% - 20px);
    margin: auto;
    max-width: 444px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 100%;

    &__asset {
        fill: $seance; }

    &__top-left {
        @include position(absolute, -120px null null -120px); }

    &__bottom-right {
        @include position(absolute, null -120px -120px null); }

    &__inner {
        position: relative;
        z-index: 1; }

    &__heading {
        line-height: 1.2; }

    &__button {
        margin: auto; }

    @media(min-width: $large) {
        padding: 43px 43px 63px; } }
