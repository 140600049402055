.key_message_wrapper {
  background-color: $dark-grey;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  .mobile_overlay {
    @include svg-background('../images/backgrounds/key_message_overlay');
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -127px;
    width: 255px;
    height: 240px;
    z-index: 2; }

  &--text-center {
    text-align: center; }

  &--vertical-center {
    @include align-items(center);
    @include display(flex);
    @include flex-direction(row);
    .center {
      padding-top: 30px;
      padding-bottom: 30px; } }

  .button {
    @extend %button;
    width: 320px; }

  h2 {
    font-size: 40px;
    line-height: 1.5;
    display: block;
    color: $white;
    margin: 0 auto 1.5em; } }

.key_message_image {
  @extend %image_cover;
  height: 90vw;
  -ms-behavior: asset-url('../images/vendor/backgroundsize.min.htc'); }

.key_message_icon {
  position: absolute;
  top: -60px;
  left: -100px;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat; }

.key_message_info {
  position: relative;
  &.yellow {
    @extend %gradient_yellow;
    .key_message_icon {
      @include svg-background('../images/icons/yellow_icon2', $background-size: 140px auto); }
    .key_message_link {
      color: $purple;
      &:hover {
        color: $purple; } } }
  &.blue {
    @extend %gradient_blue;
    .key_message_icon {
      @include svg-background('../images/icons/blue_sun', $background-size: 140px auto); }
    .key_message_link {
      color: $white;
      &:hover {
        color: $white; } } }
  &.pink {
    @extend %gradient_pink;
    .key_message_icon {
      @include svg-background('../images/icons/pink_icon1', $background-size: 140px auto); }
    .key_message_link {
      color: $white;
      &:hover {
        color: $white; } }
    .link_block_detail {
      p {
        color: $white; } } }

  &.purple {
    @extend %gradient_purple;
    .key_message_icon {
      @include svg-background('../images/icons/purple_sun', $background-size: 140px auto); }
    .key_message_link {
      color: $white;
      &:hover {
        color: $white; } } }
  &.green {
    @extend %gradient_green;
    .key_message_icon {
      @include svg-background('../images/icons/green_sun', $background-size: 140px auto); }
    .key_message_link {
      color: $white;
      &:hover {
        color: $white; } } }


  .center {
    position: relative; }

  .button {
    margin: 1em auto 2em;
    position: relative;
    left: auto;
    right: auto;
    display: block;
    z-index: 2;
    bottom: 0;
    font-size: 20px;
    line-height: 2.14286em;
    float: right;
    span {
      line-height: 1.5;
      display: block; } }

  .link_block_detail {
    p {
      font-size: 18px;
      line-height: 1.36364em;
      display: inline-block;
      vertical-align: top;
      margin-left: 13.63636%;
      margin-top: 1.66667em;
      color: #3b3b3b;
      margin-right: 4.54545%;
      position: relative;
      z-index: 2; } } }

.key_message_link {
  font-family: $heading-font-stack;
  @include adjust-font-size-to(28px);
  padding: 2em;
  margin-left: gutter-span();
  margin-right: gutter-span();
  text-align: center;
  display: block;
  position: relative;
  z-index: 0; }


// Responsive
//********************************************************
@include breakpoint-context( 'all' );


@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet);

@include breakpoint-context($tablet) {
  .key_message_wrapper {
    min-height: calc(100vh - 110px);
    .mobile_overlay {
      display: none; } }

  .key_message_link {
    margin-top: 1.5em;
    padding: 1em; }

  .key_message_info {
    height: 210px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .link_block_detail {
      font-size: 1.22222em;
      width: 90%;
      margin: auto;

      p {
        width: 40%; } }

    .button {
      width: 40%;
      margin-top: 3.33333em; } }

  .key_message_image {
    height: 90vh; } }

@include breakpoint-context($desktop) {
  .key_message_wrapper {
    min-height: calc(100vh - 170px); }

  .key_message_link {
    @include leader(1.5);
    @include adjust-font-size-to(30px);
    padding: 0; }

  .key_message_icon {
    width: 220px;
    height: 220px;
    top: -70px;
    left: -140px;
    .svg & {
      background-size: 220px 220px!important; } } }
