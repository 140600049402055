#shop_location_listing {
  flex: 1 1 70%;
  width: 450px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  @include padding-leader(2);
  @include padding-trailer(2);

  .search_result_title {
    color: #0171AB;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 165%; }
  .location_results {
    padding: 10px 24px;
    margin-bottom: 0; }
  .article_stub_item {
    @include box-sizing('border-box');
    @include trailer(1);
    border-bottom: 2px solid #F4F4EF;
    width: 395px;
    background-color: $egg;
    margin-left: gutter-span();
    margin-right: gutter-span();
    padding: 24px;
    line-height: 1.4em;
    background: #fff;
    &.selected {
      background: #E3F7FF; }
    &:hover {
      border: 2px solid #479DDC; }
    .location_address {
      margin-bottom: 24px; }
    .contact-directions-wrapper {
      display: flex;
      .contact_number {
        margin-right: 8px;
        border-radius: 16px;
        padding: 10px;
        height: 30px;
        color:#fff {}
        justify-content: center;
        align-items: center;
        background: var(--Primary-Default, #00ACF1);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30); }
      .contact_directions {
        margin-left: 8px;
        border-radius: 16px;
        padding: 10px;
        height: 30px;
        color:#fff {}
        background: var(--Primary-Default, #E6007E);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
        justify-content: center;
        align-items: center; } } }

  .article_stub_title {
    color: #0171AB; }

 }  // Responsive
//********************************************************
@include breakpoint-context('all') {
  #shop_location_listing {
    .article_stub_item {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      // margin-left: gutter-span()
      // margin-right: gutter-span()
 } } }      // padding: 25px gutter-span()

@include breakpoint-context($small-tablet) {
  #shop_location_listing {
    .article_stub_item {
      display: inline-block;
      vertical-align: top;
      // margin-left: gutter-span()/2
 } } }      // margin-right: gutter-span()/2

@include breakpoint-context($tablet) {
  #shop_location_listing {
    .article_stub_item {} } }

@include breakpoint-context($desktop) {
  #shop_location_listing {
    .article_stub_item {
 } } }      // width: column-span(2,0,6)
