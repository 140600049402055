//  Case conversion
//****************************************************************************
%upcase {
  text-transform: uppercase; }

%downcase {
  text-transform: lowercase; }

%nocase {
  text-transform: none; }


//  Font mixins
//****************************************************************************
%default-font {
  font-family: $default-font-stack;
  font-weight: normal;
  font-style: normal; }

%bold-font {
  font-weight: bold; }

%italic-font {
  font-style: italic; }

%bold-italic-font {
  @extend %bold-font;
  @extend %italic-font; }

%skippy-font {
  font-family: $skippy-font-stack; }
