// Loading-ring
//
// Markup: ./loading-ring.hbs
//
// Styleguide components.Loading-ring

.loading-ring {
    $this: &;
    height: 80px;
    margin: auto;
    position: relative;
    width: 80px;

    &__element {
        animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
        border: 8px solid $affair;
        border-color: $affair transparent transparent;
        border-radius: 50%;
        box-sizing: border-box;
        display: block;
        height: 64px;
        margin: 8px;
        position: absolute;
        width: 64px;

        &:nth-child(1) {
            animation-delay: -.45s; }

        &:nth-child(2) {
            animation-delay: -.3s; }

        &:nth-child(3) {
            animation-delay: -.15s; } } }

@keyframes lds-ring {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
