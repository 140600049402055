.people__header {
  background-color: #e3f7ff;
  position: relative;
  section {
    max-height: 500px; }
  .banner__picture {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    &::before {
      @include position(absolute, 0 0 0 0);
      background: linear-gradient(60deg, rgba(0, 0, 0, .3) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      opacity: 60%;
      pointer-events: none;
      z-index: 1; }
    img {
      width: 100%; } }
  .banner__content {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: -270px;
    max-width: unset;
    z-index: 100;
    .banner__title {
      color: White; }

    .people__image {
      .person__picture {
        img {
          border-radius: 1rem;
          overflow: hidden; } } }

    .people__meta {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      .banner__title {
        margin-bottom: 0; } } } }

@media(max-width: 999px) {
  .people__header {
    .banner__content {
      bottom: 40px; }
    .banner__slider {
      padding-bottom: 60px; }
    .banner__picture {
      &::before {
        background-color: black; }
      img {
        max-width: unset; } }
    .slider {
      .slick-slide {
        img {
          width: unset; } } } } }

@media(max-width: 700px) {
  .people__header {
    .banner__picture {
      img {
        height: 500px; } }
    .people__meta {
      margin-left: 0!important; }
    .banner__content {
      flex-direction: column;
      top: 0;
      bottom: unset;
      margin-top: 60px; }
    .person__picture {
      max-width: 300px;
      img {
        max-width: 300px; } } } }
