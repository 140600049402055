.ccm-image-slider-container {
  position: relative;

  .rslides {
    margin: 0 auto;
    position: relative;

    li {
      background-size: cover;
      background-position: center;
      height: 400px; }

    .mega-link-overlay {
      @include position(absolute, 0 0 0 0); } }

  .ccm-image-slider-text {
    @include position(absolute, auto null 32px 40px);
    color: $white; }

  .rslides_nav {
    background-color: $darkblue;
    background-position: center;
    background-size: 15px;

    &.prev {
      background-image: url(../images/icons/arrow_left.png); }

    &.next {
      background-image: url(../images/icons/arrow_right.png); } }

  @include breakpoint-context($tablet) {
    .rslides {
      li {
        height: 800px; } }

    .ccm-image-slider-text {
      left: 32px; } } }
