// accordion
//
// Accordions can open a particular participant by using `?participant=jake` in
// the URL query string, and adding `data-participant="jake"` to the
// `.accordion__item` element, for eaxmple.
// <a href="/styleguide/item-components-accordion.html?participant=jake">Click here to try it</a>
//
// Markup: ./accordion.hbs
//
// background: #e3f7ff
//
// Styleguide components.accordion

.accordion {
    $this: &;
    width: 100%;

    &__item {
        border-radius: 20px;
        box-shadow: 0 2px 8px 0 rgba($black, .17);
        position: relative;

        + #{$this}__item {
            margin-top: 15px; }

        &--error {
            margin-bottom: 50px;

            #{$this}__link {
                border: 1px solid $error;
                box-sizing: border-box; }

            &::after {
                @include position(absolute, null null null 50%);
                color: $error;
                content: 'Sorry, there are errors with this participant';
                font-size: rem(12);
                transform: translateX(-50%); } } }

    &__link {
        background-color: $affair;
        border-radius: 12px;
        color: $white;
        cursor: pointer;
        font-size: rem(18);
        font-weight: 600;
        line-height: 1.5;
        padding: 20px;
        position: relative;
        user-select: none;

        &--active {
            background-color: $rose;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            #{$this}__icon {
                transform: translateY(-50%) rotate(180deg);
                transform-origin: center; } } }

    &__icon {
        @include position(absolute, 50% 20px null null);
        fill: $white;
        transform: translateY(-50%);
        transform-origin: center; }

    &__content {
        background: $white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: none; }

    &__wrap {
        padding: 20px;

        p {
            &:last-child {
                margin-bottom: 0; } } } }
