// Grid
//
// Markup: grid.hbs
//
// Styleguide layouts.Grid

.grid {
    $this: &;
    @include grid;

    &--reverse {
        flex-direction: row-reverse; }

    &__item {
        &--75 {
            margin-bottom: 20px;
            margin-right: 20px;
            width: calc(75% - 20px); }

        &--25 {
            margin-bottom: 20px;
            margin-right: 20px;
            width: calc(25% - 20px); }

        &--40 {
            margin-bottom: 20px;
            margin-right: 20px;
            width: calc(100% - 20px); }

        &--60 {
            margin-bottom: 20px;
            margin-right: 20px;
            width: calc(100% - 20px); } }

    &--space-between {
        justify-content: space-between; }

    &--align-end {
        align-items: flex-end; }

    &--align-center {
        align-items: center; }

    &--no-wrap {
        flex-wrap: nowrap; }

    &--styleguide {
        #{$this}__item {
            background-color: #ededed;
            border-radius: 6px;
            min-height: 50px; } }

    @media(min-width: 1024px) {
        &.grid--2-col {
            .grid__item--50 {
                width: calc(50% - 20px); } } }

    @media(min-width: 767px) {
        &--medium-up {
            @include grid; } }

    @media(max-width: 1024px) {
        &--break\@large {
            #{$this}__item {
                width: calc(100% - #{$grid-gutter}); } } }

    @media(min-width: $medium) {
        &--medium-up {
            @include grid; } }

    @media(min-width: $large) {
        &--large-up {
            @include grid; }

        &.grid--2-col {
            #{$this}__item {
                &--50 {
                    width: calc(50% - 20px); } } }

        &__item {
            &--40 {
                width: calc(40% - 20px); }

            &--60 {
                width: calc(60% - 20px); } } } }
