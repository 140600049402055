// input-toggle
//
// Markup: ./input-toggle.hbs
//
// Styleguide elements.input-toggle

.input-toggle {
    $this: &;
    border-radius: 10em;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;

    &:hover {
        cursor: pointer; }

    &:before {
        color: $white;
        content: 'Yes';
        font-weight: 700;
        left: 0; }

    &:after {
        color: $cerulean2;
        content: 'No';
        font-weight: 700;
        right: 0; }

    &:before,
    &:after {
        @include position(absolute, 50% null null null);
        font-size: rem(14);
        padding: 12px;
        transform: translateY(-50%);
        z-index: 0; }

    &__input {
        @include position(absolute, 0 0 0 0);
        appearance: none;
        opacity: 0;
        z-index: 1;

        &:checked ~ .input-toggle__handle {
            box-shadow: inset 0 1px rgba($black, .15), inset 0 0 0 1px $cerulean2;

            &:before {
                background: $white;
                box-shadow: inset 0 -1px rgba($black, .2), inset 0 0 0 1px $cerulean2, 0 1px 2px rgba($black, .1), 0 6px 12px rgba($black, .1);
                left: calc(50% + 7px); } } }

    &__handle {
        background-image: linear-gradient($cerulean2, $cerulean2);
        border: 2px solid $cerulean2;
        border-radius: 10em;
        box-shadow: inset 0 1px rgba($cerulean2, .15), inset 0 0 0 1px rgba($cerulean2, .15), inset 0 1px 2px rgba($cerulean2, .15), inset 44px 44px $white;
        display: block;
        height: 32px;
        pointer-events: none;
        transition: .4s ease;
        width: 68px;

        &:before {
            @include position(absolute, 6px null null 8px);
            background: $cerulean2;
            border-radius: 10em;
            content: '';
            height: 24px;
            transition: .4s ease;
            width: 24px;
            z-index: 1; } } }
