.product_image_wrapper {
  @include trailer(1);
  margin-left: gutter-span();
  margin-right: gutter-span();
  .product_detail_image {
    @include transition-property('display');
    @include transition-duration(200ms);
    width: 100%;
    display: none; } }

.product_price_column {}


.price_variables {
  font-family: $heading-font-stack;
  @include adjust-font-size-to(34px); }

#product_variations {
  @include leader(1);
  label,
  legend {
    color: $text-color; }
  fieldset.variations {
    .field {
      margin-left: 0;
      margin-right: 0;
      display: block;
      padding-bottom: 0;
      label.r_on {
        @include svg-background('../images/backgrounds/radio_on_alt'); } } }

  fieldset.quantity {
    @include trailer(1.5);
    @include padding-leader(1);
    .field {
      width: 100px; }
    label {
      color: $text-color; } }

  .button {
    @extend %button;
    @extend %input_btn_arrow; } }


// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  .product_image_wrapper,
  .product_price_column {
    display: inline-block;
    vertical-align: top;
    width: column-span(3,0,6); }

  .product_image_wrapper {
    margin-left: 0;
    margin-right: 0; }

  .product_price_column {
    margin-left: gutter-span(); }

  #product_variations {
    fieldset {
      margin-left: 0;
      margin-right: 0; }

    .button {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } } }

@include breakpoint-context($desktop) {
  #content_main {
    .page-template-product & {
      width: 665px; } }

  .product_image_wrapper {
    @include box-sizing('border-box');
    width: 310px;
    padding-right: 20px; }
  .product_price_column {
    width: 300px;
    margin-left: 10px; } }
