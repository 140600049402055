#family_story_listing {
  padding: 0;

  .article_stub_item {
    position: relative;
    margin: 0;
    width: 100%;
    &.blue {
      @extend %gradient_blue; }
    &.yellow {
      @extend %gradient_yellow; }
    &.red {
      @extend %gradient_red; }
    &.purple {
      @extend %gradient_purple; }

    &.blue,
    &.purple,
    &.red, {
      a,
      h2,
      p {
        color: $white; }
      .article_stub_title {
        span {
          @include svg-background('../images/icons/arrow_right', $background-size: 18px auto); } } }
    &.yellow {
      a,
      h2,
      p {
        color: $purple; }
      .article_stub_title {
        span {
          @include svg-background('../images/icons/arrow_right_purple', $background-size: 18px auto); } } }

    &.blue,
    &.purple,
    &.red,
    &.yellow {
      h2 {
        &:hover {
          text-decoration: underline; } } }

    &.blue {
      .icon {
        @include svg-background('../images/icons/blue_icon1', $background-size: 130px auto); } }
    &.purple {
      .icon {
        @include svg-background('../images/icons/purple_icon1', $background-size: 130px auto); } }
    &.red {
      .icon {
        @include svg-background('../images/icons/red_icon1', $background-size: 130px auto); } }
    &.yellow {
      .icon {
        @include svg-background('../images/icons/yellow_icon2', $background-size: 130px auto); } } }

  .article_stub_image_wrapper {
    @extend %image_cover;
    @include trailer(1);
    height: 220px;
    -ms-behavior: asset-url('../images/vendor/backgroundsize.min.htc'); }

  .article_stub_content {
    height: 240px;
    margin-left: 1em;
    margin-right: 1em;
    background-color: transparent;
    box-shadow: none;
    transition: none;
    padding: 0;

    &:hover {
      background-color: transparent;
      margin-top: 0; } }

  .article_stub_title {
    @include trailer(.5);
    span {
      background-position: right center;
      background-repeat: no-repeat;
      padding-right: 30px; } }
  .article_stub_title,
  .article_stub_abstract {
    position: relative; }

  .article_stub_abstract {
    margin-top: 0; }

  .icon {
    display: none;
    position: absolute;
    top: 180px;
    right: -30px;
    width: 130px;
    height: 130px;
    background-position: right center;
    background-repeat: no-repeat;
    z-index: 1; } }

// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {
  #family_story_listing {
    .article_stub_content {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }

@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet) {
  #family_story_listing {
    .article_stub_image_wrapper {
      height: 400px; } } }

@include breakpoint-context($tablet) {
  #family_story_listing {
    .article_stub_item {
      width: 50%;
      display: inline-block;
      vertical-align: top; }
    .article_stub_image_wrapper {
      height: 260px; }

    .article_stub_content {
      width: 300px;
      margin-left: auto;
      margin-right: auto; }
    .icon {
      display: block; } } }

@include breakpoint-context($desktop) {
  #family_story_listing {
    .article_stub_image_wrapper {
      height: 350px; }

    .article_stub_item {
      &.blue,
      &.red,
      &.yellow,
      &.purple {
        .article_stub_content {
          // margin-left: 0
 } }          // margin-right: 0

      &.blue,
      &.red {
        text-align: right;
        .article_stub_content {
          width: 390px;
          margin-right: 80px;
          text-align: left; } }

      &.yellow,
      &.purple {
        text-align: left;
        .article_stub_content {
          width: 390px;
          margin-left: 80px; } } }

    .icon {
      top: 270px; } } }

@include breakpoint(1200px) {
  #family_story_listing {
    .article_stub_image_wrapper {
      height: 460px; }

    .icon {
      top: 380px; } } }

@include breakpoint(1600px) {
  #family_story_listing {
    .article_stub_image_wrapper {
      height: 560px; }

    .icon {
      top: 490px; } } }

