.label-tooltip {
  &:hover, &:active, &:focus {
    .tooltip {
      &:after {
        opacity: 1; } } }
  .tooltip {
    box-sizing: border-box;
    background: $darkblue;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: 25px;
    line-height: 1;
    margin: 0 5px;
    padding: 5px 0 0;
    position: relative;
    text-align: center;
    width: 25px;
    @include breakpoint-context($small-tablet) {
      display: inline-block; }
    &:after {
      background: rgba($white, .8);
      border: 1px solid $mid-grey;
      box-shadow: 3px 3px 3px 0px rgba(0,0,0,.1);
      color: $text-color;
      content: attr(data-tooltip);
      left: 100%;
      margin-left: 5px;
      padding: 5px 10px;
      position: absolute;
      text-align: left;
      top: 0;
      opacity: 0;
      transition: .3s;
      min-width: 300px; } } }

