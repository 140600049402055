.wysiwyg {
    h1 {
        @extend %heading--1;
        font-size: rem(50);
        margin-bottom: 35px; }

    h2 {
        @extend %heading--2;
        font-size: rem(36); }

    a:not(.button) {
        color: $link-color;
        outline: none;
        text-decoration: none;
        transition-duration: .2s;
        transition-property: background-color, color;

        &:hover {
            color: $link-hover-color; } }

    p {
        font-size: rem(18);
        margin-bottom: 1.5em;

        &:last-child {
            margin-bottom: 0; } }

    strong,
    b {
        font-weight: 700; }

    em,
    i {
        font-style: italic; }

    img:not(.card__media) {
        border-radius: 14px;
        margin-bottom: 70px;
        margin-top: 70px; }

    img.video__image {
        margin-top: 0;
        margin-bottom: 0; }

    &--clean {
        p,
        h1,
        h2,
        h3,
        h4 {
            margin: 0; } }

    ul {
        list-style: inherit;
        padding-left: 22px; }

    ol {
        list-style: decimal;
        padding-left: 22px; } }

p iframe {
    max-width: 100%; }
