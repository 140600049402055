// arrow
//
// .arrow-icon--left - left arrow
// .arrow-icon--right - right arrow
// .arrow-icon--rose - rose coloured arrow
//
// Markup: ./arrow.hbs
//
// Styleguide components.arrow

.arrow-icon {
    &--left {
        transform: rotate(90deg); }

    &--right {
        transform: rotate(-90deg); }

    &--rose {
        fill: $rose; } }
