// background flower
//
// Markup: ./background-flower.hbs
//
// Styleguide components.background-flower

.c-background-flower {
  position: relative;
  padding-top: 1px;

  &__container {
    background-color: $lily-white;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0; }

  &__image {
    fill: $lightblue;
    height: 400px;
    left: 100%;
    opacity: 0.38;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 400px; }

  &--fixed {
    .c-background-flower__container {
      height: 850px; } } }
