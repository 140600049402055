// checkbox
//
// .checkbox--radio - Makes custom radio buttons circular
// .checkbox--small - Smaller font size on mobile
//
// Markup: ./checkbox.hbs
//
// Styleguide elements.form.checkbox

.checkbox {
    $this: &;
    position: relative;

    &__input {
        opacity: 0;
        position: absolute; }

    &__input:checked+.checkbox__label::before {
        background: url('../images/interface/tick.svg') center no-repeat $cerulean; }

    &__label {
        cursor: pointer;
        display: flex;
        font-size: rem(16);
        line-height: 1.5;

        &::before {
            background-color: $white;
            border: 2px solid $cerulean;
            border-radius: 3px;
            box-sizing: border-box;
            content: '';
            display: inline-block;
            flex: none;
            height: 24px;
            margin-right: .4375em;
            margin-top: .125em;
            width: 24px;

            #{$this}--radio & {
                border-radius: 50%; } } }

    &__link {
        display: contents;

        &:hover {
            text-decoration: underline; } }

    &--transparent {
        #{$this}__label {
            &::before {
                background: transparent;
                border-color: $white; } }

        #{$this}__input:checked+.checkbox__label::before {
            background-color: transparent; }

        #{$this}__link {
            color: $white;
            font-weight: 700; } }

    &__text {
        text-align: left; }

    &--small {
        #{$this}__label {
            font-size: rem(12);

            @media(min-width: $medium) {
                font-size: rem(16); } } }

    @media(min-width: $medium) {
        &--small\@medium-min {
            #{$this}__label {
                font-size: rem(12); } } }

    &.parsley-error {
        #{$this}__label {
            &:before {
                border-color: $error; } } } }
