$base-spacing: 20px;
$spacing-directions: ( null: null, 't': '-top', 'r': '-right', 'b': '-bottom', 'l': '-left', 'h': '-left' '-right', 'v': '-top' '-bottom');
$spacing-properties: ( 'p': 'padding', 'm': 'margin');
$spacing-sizes: ( null: $base-spacing, '-half': $base-spacing/2, '-double': $base-spacing*2, '-tripple': $base-spacing*3, '-quadruple': $base-spacing*4, '0': 0);

.u {

    &-hidden {
        display: none; }

    &-noscroll {
        overflow: hidden; }

    &-align-center {
        text-align: center; }

    &-align-right {
        text-align: right; }

    &-hide {
        display: none; }

    &-mb-15 {
        margin-bottom: 1.5em; }

    &-overflow-hidden {
        overflow: hidden; }

    @media(max-width: $medium) {
        &-mb-50\@medium {
            margin-bottom: 50px; } }

    @media(min-width: $large) {
        &-mb-100\@large-min {
            margin-bottom: 100px; } }

    &-m-auto {
        margin-left: auto;
        margin-right: auto; }

    &-flex {
        display: flex; }

    &-flex-wrap {
        flex-wrap: wrap; }

    &-justify-center {
        justify-content: center; }

    &-justify-space-between {
        justify-content: space-between; }

    &-w-full {
        width: 100%; }

    &-rounded {
        border-radius: 12px; }

    &-shadow {
        box-shadow: 0 2px 8px rgba(0, 0, 0, .1); }

    &-pos-rel {
        position: relative; }

    &-pos-sticky {
        position: sticky;
        top: 0; }

    &-object-fit-cover {
        background-size: cover;
        height: 100%;
        object-fit: cover;
        width: 100%; }

    &-white {
        color: $white; }

    &-mine-shaft {
        color: $mine-shaft; }

    &-bg-white {
        background: $white; }

    &-success {
        color: $success; }

    &-error {
        color: $error; }

    @media(max-width: $large) {
        &-hide\@large {
            display: none; } }

    @media(min-width: $medium) {
        &--img-overlap {
            bottom: 0;
            position: absolute; }

        &-pos-rel\@medium-only {
            position: relative; } }

    @media(min-width: $large) {
        &-ml\@large {
            margin-left: 20px; }

        &-pos-rel\@medium-only {
            position: static; } }

    &-size-18 {
        font-size: rem(18); }

    &-size-12 {
        font-size: rem(12); }

    &-weight-700 {
        font-weight: 700; } }

@each $property-namespace, $property in $spacing-properties {
    @each $direction-namespace, $direction-rules in $spacing-directions {
        @each $size-namespace, $size in $spacing-sizes {
            .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {
                @each $direction in $direction-rules {
                    #{$property}#{$direction}: $size; } } } } }

@media(max-width: 1024px) {
    .u-hidden\@large {
        display: none; } }

@media(max-width: 767px) {
    .u-hidden\@medium {
        display: none; } }

@media(min-width: 768px) {
    .u-hidden\@min-medium {
        display: none; } }

@mixin clearfix {
    overflow: visible;

    &:after {
        clear: both;
        content: '';
        display: table; } }
