.u-icon-text {
    display: flex;
    gap: 16px;
    align-items: center;

    svg {
        width: 24px;
        height: 24px; }

    & + & {
        margin-top: 14px; } }
