$header-height: 95px;

.header {
    $this: &;
    background: $white;
    height: 95px;
    position: relative;
    z-index: 9;

    &:before {
        content: '';
        opacity: 0;
        transition: opacity .75s ease; }

    &--spaced {
        margin-bottom: 50px; }

    &--nav-active {
        &:before {
            @include position(fixed, 150px 0 0 0);
            background-color: rgba(42, 47, 47, .8);
            content: '';
            opacity: 1;
            z-index: 1; } }

    &__container {
        @include margin(null auto);
        @include padding(null 20px);
        background: $white;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        justify-content: space-between;
        max-width: 1350px;
        position: relative;
        width: 100%; }

    &__logo {
        @include position(absolute, null null -30% 7%);
        display: block;
        height: 110px;
        z-index: 750;

        @media(min-width: $large) {
            height: 180px; } }

    &__object {
        height: 100%;
        pointer-events: none; }

    &__content {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 100%; }

    &__top {
        display: block; }

    &__top-button {
        display: none; }

    &__main {
        border-left: 1px solid rgba($mine-shaft, .26);
        margin-left: 15px;
        padding-left: 28px; }

    &__curve {
        @include position(absolute, 100% null null 0);
        width: 100%;
        z-index: 750; }

    &__search {
        cursor: pointer; }

    &__nav {
        @include position(absolute, 95px null null 0);
        background: $lily-white;
        box-sizing: border-box;
        height: calc(100vh - 90px);
        opacity: 0;
        padding: 50px 0;
        pointer-events: none;
        transform: translateX(100%);
        transition: transform .5s ease, opacity .75s ease;
        width: 100%;
        z-index: 500;

        #{$this}--nav-active & {
            overflow: scroll; }

        @media(min-width: $large) {
            @include position(absolute, 150px null null 0);
            height: calc(100vh - 150px); }

        &--active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0); } }

    @media(min-width: $large) {
        height: 150px;

        &__top-button {
            box-sizing: border-box;
            display: flex;
            margin-left: 5px;
            min-height: 48px; }

        &__top {
            align-items: center;
            display: flex;
            margin-bottom: 20px;
            margin-top: 20px; }

        &__search-form {
            overflow: hidden; }

        &__main {
            border-left: 0; }

        &__content {
            align-items: flex-end;
            flex-direction: column;
            justify-content: center;
            width: 100%; }

        &__nav {
            left: auto;
            right: 0;
            width: 420px; }

        &__search {
            margin-left: 10px;
            margin-right: 10px;
            padding-right: 10px; }
        &__icon {
            margin-right: 5px; } }

    @media(min-width: $xlarge) {
        &__top {
            margin-bottom: 15px;
            margin-top: 10px; }

        &__main {
            margin: 0;
            padding: 0; }

        &__content {
            justify-content: flex-start; }

        &__nav {
            background: transparent;
            height: auto;
            opacity: 1;
            overflow: initial;
            padding: 0;
            pointer-events: all;
            position: relative;
            top: 0;
            transform: none;
            width: auto; }

        &__curve {
            z-index: 500; } } }
