// Section
//
// .section--styled - Styled variation
//
// Markup: ./section.hbs
//
// Styleguide layouts.section

.section {
    $this: &;
    padding-bottom: 50px;
    padding-top: 50px;

    + #{$this}:not(.section--styled) {
        padding-top: 0; }

    + .wysiwyg img {
        margin-bottom: 0;
        margin-top: 0; }
    &__no-padding {
        padding-bottom: 0px; }
    &__asset {
        @include position(absolute, -125px -125px null null);
        z-index: -1;

        &--yellow {
            fill: $lightning-yellow; }

        &--purple {
            fill: $purple; }

        &--lily-white {
            fill: $lily-white; }

        &--offset {
            top: -65px; } }

    &--styled {
        background: $lily-white; }

    &--overlap {
        position: relative;

        &:before {
            @include position(absolute, 0 null null 0);
            background: $white;
            content: '';
            display: block;
            height: 200px;
            width: 100%; }

        .container {
            position: relative; } }

    &--overlay {
        &:after {
            background-color: rgba(59,59,59,.75);
            content: ''; } }

    &--has-asset {
        overflow: hidden;
        position: relative;
        z-index: 1; }

    &--v-double {
        padding-bottom: 50px;
        padding-top: 50px; }

    @media (min-width: $large) {
        &--fade {
            position: relative;

            &::before {
                background-image: linear-gradient(to left, rgba($black, 0), rgba($white, 1) 100%);
                content: '';
                height: 100%;
                left: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: 100px;
                z-index: 1; }

            &::after {
                background-image: linear-gradient(to right, rgba($black, 0), rgba($white, 1) 100%);
                content: '';
                height: 100%;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                width: 300px;
                z-index: 1; } }

        &--v-double {
            padding-bottom: 100px;
            padding-top: 100px; } }

    @media (max-width: $large) {
        &__asset {
            display: none; } } }
