// textarea
//
// .parsley-error - Error styling
//
// Markup: ./textarea.hbs
//
// Styleguide elements.form.textarea

.textarea {
    $this: &;
    background: $white;
    border: 2px solid $cerulean;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: inherit;
    font-size: rem(16);
    max-width: 400px;
    padding: 11px 9px;
    width: 100%;

    &::-webkit-input-placeholder {
        color: rgba($mine-shaft, .3); }

    &::-moz-placeholder {
        color: rgba($mine-shaft, .3); }

    &:-ms-input-placeholder {
        color: rgba($mine-shaft, .3); }

    &.parsley-error {
        border: 2px solid $error; } }
