// full form
//
// Full form using all the form elements, including parsley validation.
//
// Markup: ./form.hbs
//
// Styleguide elements.form.full form

.form-group {
    margin-bottom: 15px;

    &--spaced-top {
        margin-top: 1.5em; }

    @media(min-width: 1024px) {
        margin-bottom: 25px; }

    &--date-picker {
        input {
            $this: &;
            background: $white;
            border: 2px solid $cerulean;
            border-radius: 8px;
            box-sizing: border-box;
            font-size: rem(16);
            max-width: 400px;
            padding: 11px 9px;
            width: 100%;

            &::-webkit-input-placeholder {
                color: rgba($mine-shaft, .3); }

            &::-moz-placeholder {
                color: rgba($mine-shaft, .3); }

            &:-ms-input-placeholder {
                color: rgba($mine-shaft, .3); }

            &.parsley-error {
                border: 2px solid $error; } } } }
.form-radio {
    flex-wrap: wrap;
    justify-content: left;
    display: flex;
    .parsley-errors-list {
        order: 50; } }
.radio-group {
    display: flex;
    margin: 5px; }

