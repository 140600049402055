.donation {
  #content {
    background-color: $purple; }

  h1,
  p {
    color: $white; }

  &__heading {
    color: $white;
    &--center {
      text-align: center; }

    &--bottom-margin {
      margin-bottom: 32px; } }

  &__copy {
    margin-bottom: 32px;
    margin-left: 2.85712%;
    margin-right: 2.85712%; }

  &__link {
    color: $white;
    text-decoration: underline; }

  .button {
    // @extend %button
    font-size: 16px;

    span.btn_icon {
      padding-top: 18px; } }

  .u-align-center {
    text-align: center; }

  .donation__communication {
    .custom_check {
      text-align: left;
      margin-left: 24px; } }

  .odd {
    .button {
      background-color: $brightblue;
      .btn_icon {
        background-color: $darkblue; } } }

  &__block {
    width: calc(50% - 40px);
    float: left;
    margin: 0 20px; }

  &__text {
    color: $white; }

  .grid {
    @include clearfix;
    .half {
      float: left;
      padding: 20px;
      width: calc(100% - 40px);
      @include breakpoint-context($tablet) {
        width: calc(50% - 40px); } } } }

@include breakpoint-context($small-tablet) {
  .donation__communication {
    .custom_check {
      width: calc(50% - 32px);
      display: inline-block; } } }

@include breakpoint-context($tablet) {
  .donation__communication {
    .custom_check {

      margin-right: 16px;
      margin-left: 0;
      width: initial; } } }
