// nav icon
//
// Markup: ./nav-icon.hbs
//
// Styleguide components.nav icon

.nav-icon {
    $this: &;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    user-select: none;
    z-index: 1;

    span {
        background: $cerulean2;
        border-radius: 3px;
        display: block;
        height: 4px;
        margin-bottom: 4px;
        position: relative;
        transform-origin: 4px 0;
        transition: transform .5s cubic-bezier(.77, .2, .05, 1), background .5s cubic-bezier(.77, .2, .05, 1), opacity .55s ease;
        width: 32px;
        z-index: 1;

        &:first-child {
            transform-origin: 0% 0%; }


        &:nth-last-child(2) {
            transform-origin: 0% 100%; } }

    &--active {
        span {
            background: $cerulean2;
            opacity: 1;
            transform: rotate(45deg) translate(-5px, -16px);

            &:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(.2, .2); }

            &:nth-last-child(2) {
                transform: rotate(-45deg) translate(-2px, 15px); } } }

    @media(min-width: $xlarge) {
        display: none; } }
