.ugc {
    @extend %default-font;
    color: $text-color;

    img {
        width: 100%;
        height: auto; }

    p,ul,ol,blockquote {
        @include trailer(1); }

    ul,ol {
        @include leader(.2); }

    ul {
        clear: left;
        list-style: none;

        li {
            @include svg-background("../images/icons/bullet");
            @include trailer(.5);
            background-repeat: no-repeat;
            background-position: 0 9px;
            padding-left: 20px; } }

    ol {
        list-style-type: none;
        counter-reset: ol-counter;
        margin-left: 15px;

        li {
            @include trailer(.5);
            position: relative;
            padding-left: 8px; } }

    ol > li:before {
        font-family: $heading-font-stack;
        @include adjust-font-size-to(20px);
        content: counter(ol-counter);
        counter-increment: ol-counter;
        color: $secondary-color;
        left: -15px;
        position: absolute;
        top: 0;
        width: 15px; }

    .intro {
        font-family: $heading-font-stack;
        @include adjust-font-size-to(24px);
        line-height: 1.5em; }

    iframe.youtube-player {
        width: 100%; }

    video {
        width: 100%;
        height: auto;
        display: block; }

    .img_center {
        width: 100% + gutter-span();
        height: auto;
        margin-left: - gutter-span()/2;
        margin-right: - gutter-span()/2; }

    .custom-font {
        font-family: $heading-font-stack; }

    // Responsive
    //********************************************************
    @include breakpoint-context( 'all' ) {
        .img_center {
            width: 100% + gutter-span();
            margin-left: - gutter-span()/2;
            margin-right: - gutter-span()/2; } }

    @include breakpoint-context($mobile-landscape);

    @include breakpoint-context($small-tablet);

    @include breakpoint-context($tablet) {
        .img_center {
            width: 100% + gutter-span()*2;
            margin-left: - gutter-span();
            margin-right: - gutter-span(); } }

    @include breakpoint-context($desktop); }
