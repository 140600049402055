// search field
//
// Markup: ./search-field.hbs
//
// Styleguide components.search field

.search-field {
    $this: &;
    @include position(absolute, null null -115px 0);
    align-content: center;
    background: $lily-white;
    box-shadow: 0 8px 10px 0 rgba($black, .18);
    box-sizing: border-box;
    display: flex;
    height: 115px;
    justify-content: center;
    padding: 0 20px 20px;
    pointer-events: none;
    transform: translateX(100%);
    transition: transform .5s ease, opacity .75s ease;
    width: 100%;
    z-index: 100;

    &__inner {
        align-self: flex-end;
        max-width: 400px;
        position: relative;
        width: 100%; }

    &__button {
        @include position(absolute, 50% 5px null null);
        background: transparent;
        border: 0;
        transform: translateY(-50%); }

    &--active {
        opacity: 1;
        pointer-events: all;
        transform: translateX(0); }

    @media(min-width: $xlarge) {
        @include position(relative, null null auto auto);
        background: transparent;
        box-shadow: none;
        height: auto;
        padding: 0;
        transform: translateX(100%);
        width: auto;

        &__input {
            padding: 10px 9px 11px;

            &::placeholder {
                color: $mine-shaft; } }

        &--active {
            transform: translateX(0); }

        &__button {
            display: none; } } }
