#donate_block {
  @extend %gradient_purple;
  @include padding-leader(2);
  text-align: center;

  h2 {
    @include adjust-font-size-to(26px);
    @include trailer(1.5);
    color: $white;
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  .button {
    @extend %button;
    @include adjust-font-size-to(16px);
    display: block; } }


// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  #donate_block {
    @include padding-leader(3);
    @include padding-trailer(3);
    .button {
      @include adjust-font-size-to(20px);
      margin-left: auto;
      margin-right: auto;
      width: column-span(2.5,0,6); } } }

@include breakpoint-context($desktop) {
  #donate_block {
    @include padding-leader(3);
    @include padding-trailer(3);
    .button {
      width: column-span(3,0,9); } } }

