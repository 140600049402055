// Contact-details
//
// Markup: ./contact-details.hbs
//
// Styleguide components.contact-details

.contact-details {
    $this: &;

    &__item {
        display: flex;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0; } }

    &__icon {
        align-self: flex-start;
        fill: $cerulean;
        flex-shrink: 0;
        margin-right: 20px;
        margin-top: 3px;
        width: 22px; }

    &__detail {
        align-self: flex-start;
        font-size: rem(18); } }
