blockquote {
  font-family: $heading-font-stack;
  @include adjust-font-size-to(30px);
  padding: rhythm(1.5) 10px rhythm(1.5);
  color: $purple;
  background-repeat: no-repeat;
  margin: 20px auto;
  max-width: 960px;
  .multiplebgs & {
    @include background( url("../images/icons/quote.png") scroll no-repeat left top, url("../images/icons/quote_end.png") scroll no-repeat right bottom ); }
  .multiplebgs.svg & {
    background-image: url("../images/icons/quote.svg"), url("../images/icons/quote_end.svg"); } }

.quote_block_wrapper {
  @include padding-leader(3);
  @include padding-trailer(4);
  position: relative;
  overflow: hidden;

  &.green {
    @extend %gradient_green;

    blockquote,
    cite {
      color: $white; } }

  blockquote {
    @include adjust-font-size-to(24px);
    padding: rhythm(2.5) 0 rhythm(1);
    margin-left: gutter-span();
    margin-right: gutter-span();
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%; }

  blockquote,
  cite {
    position: relative;
    color: $purple; }

  cite {
    @include leader(1);
    display: block; }

  .bkg_icon {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 160px;
    height: 160px;
    @include svg-background('../images/icons/purple_sun', $background-size: 160px auto); } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {
  .quote_block_wrapper {
    blockquote {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }

@include breakpoint-context($tablet) {
  .quote_block_wrapper {
    blockquote {
      margin-left: gutter-span()*3;
      margin-right: gutter-span()*3; }
    .center {
      position: relative; }

    .bkg_icon {
      @include svg-background('../images/icons/purple_sun', $background-size: 260px auto);
      width: 260px;
      height: 260px;
      background-repeat: no-repeat;
      bottom: -170px;
      left: -160px; } } }

