#search_results {
  margin-left: gutter-span();
  margin-right: gutter-span();
  .info {
    @include trailer(1);
    color: $white; } }

#search_form {
  @include trailer(1);
  margin-left: gutter-span();
  margin-right: gutter-span();

  .field {
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
    vertical-align: top;

    &.search_field {
      width: calc(100% - 80px);
      input {
        height: 54px; } }
    &.search_button {
      width: 80px;
      input {
        @include svg-background('../images/icons/search');
        @include transition-duration(200ms);
        @include transition-property('background-color');
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $button-color;
        width: 80px;
        border: none;
        height: 0;
        padding-top: 42px;
        overflow: hidden;
        cursor: pointer;
        text-indent: -9999px;
        &:hover {
          background-color: $button-hover-color; } } } } }



// Responsive
//********************************************************

@include breakpoint-context('all');

@include breakpoint-context($tablet) {
  #search_results,
  #search_form {
    width: column-span(4,0,6);
    margin-left: auto;
    margin-right: auto;
    fieldset {
      margin-left: 0;
      margin-right: 0; } } }


@include breakpoint-context($desktop) {
  #search_results,
  #search_form {
    @include box-sizing('border-box');
    padding-left: 30px;
    padding-right: 30px;
    fieldset {
      margin-left: 0;
      margin-right: 0; } }

  #search_form {
    .field {
      &.search_field {
        width: 220px; } } } }
