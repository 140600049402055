// Pagination
//
// Markup: ./pagination.hbs
//
// Styleguide components.Pagination

.pagination {
    $this: &;

    &__list {
        text-align: center; } }
