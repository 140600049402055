.text-image {
  @include clearfix;
  &.green {
    background-color: $green; }
  &.blue {
    background-color: $brightblue; }
  &.purple {
    background-color: $purple; }
  &.red {
    background-color: $red; }
  &.yellow {
    background-color: $yellow; }

  &__text {
    text-align: center;
    color: $white;
    padding: 50px;

    .yellow & {
        color: $purple; }

    h2 {
      color: $white;

      .yellow & {
          color: $purple; } } }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 360px; }

  @include breakpoint-context($tablet) {
    .text-image {
      &__text {
        float: left;
        padding: 50px;
        width: calc(50% - 100px); }

      &__image {
        float: left;
        width: 50%;
        height: 560px; } } }

  @include breakpoint-context($desktop) {
    .text-image {
      &__text {
        padding: 50px 120px;
        width: calc(50% - 240px); } } } }
