.submissions {
  .ugc {
    margin-bottom: 48px; }

  .participant {
    &__heading {
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid $mid-grey;
      padding: 0 0 12px;
      margin: 12px 0;

      &::after {
          content: '';
          display: inline-block;
          height: 7px;
          width: 7px;
          border-right: 2px solid black;
          border-bottom: 2px solid black;
          transform: rotate(-45deg);
          position: absolute;
          top: 10px;
          left: 110px; } }

    &__data {
      @include clearfix();
      margin-bottom: 32px; }

    &__column {
      margin-bottom: 16px; }

    &--limit {
      color: $red; }

    @include breakpoint-context($small-tablet) {
      &__column {
        float: left;
        margin-left: 5px;
        margin-right: 5px;
        width: calc(50% - 10px);
        word-break: break-word; } } } }
