// input - Password
//
// Markup: ./input-password.hbs
//
// Styleguide elements.form.input password

.input-password {
    max-width: 400px;
    position: relative;

    & ~ input {
        margin-top: 10px; }

    &__toggle {
        @include position(absolute, 25px 10px null null);
        cursor: pointer;
        transform: translateY(-50%);

        &--hide-password {
            display: none; } } }
