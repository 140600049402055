%placeholder {
  background-image: url("../images/stock/placeholder.png");
  background-color: $primary-color;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: center center; }

%image_cover {
  background: no-repeat;
  background-position: center center;
  background-size: cover; }
