#content_main {
  .basket &,
  .checkout & {
    position: relative; }

  .basket-row {
    &--fade {
      transition: opacity .5s ease-in-out;
      opacity: 0; } }

  .basket-row {
    &--hide {
      display: none; } }

  .icon_flower1,
  .icon_flower2 {
    position: absolute;
    width: 188px;
    height: 188px;
    background-position: center center;
    background-repeat: no-repeat; }

  .icon_flower1 {
    @include svg-background('../images/icons/basket_flower1');
    right: -60px;
    top: 20px;
    z-index: 1; }
  .icon_flower2 {
    @include svg-background('../images/icons/basket_flower2');
    left: -100px;
    top: 220px;
    z-index: 1; } }

#basket_table_wrapper {
  @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
  @include leader(2);
  @include trailer(2);
  background-color: $egg;
  margin-left: gutter-span();
  margin-right: gutter-span();
  padding: 20px;
  position: relative;
  z-index: 2;
  h2 {
    @include trailer(.5); }

  .stock-limit {
    padding: 10px;
    color: $white;
    background: $brightblue;
    border: 1px solid $darkblue;
    margin-bottom: 1.25em; } }

#basket_table,
#basket_summary {
  overflow-x: auto;
  background-color: $egg;
  background-position: 0 0, 100% 0, 0 0, 100% 0;
  background-repeat: no-repeat;
  background-size: 8em 100%, 8em 100%, 2em 100%, 2em 100%;
  background-attachment: local, local, scroll, scroll;
  .donation & {
    color: $text-color;
    background-color: $egg; } }
.basket_order_view,
.order_table {
  border-top: 2px solid $mid-grey;
  .donation & {
    border-top-color: $mid-grey; }
  display: table;
  td,
  th {
    padding: 20px 10px; }
  th {
    border-bottom: 2px solid $mid-grey;
    .donation & {
      border-top-color: $mid-grey; } } }

#basket_table {
  .quantity_number {
    width: 95px; } }

.basket_order_view {
  font-size: (14/18)+rem;
  @include breakpoint-context($tablet) {
    font-size: 1rem; }
  tr.single_item {
    border-bottom: 2px solid $mid-grey;
    .donation & {
      border-top-color: $purple; } }
  td.product_col {
    min-width: 120px; }
  td.quantity_col {
    min-width: 50px; }
  td.total {
    min-width: 50px; }
  td.remove_col {
    min-width: 50px;
    input.remove_button {
      @include box-shadow(none);
      cursor: pointer;
      width: 50px;
      height: 50px;
      text-indent: -9999px;
      background: $egg url("../images/icons/close.png") center center no-repeat; } }

  tr.total_row {
    td {
      @extend %bold-font; } }

  tr#basket_buttons {
    .button {
      margin-left: 0 !important; } } }

.single_column {
  .checkout_title {
    margin-left: gutter-span()*1.5; } }


.no_user_fields {
  h2.no_user_checkout {
    @include trailer(1);
    margin-left: gutter-span();
    color: $white; }

  #checkout_login_form,
  #checkout_reg_form {
    legend {
      @include adjust-font-size-to(24px); } }

  #proceed_as_guest {
    margin-left: gutter-span();
    margin-right: gutter-span();
    h3 {
      color: $white; }

    .button {
      @include leader(1);
      @extend %button;
      .btn_icon {
        text-align: center!important; } } } }

.order_table {
  tfoot {
    td {
      border-bottom: 2px solid $mid-grey;
      .donation & {
        border-top-color: $purple; } } }
  .description {
    width: 450px; }
  .quantity {
    width: 100px; }
  .price {
    width: 100px; }
  .num {
    font-weight: bold; } }


#security_ugc {
  @include padding-leader(2);
  @include padding-trailer(2);
  .ugc {
    margin-left: gutter-span();
    margin-right: gutter-span(); } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {

  .no_user_fields {
    h2.no_user_checkout {
      margin-left: gutter-span(); } }

  .single_column {
    .checkout_title {
      margin-left: gutter-span()*1.5; } }

  #proceed_as_guest {
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  #security_ugc {
    .ugc {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }


@include breakpoint-context($small-tablet) {

  #basket_table,
  #basket_summary {
    overflow-x: auto;
    background-image: none; }

  .basket_order_view,
  .order_table {
    width: 100%;
    tr#basket_buttons,
    tr.continue_row {
      td {
        text-align: right; } } }

  .no_user_fields {

    h2.no_user_checkout {
      margin-left: gutter-span()*3.5; }

    #checkout_login_form,
    #checkout_reg_form {
      input {
        margin-left: 0; } }

    #proceed_as_guest {
      h3,
      .button {
        margin-left: gutter-span()*2; }
      .button {
        width: column-span(2.5,0,6); } } } }

@include breakpoint-context($tablet) {
  #security_ugc {
    .ugc {
      margin-left: auto;
      margin-right: auto;
      width: column-span(5,0,6); } } }


@include breakpoint-context($desktop) {
  .single_column {
    .checkout_title {
      padding-left: gutter-span(); } }

  #content_main {
    .basket &,
    .checkout & {
      .icon_flower1 {
        right: 10px;
        top: -60px; } } }


  #basket_table_wrapper {
    margin-left: column-span(1,0,9) + gutter-span();
    margin-right: column-span(1,0,9) + gutter-span(); }

  .no_user_fields {
    h2.no_user_checkout {
      margin-left: gutter-span()/2; }

    #checkout_login_form,
    #checkout_reg_form {
      display: inline;
      float: left;
      fieldset {
        display: inline-block;
        vertical-align: top;
        margin-left: 15px;
        margin-right: 12px;
        float: left;
        width: 300px;
        .field {
          margin-left: 0;
          margin-right: 0; } }
      legend {
        margin-left: 0; }
      .input_btn {
        margin-left: 0;
        width: 300px; }
      .breaker {
        float: left;
        width: 2px;
        height: 410px;
        margin: 0; } }

    #proceed_as_guest {
      display: inline-block;
      vertical-align: top;
      margin-left: 15px;
      margin-right: 0;
      float: left;
      width: 280px;
      h3,
      .button {
        margin-left: 0; }
      .button {
        width: 100%; }
      h3 {
        margin-bottom: 35px; } } }

  #security_ugc {
    .ugc {
      width: column-span(6.5,0,9); } } }
