.form-add {
  color: #c70077;
  font-family: $heading-font-stack;
  font-size: 22px;

  &:before {
    @include size(36px);
    @include margin(-5px 15px null null);
    border-radius: 50%;
    content: "";
    display: inline-block;
    vertical-align: middle;
    box-shadow: 4px 4px 8px rgba(#000, 0.2);
    background-color: #00adf2;
    background-image: url('../images/backgrounds/form-add-plus.png');
    background-position: center;
    background-repeat: no-repeat; }

  &--disabled {
    cursor: not-allowed; } }
