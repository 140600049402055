// select
//
// Markup: ./select.hbs
//
// Styleguide elements.form.select

.select-flex {
    display: flex; }

.custom-select {
    background-color: $white;
    border: 2px solid $cerulean;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: inherit;
    max-width: 400px;
    position: relative;
    width: 100%;

    &__select {
        appearance: none;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        font-size: rem(18);
        padding: 10px 9px;
        width: 100%;

        &::-ms-expand {
            display: none; }

        &:focus {
            outline: none; }

        option[hidden] {
            color: rgba($mine-shaft, .3);
            opacity: .5; } }

    &__arrow {
        @include position(absolute, 50% 15px null null);
        pointer-events: none;
        transform: translateY(-50%); } }
