// scroller
//
// Markup: ./scroller.hbs
//
// Styleguide components.scroller

.scroller {
    $this: &;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba($black, .17);
    font-size: rem(12);
    height: 150px;
    line-height: 18px;
    overflow: hidden;

    &__inner {
        box-sizing: border-box;
        height: 100%;
        overflow-y: scroll;
        padding: 15px 20px 2px; } }
