// cqc widget
//
// 3rd party widget
//
// Markup: ./cqc-widget.hbs
//
// Styleguide components.cqc widget

.cqc-widget {
    &.cleanslate {
        box-sizing: border-box !important;
        margin: 0 20px !important;
        max-width: calc(100% - 40px) !important;
        width: 960px !important;

        @media(min-width: $medium) {
            margin: auto !important; } } }
