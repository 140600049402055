#event-styling {
    .events {
        padding-top: 75px;
        padding-bottom: 80px;
        background-color: $lily-white;

        &__title {
            text-align: center;
            color: $rose;

            + * {
                margin-top: 50px;

                @media (min-width: 768px) {
                    margin-top: 90px; } }

            h2 {
                font-size: 36px;
                margin-bottom: 0;

                @media (min-width: 768px) {
                    font-size: 50px; } } }

        &__list {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            gap: 55px 50px;

            @media (min-width: 768px) {
                grid-template-columns: repeat(2, minmax(0, 1fr)); }

            + * {
                margin-top: 32px; } }

        &__item {
            border-radius: 20px;
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.16); }

        &__image {
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; } }

        &__image-wrap {
            &::before {
                content: '';
                display: block;
                padding-bottom: 182 / 316 * 100%;

                @media (min-width: 768px) {
                    padding-bottom: 321 / 558 * 100%; } } }

        &__tag {
            position: absolute;
            left: 15px;
            bottom: 0;
            transform: translateY(50%);
            background-color: $apple;
            color: #fff;
            border-radius: 23px;
            padding: 6px 26px;
            font-size: 12px;
            line-height: 1;

            @media (min-width: 768px) {
                left: 30px;
                font-size: 18px;
                padding: 11px 40px; } }

        &__content {
            background-color: #fff;
            padding: 44px 15px 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (min-width: 768px) {
                padding: 52px 30px 30px; }

            h3 {
                color: $rose;
                font-size: 28px;
                margin-bottom: 0;

                @media (min-width: 768px) {
                    font-size: 32px; }

                + * {
                    margin-top: 13px; } }

            p {
                margin-bottom: 0; }

            .button {
                margin-top: 20px; } }

        &__date,
        &__location {
            font-family: $heading-font-family; }

        &__date {
            color: $rose;
            font-size: 16px;
            margin-bottom: 0;

            @media (min-width: 768px) {
                font-size: 20px; }

            + * {
                margin-top: 20px; } }

        &__location {
            font-size: 16px;
            color: $mine-shaft;

            @media (min-width: 768px) {
                font-size: 20px; }

            + * {
                margin-top: 20px; } }

        &__price {
            font-weight: 700;
            color: $rose;
            font-size: 24px;
            margin-top: auto;
            padding-top: 20px;

            @media (min-width: 768px) {
                padding-top: 40px; }

            + * {
                margin-top: 10px; } } } }
