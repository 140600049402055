#event_basket_popup {
  @include svg-background('../images/backgrounds/header_curve', $background-size: 600px auto);
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  background-color: $darkpurple;
  color: #fff;
  text-align: center;

  &:before {
    @include svg-background('../images/backgrounds/header_curve_blue', $background-size: 600px auto);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-position: center top;
    background-repeat: no-repeat; }

  &.main_menu_visible {
    background: $darkpurple none;
    z-index: 1;
    &:before {
      content: ""; }
    .center {
      @include padding-leader(1); } }

  #event_basket_wrapper {
    position: relative;
    text-align: left;
    display: none; }

  .center {
    @include transition-duration('500ms');
    @include transition-property(padding-top);
    @include padding-leader(2.5);
    @include padding-trailer(1); }

  .event_order_summary {
    width: column-span(2,1) - gutter-span();
    margin-left: auto;
    margin-right: auto; }

  .heading {
    @include adjust-font-size-to(h(3));
    @include trailer(0.5, h(3));
    color: #fff; }

  .photo_count {
    @include svg-background('../images/icons/photos');
    @include trailer(0.5);
    padding-left: 40px;
    background-position: 0 3px;
    background-repeat: no-repeat; }

  .button {
    @extend %follow-on-button;
    width: column-span(2,1) - gutter-span(); } }


#hide_basket_link {
  @include adjust-font-size-to(14px);
  position: absolute;
  top: 100%;
  left: 75%;
  background: $darkpurple;
  color: #fff;
  padding: 0 15px;
  &:hover,
  &:focus {
    background: $purple; } }

#show_basket_wrapper {
  @include padding-leader(0.5);
  @include padding-trailer(0.5);
  display: block;
  overflow: hidden; }

#show_basket_link {
  font-family: $heading-font-stack;
  @include svg-background('../images/icons/photos');
  color: #fff;
  padding-left: 40px;
  background-position: 0 3px;
  background-repeat: no-repeat;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: underline; } }

// Resposnive
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@include breakpoint-context($mobile-landscape) {
  #event_basket_popup {
    .svg &,
    &,
    .svg &:before,
    &:before {
      background-size: 1000px auto; } } }

@include breakpoint-context($small-tablet) {
  #event_basket_popup {
    .center {
      @include padding-leader(1); }
    .event_order_summary,
    .button {
      width: column-span(2,1); } } }

@include breakpoint-context($tablet) {
  #event_basket_popup {
    .event_order_summary,
    .button {
      width: column-span(3,1); } } }

@include breakpoint-context($desktop) {
  #event_basket_popup {
    .svg &,
    &,
    .svg &:before,
    &:before {
      background-size: 2560px auto; } }

  #event_basket_popup {
    .event_order_summary {
      @include float-left;
      width: column-span(3,1);
      margin-left: grid-span(2,1) + gutter-span()/2;
      margin-right: gutter-span()/2; }

    .button {
      @include float-left;
      width: column-span(3,1);
      margin-left: gutter-span()/2; } } }
