// messages
//
// Paragraph tags are added by C5 and should be thought of when making CSS changes here.
//
// Markup: ./messages.hbs
//
// background: #e3f7ff
//
// Styleguide components.messages

.messages {
    padding: 75px 25px 50px;

    &__content {
        margin: auto;
        max-width: 960px;

        &--error {
            color: $thunderbird; }

        &--info {
            color: $cerulean; }

        &--success {
            color: $dull-lime; } }

    &__title {
        font-family: $heading-font-family;
        font-size: 1.66667em;
        line-height: 1.5em;
        margin-bottom: .83333em; } }
