// input
//
// .parsley-error - Error styling
//
// Markup: ./input.hbs
//
// Styleguide elements.form.input

.input {
    $this: &;
    background: $white;
    border: 2px solid $cerulean;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: inherit;
    font-size: rem(16);
    max-width: 400px;
    padding: 12px 9px 11px;
    width: 100%;

    &::-webkit-input-placeholder {
        color: rgba($mine-shaft, .3); }

    &::-moz-placeholder {
        color: rgba($mine-shaft, .3); }

    &:-ms-input-placeholder {
        color: rgba($mine-shaft, .3); }

    &.parsley-error {
        border: 2px solid $error; }

    &--transparent {
        background: transparent;
        border-color: $white;
        color: $white;

        &::-webkit-input-placeholder {
            color: rgba($white, .6); }

        &::-moz-placeholder {
            color: rgba($white, .6); }

        &:-ms-input-placeholder {
            color: rgba($white, .6); } } }

.c5-form {
    .form-control {
        background: $white;
        border: 2px solid $cerulean;
        border-radius: 8px;
        box-sizing: border-box;
        font-size: rem(16);
        max-width: 400px;
        padding: 11px 9px;
        width: 100%; } }
