.enewsletter_wrapper {
  @extend %gradient_red;
  padding: 3em 0.5em;
  @include box-shadow(inset 0 0 20px rgba(0,0,0,0.5));
  position: relative;
  overflow: hidden;

  .bkg_icon {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 160px;
    height: 160px;
    @include svg-background('../images/icons/red_icon1', $background-size: 160px auto); }

  h2 {
    @include trailer(.5);
    @include adjust-font-size-to(26px);
    text-align: center;
    color: $white; }

  p {
    color: $white;
    text-align: center;
    margin-bottom: 16px; }

  .ccm-block-express-form {
    &#form_wrapper {
      background-color: transparent;
      padding-top: 1em;
      padding-bottom: 3em; }

    .form-actions {
      .button {
        display: block;
        margin-left: auto;
        margin-right: auto; } } } }

#enewsletter_form {
  * {
    box-sizing: border-box; }
  .field {
    &.buttons {
      @include leader(1);
      margin-left: 0;
      margin-right: 0; } }
  .custom_check label {
    color: $white; }
  .custom_check {
    font-size: 0;
    .control-label {
      width: 50%;
      display: inline-block;
      font-size: 1rem;
      @include breakpoint-context($tablet) {
        width: 25%; } } } }


// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  .enewsletter_wrapper {

    .ccm-block-express-form {
      &#form_wrapper {
        background-color: transparent;
        padding-top: 1em;
        padding-bottom: 3em; }

      .form-group {
        width: calc(33% - 2.77778%);
        float: left;
        padding-left: 1.38889%;
        padding-right: 1.38889%; } } }

  #enewsletter_form {
    .third {
      position: relative;
      left: 5px;
      margin-left: 0;
      margin-right: 0;
      padding-left: gutter-span()/2;
      padding-right: gutter-span()/2; }

    .half {
      display: inline-block;
      vertical-align: top; }
    .buttons {
      text-align: center;
      .button {
        margin-left: auto;
        margin-right: auto; } } } }

@include breakpoint-context($desktop) {
  .enewsletter_wrapper {
    .center {
      position: relative; }
    .bkg_icon {
      bottom: -100px;
      left: -60px; } }

  #enewsletter_form {
    width: column-span(6,0,9) + gutter-span()*2;
    margin-left: auto;
    margin-right: auto; } }
