.c-events-5050 {
    max-width: 1400px;
    margin: 0 auto;
    padding: 152px 16px;

    &__container {
        display: grid;
        gap: 42px 92px;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr)); } }

    h2 {
        font-size: 40px;
        line-height: 1;

        + * {
            margin-top: 40px; } }

    &__media {
        iframe {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: auto; } }

    &__slider {
        position: relative;

        img {
            aspect-ratio: 1024 / 768;
            object-fit: cover;
            width: 100%; }

        .slick-list {
            max-width: calc(100vw - 46px);
            width: 100%; } }

    &__slider-controls {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: $rose;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 16px;

        button {
            padding: 12px 16px;
            background-color: transparent;
            border: 0;
            cursor: pointer;

            svg {
                width: 24px;
                height: 24px;
                transition: transform 200ms ease-out; }

            &:hover {
                transition-duration: 350ms; }

            &:first-child svg {
                transform: rotate(180deg); }

            &:first-child:hover svg {
                transform: rotate(180deg) translateX(5px); }

            &:last-child svg {
                transform: rotate(0deg); }

            &:last-child:hover svg {
                transform: rotate(0deg) translateX(5px); } } }

    .slick-slide {
        > div > div {
            display: block !important; } }

    &__content {
        li {
            position: relative;
            padding-left: 48px;

            + li {
                margin-top: 32px; }

            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 32px;
                height: 32px;
                background-color: #FFC9F0;
                border-radius: 50%; }

            &::after {
                content: '';
                position: absolute;
                top: 9px;
                left: 9px;
                background-image: url('../images/icons/check_small.svg');
                width: 15px;
                height: 12px;
                background-size: 100%; } }

        &--dots {
            li::before {
                display: none; }

            li:after {
                background-image: url('../images/icons/swirl_purple.svg');
                width: 32px;
                height: 32px;
                top: -1px;
                left: 0; } }

        &--flower-dots {
            li::before {
                display: none; }

            li:after {
                background-image: url('../images/icons/pink-flower2.svg');
                width: 32px;
                height: 32px;
                top: -1px;
                left: 0; } } } }


