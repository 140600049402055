// people listing
//
// Markup: ./people-listing.hbs
//
// Styleguide components.people-listing

$peopleGutter: 10px;

.c-people-listing {
  margin-bottom: 60px;

  > .container {
    position: relative; }

  &__title {
    h2 {
      font-size: 40px;
      margin-bottom: 40px;
      margin-top: 60px; } }

  &__collection {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -$peopleGutter;

    > * {
      box-sizing: border-box;
      flex: 0 0 100%;
      padding: 0 $peopleGutter 40px;

      @media(min-width: 767px) {
        flex-basis: 50%; }

      @media(min-width: 1024px) {
        flex-basis: 33.33%; } } }

  &__item {
    display: flex;
    flex-direction: column;

    > * {
      flex: 1; }

    @media(min-width: 767px) {
      margin: 0; }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 20px; }

    &-name {
      margin: 0; }

    &-role {
      margin-bottom: 40px; }

    &-link {
      margin-top: auto; } } }

