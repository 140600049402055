#article_tag_filter {
  label {
    .shop-online & {
      color: $white; } } }

#article_tag_filter {
  input {
    height: 50px; }
  label {
    color: $text-color; }

  .field {
    margin-left: 0;
    margin-right: 0; }

  .button {
    @extend %input_btn_arrow;
    background-position: right center; }

  .reset_filter {
    margin-left: 0; } }


// Responsive
//********************************************************

@include breakpoint-context('all');


@include breakpoint-context($tablet) {
  .shop-online {
    #article_tag_filter {
      fieldset {
        margin-left: 0;
        width: 320px;
        display: inline-block;
        vertical-align: top;
        margin-right: gutter-span();
        &.buttons {
          margin-top: 1.9em;
          .button {
            width: 100%; } } }
      .field {
        margin-left: 0; } } } }

@include breakpoint-context($desktop) {
  .shop-online {
    #article_tag_filter {
      fieldset {
        width: 280px; } } } }
