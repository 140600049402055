#outer_wrapper {
  .search & {
    @extend %gradient_red;

    h1,
    .ugc {
      color: $white; }

    h1.page_title {
      color: $white;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px;
      width: 63.88889%; }

    .article_listing {
      padding: 0;
      margin: 0; }

    .article_stub_item {
      width: 100%;
      margin: 0;
      padding: 0; }

    .article_stub_link {
      margin: 0 4.54545% 1.66667em;
      padding: 0; }

    .article_stub_content {
      width: calc(100% - 40px); }

    @include breakpoint-context($small-tablet) {
      #content {
        background-size: 140px;
        background-image: url('../images/icons/yellow_sun.svg');
        background-repeat: no-repeat;
        background-position: 0 0; }

      .article_stub_item {
        width: 100%; }

      .article_stub_link {
        height: auto; }

      .article_stub_content {
        height: auto; } }

    @include breakpoint-context($tablet) {
      .article_stub_link {
        height: 180px; }

      .article_stub_content {
        height: calc(180px - 1.66667em);

        &:hover {
          margin-top: 0; } } }

    @include breakpoint-context($desktop) {
      .article_stub_link {
        height: 260px;
        width: 75%;
        margin-left: auto;
        margin-right: auto; }

      .article_stub_content {
        height: calc(260px - 80px);
        padding: 40px 60px;
        width: calc(100% - 120px); } } } }
