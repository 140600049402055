// media
//
// Markup: ./media.hbs
//
// Styleguide components.media

.media {
    $this: &;

    &--contained {
        background-color: $lily-white;
        border-radius: 14px;
        box-sizing: border-box;
        max-width: 275px;
        min-height: 190px;
        padding: 30px; }

    &--top-overlap {
        position: relative;

        #{$this}__img {
            @include position(absolute, null null 30px 30px); } } }
