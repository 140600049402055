//  Sub navigation
//****************************************************************************
#sub_nav_trigger_wrapper {
  background-color: $darkblue; }

.sub_nav_trigger {
  font-family: $heading-font-stack;
  @include adjust-font-size-to(24px);
  @include box-sizing('border-box');
  position: relative;
  padding: rhythm(.4) 80px rhythm(.4) 25px;
  display: block;
  color: #fff;
  background-color: $darkblue;
  span.icon_holder,
  span.icon {
    position: absolute; }
  span.icon_holder {
    top: 0;
    right: 0;
    width: 80px;
    height: 100%;
    background-color: $button-color; }
  span.icon {
    @include transition-duration(200ms);
    @include transition-property('transform');
    @include svg-background('../images/icons/sub_nav_trigger');
    background-position: center center;
    background-repeat: no-repeat;
    top: 20px;
    right: 24px;
    width: 24px;
    height: 24px; }
  &:hover {
    color: #fff; }
  &.active {
    background-color: $tertiary-color;
    color: $primary-color;
    span.icon {
      @include transform(rotate(180deg)); } } }

#sub_nav_wrapper {
  @include padding-leader(1);
  @include padding-trailer(1);
  display: none;
  background-color: $primary-color; }

#sub_nav {
  font-family: $heading-font-stack;
  display: block!important;
  li {
    @include trailer(.6); }

  a.menu_link {
    @include adjust-font-size-to(24px);
    @include border-radius(5px);
    @include padding-leader(.1);
    @include padding-trailer(.1);
    @include box-sizing('border-box');
    color: $white;
    background-color: $darkblue;
    margin-left: 25px;
    margin-right: 25px;
    display: block;
    padding-left: 25px;
    padding-right: 25px; }

  li.level_1 {
    a.menu_link {
      &.active {
        background-color: $yellow;
        color: $darkblue; } } }

  .dropdown {
    @include leader(.6);
    margin-left: 20px;
    margin-right: 20px; } }

// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  .sub_nav_trigger {
    @include transition-duration(200ms);
    @include transition-property('background-color');
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    padding-right: 45%;

    &:hover {
      background-color: $yellow;
      color: $primary-color; }

    span.icon_holder {
      width: 40%; }

    span.icon {
      left: 20px; } }

  #sub_nav {
    a.menu_link {
      width: 330px;
      margin-left: auto;
      margin-right: auto; } } }

@include breakpoint-context($desktop) {
  .sub_nav_trigger {
    display: none; }

  #sub_nav_wrapper {
    display: block!important;
    background: transparent; }

  #sub_nav {
    $gutter-styles: opposite;
    margin-left: - gutter-span()*2;

    a.menu_link {
      width: auto;
      color: $primary-color;
      padding: 0;
      padding-left: gutter-span()*2;
      padding-right: gutter-span()*2;
      background: transparent;
      display: block;
      &:hover {
        span {
          border-bottom: 1px solid $primary-color; } } }

    li.level_1 {
      a.menu_link {
        &.active {
          @include padding-leader(.2);
          @include padding-trailer(.2);
          @include border-radius(8px);
          display: block;
          background-color: $primary-color;
          color: $yellow; } } }

    .dropdown {
      @include leader(.5);
      margin-left: gutter-span()*4; } } }


// body color overrides for subnav
//********************************************************

@include breakpoint-context($desktop) {

  #outer_wrapper {
    .shop-online & {

      #sub_nav {
        a.menu_link {
          color: $white; } } } } }


