// ordered list
//
// Markup: ./ordered-list.hbs
//
// Styleguide elements.ordered list

.ordered-list {
    $this: &;
    counter-reset: ol-counter;
    list-style: none;

    .wysiwyg & {
        list-style: none;
        padding-left: 0; }

    li {
        counter-increment: ol-counter;
        font-size: rem(20);
        margin-bottom: 35px;

        &::before {
            color: $rose;
            content: counter(ol-counter) '. ';
            font-family: 'Hayseed W00 Regular', Georgia, Times, Times New Roman, serif;
            font-size: rem(36);
            font-weight: bold; } }


    @media(min-width: 768px) {
        columns: 2; } }
