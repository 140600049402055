// footer
//
// Markup: ./footer.hbs
//
// Styleguide layouts.footer

.footer {
    $this: &;
    background-color: $allports;
    color: $white;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding-bottom: 0;
    padding-top: 50px;
    position: relative;

    &::after {
        @include position(absolute, null null -50px null);
        background: $allports;
        border-radius: 0 0 80% 80%;
        box-shadow: inset 0 -40px 40px -50px rgba($black, .25);
        content: '';
        display: block;
        height: 50px;
        width: 100%; }

    &__content {
        display: flex; }

    &__copy {
        margin-bottom: 20px;

        p {
            font-size: rem(12);
            line-height: 2;
            margin-bottom: 0; } }

    &__row {
        &--order-1 {
            order: 1; }

        &--order-2 {
            order: 2; } }

    &__nh-logo {
        display: none; }

    &__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        max-width: 430px;
        text-align: center; }

    &__logo {
        align-self: flex-start;
        display: flex;
        flex-wrap: wrap;
        max-width: 140px; }

    &__social-nav {
        margin-bottom: 30px; }

    &__col {
        display: flex;
        flex-direction: column;

        #{$this}__nav {
            order: 1; }

        #{$this}__copy {
            order: 2; } }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px; }

    &__nav-item {
        width: 100%; }

    &__nav-link {
        color: $white;
        font-size: rem(16);
        line-height: 30px;
        margin-right: 10px;

        &:hover {
            color: $white;
            text-decoration: underline; } }

    // Fix for IOS showing registration number as a link
    a {
        color: inherit;
        text-decoration: none; }

    @media(min-width: $medium) {
        &__row {
            margin-bottom: 40px;

            &--order-1 {
                order: unset; }

            &--order-2 {
                order: unset; } }

        &__nh-logo {
            align-self: flex-start;
            display: block;
            margin-right: 20px;
            width: 109px; }

        &__social-nav {
            margin-bottom: 0; }

        &__nav-item {
            width: auto;

            &:last-child {
                #{$this}__nav-link {
                    border-right: 0; } } }

        &__nav-link {
            border-right: 1px solid $white;
            padding-right: 10px; }

        &__footer-logos {
            margin: 0 auto;
            max-width: 380px;
            width: 100%; }

        &__col {
            display: flex;
            flex-direction: column;

            #{$this}__copy {
                order: unset; }

            #{$this}__nav {
                order: unset; } }

        &__nav {
            margin-bottom: 0; } } }
