.event_registration {
  background: #e9eff2;
  border-top: 7px solid #c70077;
  padding-bottom: 100px;
  margin-top: 40px;

  header {
    text-align: center;
    margin-top: -32px;
    padding: 0px 20px 40px; }

  h1 {
    background: #c70077;
    color: #fff;
    font-size: 30px;
    text-align: center;
    border-radius: 100px;
    width: 385px;
    max-width: 100%;
    padding: 10px 0px;
    display: inline-block;
    margin-bottom: 40px; }

  h2 {
    text-align: center; }

  @media all and (max-width: 1023px) {
    padding-bottom: 50px; } }
