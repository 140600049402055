#content_main {
  @include padding-leader(2);

  &.with_sub_nav {
    @include padding-trailer(2);
    margin-left: gutter-span();
    margin-right: gutter-span(); } }

.single_column,
.full_width_column {
  .page_title,
  .ugc {
    margin-left: gutter-span();
    margin-right: gutter-span(); } }

// Responsive
//********************************************************
@include breakpoint-context( $mobile-landscape ) {
  #content_main {
    &.with_sub_nav {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .single_column,
  .full_width_column {
    .page_title,
    .ugc {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }


@include breakpoint-context($small-tablet) {
  #content_main {
    &.with_sub_nav {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .single_column,
  .full_width_column {
    .page_title,
    .ugc {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }


@include breakpoint-context($tablet) {
  #content_main {
    &.with_sub_nav {
      margin-left: column-span(.5,0,6);
      margin-right: column-span(.5,0,6); }

    &.single_column,
    &.full_width_column {
      @include padding-leader(5);
      @include padding-trailer(2);
      .page_title,
      .ugc {
        margin-left: auto;
        margin-right: auto;
        width: column-span(5,0,6); } } } }


@include breakpoint-context($desktop) {
  #content_main {
    &.with_sub_nav {
      @include padding-leader(0);
      margin-left: gutter-span()*3;
      margin-right: 0;
      width: column-span(6,0,9);
      display: inline-block;
      vertical-align: top; }

    &.single_column,
    &.full_width_column {
      @include padding-leader(0);
      .page_title,
      .ugc {
        width: column-span(6.5,0,9); } } } }
