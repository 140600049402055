
// Responsive
//********************************************************
@include breakpoint-context('all');

#content_sub {
  margin-top: 5em;
  @include breakpoint-context($desktop) {
    @include padding-leader(0);
    margin-top: 0;
    width: column-span(2,0,9);
    margin-left: gutter-span()/2;
    display: inline-block;
    vertical-align: top; } }
