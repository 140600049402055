// Media Cloud
//
// Markup: ./media-cloud.hbs
//
// Styleguide blocks.media-cloud

.media-cloud {
    $this: &;

    @media(min-width: $large) {
        &__group {
            min-height: 500px; }

        &__item {
            position: absolute;

            &--1 {
                bottom: 0;
                left: 0;
                z-index: 2; }

            &--2 {
                left: 70px;
                max-width: calc(100% - 70px);
                top: 0;
                z-index: 1; }

            &--3 {
                bottom: 0;
                right: 30px;
                z-index: 3; }

            &--4 {
                bottom: 25%;
                right: 0;
                z-index: 4; } } } }
