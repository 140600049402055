.step_nav {
  @include display(flex);
  @include justify-content(space-between);
  @include margin(null auto 30px);
  width: 75%;
  position: relative;
  z-index: 1;

  &:after {
    @include position(absolute, 50% null null 0px);
    @include size(100% 7px);
    @include transform(translateY(-50%));
    content: "";
    display: block;
    background: #00acf1;
    z-index: -1; }

  li {
    @include flex(none); }

  a {
    @include size(68px);
    @include display(flex);
    @include align-items(center);
    @include justify-content(center);
    border-radius: 50%;
    background: #00acf1;
    font-size: 36px;
    font-family: $heading-font-stack;
    color: #fff;
    box-shadow: 4px 7px 8px 0px rgba(1, 1, 1, 0.3);

    &.active {
      background: #233e91; } }

  @media all and (max-width: 767px) {
    width: calc(100% - 40px);

    a {
      @include size(40px);
      font-size: 22px; } } }
