.stepped_form {
  padding-bottom: 50px;

  label {
    color: #3b3b3b; }

  h2 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #c70077; }

  .wrap {
    padding: 0 20px;
    width: calc(75% + 40px);
    margin: 0 auto;
    box-sizing: border-box; }

  .customSelect {
    background: #fff;
    color: #3b3b3b;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2); }

  .customSelectInner {
    color: #3b3b3b; }

  .field {
    margin: 20px 20px 0px 0px;
    display: block;
    width: calc(100% - 20px);

    &.half {
      width: calc(50% - 20px);

      &:nth-of-type(2n + 1) {
        clear: left; } } }

  .basic_form_group {
    width: calc(100% + 20px);
    margin: -20px -20px 0px 0px;
    &:after {
      content: "";
      display: table-cell;
      clear: both; } }

  .group-clear {
    @include clearfix; }

  @media all and (max-width: 767px) {
    .wrap {
      width: 100%; }

    .field.half {
      width: calc(100% - 20px); } } }
