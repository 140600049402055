.c-shops {
    overflow: visible;
    max-width: 1228px;
    padding: 0 15px;

    &__intro {
        h2 {
            font-size: 36px;

            + * {
                margin-top: 24px; } }

        + * {
            margin-top: 40px; } }

    &__filter {
        input {
            font-size: 20px;
            padding: 0;

            &::placeholder {
                color: #000; } }

        #article_filter {
            gap: 16px;

            @media (max-width: 767px) {
                flex-direction: column; } }

        .input-container {
            display: flex;
            gap: 16px;
            padding: 5px 16px !important;

            &:hover,
            &:focus-within {
                border-color: #479DDC !important; } }

        .submit,
        .reset_filter {
            font-weight: 600;
            font-size: 18px;
            padding: 17px 24px !important;
            height: auto !important;
            margin: 0 !important;
            text-align: center;
            flex: 1 1 50%;
            font-family: $default-font-stack; } }

    &__location-btn {
        fill: #0171AB;

        &:hover {
            fill: #283583; }

        &.active {
            fill: #E6007E; } }

    &__actions {
        display: flex;
        gap: 16px; }

    &__map {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
        max-width: none !important;
        width: 100%;
        margin-top: 80px !important;
        height: auto !important;
        overflow: clip !important;

        @media (max-width: 767px) {
            margin-top: 44px !important;
            flex-direction: column;

            .google_map {
                display: none; } }

        .google_map {
            position: sticky !important;
            top: 0;
            max-height: 100vh;
            max-height: 100dvh; } }

    &__shops-list {
        width: 430px !important;
        flex: 0 0 430px !important;
        overflow: visible !important;

        @media (max-width: 961px) {
            width: 100% !important;
            flex: 1 1 auto !important;
            padding: 0 !important; } }

    &__results-title {
        padding: 22px 24px !important;
        font-size: 16px;
        position: sticky;
        top: 0;
        background-color: #F4F4EF;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            right: 24px;
            width: 24px;
            height: 24px;
            background-image: url('../images/icons/circle-anim-3.svg');
            background-size: 100%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 400ms ease-out; }

        &:has(.loading):before {
            opacity: 1; } }


    &__item {
        width: 100% !important;
        scroll-margin-top: 74px;
        border: 2px solid #fff;
        border-bottom: 2px solid #F4F4EF;

        @media (max-width: 767px) {
            margin: 0 !important; }

        &:hover {
            border: 2px solid #479DDC !important; }

        &.selected {
            border: 2px solid #E3F7FF !important; }

        .contact_number,
        .contact_directions {
            font-size: 18px;
            font-weight: 600;
            padding: 13px 24px !important;
            height: auto !important;
            cursor: pointer !important;

            > * {
                pointer-events: none; } }

        .contact_number {
            &:hover {
                opacity: 0.8; } }

        .contact_directions {
            &:hover {
                opacity: 0.8; } } }

    &__item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin-bottom: 0 !important;

            &:hover {
                text-decoration: none !important; } }

        + * {
            margin-top: 16px; } }

    [role=dialog] > *:first-child + div {
        margin-top: -32px; } }
