// featured events
//
// Markup: ./featured-events.hbs
//
// Styleguide blocks.featured events

.featured-events {
    $this: &;
    background-color: $lily-white;
    overflow: hidden;
    padding: 80px 0 130px;
    position: relative;

    &__bg {
        display: none; }

    &__copy {
        text-align: center; }

    &__button {
        @include position(absolute, null null 80px 50%);
        transform: translateX(-50%); }

    @media(min-width: 1024px) {
        &__bg {
            @include position(absolute, 50% 0 null null);
            display: block;
            height: 100%;
            max-width: 50%;
            object-fit: cover;
            transform: translateY(-50%);
            width: 696px; }

        &__copy {
            max-width: 430px;
            text-align: left; }

        &__content {
            max-width: calc(50% - 80px);
            position: relative; }

        &__button {
            @include position(absolute, 0 0 auto auto);
            transform: none; }

        &__card {
            width: 200%; } } }
