// social nav
//
// Markup: ./social-nav.hbs
//
// Styleguide components.social nav

.social-nav {
    $this: &;
    display: flex;
    justify-content: center;

    &__icon {
        fill: $white;
        height: 32px;
        width: 34px; }

    &__item {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 42px;
        justify-content: center;
        margin: 0 7.5px;
        width: 42px;

        &--instagram {
            background: linear-gradient(46.05deg, $lightning-yellow 0%, $red 49.52%, $hollywoodcerise 100%); }

        &--youtube {
            background-color: $white; }

        &--linkedin {
            background-color: $white;

            #{$this}__icon {
                fill: $deepcerulean; } }

        &--twitter {
            background-color: $pictonblue; }

        &--facebook {
            background-color: $chambray; } } }
