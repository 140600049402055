#basket_popup {
  @include box-shadow( 0px 6px 6px 0px rgba(0,0,0,0.3));
  @include box-sizing('border-box');
  width: 300px;
  background-color: $egg;
  top: 58px;
  left: 50%;
  margin-left: -102px;
  top: 50px;
  position: absolute;
  z-index: 9999;

  .quantity-error {
    line-height: 1.5;
    color: $red;
    padding: 10px 0;
    display: block;
    border: 1px solid $red;
    margin-top: 15px; }

  .order_summary {
    padding: 20px; }

  h2 {
    font-size: 20px !important;
    text-align: left; }

  .basket_popup_arrow {
    position: absolute;
    top: -11px;
    left: 135px;
    width: 20px;
    height: 11px;
    background: transparent url("../images/backgrounds/basket_arrow.png") bottom center no-repeat; }

  #close_basket_link {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 0;
    padding-top: 50px;
    text-indent: -9999px;
    background: $egg url("../images/icons/close.png") center center no-repeat; }

  .checkout_order {
    @include leader(1);
    color: $text-color;
    width: 100%;

    span.item_name {
      font-size: 17px !important; }
    span.quantitym,
    span.total {
      font-size: 16px !important; }
    tr.order_item {
      td {
        padding: 10px 0;
        border-top: 2px solid $mid-grey; } }

    tfoot {
      td {
        padding: 10px 0;
        border-top: 2px solid $mid-grey; } } }

  #basket_popup_link {
    @extend %button;
    font-size: 20px !important;
    @include leader(1); } }

.basket_link {
  box-shadow: 0 6px 6px 0 rgba(0,0,0,.3);
  font-size: .77778em;
  line-height: 2.14286em;
  color: #fff;
  padding: .5em 1em;
  display: inline-block;
  background-color: #c40076;
  text-align: center;
  @include breakpoint-context($desktop) {
    text-transform: uppercase;
    padding: 1em; } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($mobile-landscape) {

  .basket_link {
    display: inline-block; } }

@include breakpoint-context($desktop) {

  .basket_link {
    position: relative;
    margin-left: 20px;
    left: 0;
    &:hover {
        color: white; } }

  #basket_popup {
    top: 60px;
    left: auto;
    right: 260px;

    .basket_popup_arrow {
      left: auto;
      right: 55px; } } }
