// slider
//
// A variety of different options are available for sliders.
// These can be toggled in the HTML using data attributes: <br />
// `data-dots="true"` - Adds dots<br />
// `data-arrows="true"` - Adds arrows<br />
// `data-infinite="true"` - Adds infinite<br />
// `data-slides="3"` - How many slides to display<br />
// Any combination of the above can be used.
//
// Markup: ./slider.hbs
//
// Styleguide layouts.slider

.slider {
    $this: &;

    &:not(.slick-initialized) {
        display: flex;
        overflow: hidden;

        > * {
            flex: 0 0 100%;

            @media (min-width: 1024px) {
                aspect-ratio: 2 / 1; } } }

    .slick {
        &-slide {
            > div > div {
                display: block !important; } }

        &-arrow {
            background-color: $white;
            box-shadow: 0 0 6px rgba($black, .1); }

        &-dots {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;

            button {
                border: 0;
                cursor: pointer;
                font-size: 0;
                height: 4px;
                margin: 0 rem(5);
                transition: background .5s;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                &:hover {
                    background-color: $cerulean; } }

            .slick-active {
                button {
                    background-color: $cerulean; } } }

        &-slide {
            picture,
            img {
                display: block;
                width: 100%; } } }

    &__icon {
        @include position(relative, 2px null null null);
        fill: $cerulean;
        transform: rotate(90deg);

        &--reverse {
            transform: rotate(-90deg); } }

    .slick-prev,
    .slick-next {
        border: none;
        cursor: pointer;
        height: 44px;
        transform: translateY(-50%);
        width: 44px;
        z-index: 1; }

    .slick-prev {
        @include position(absolute, calc(50% - 15px) null null -20px); }

    .slick-next {
        @include position(absolute, calc(50% - 15px) -20px null null); }

    &--inside {
        .slick-prev {
            bottom: 4px;
            left: 0;
            top: auto;
            transform: none; }

        .slick-next {
            bottom: 4px;
            right: 0;
            top: auto;
            transform: none; }

        .slick-dots {
            @include position(absolute, null null 25px null);
            width: 100%; } }

    &--centered-arrows {
        .slick-prev,
        .slick-next {
            bottom: auto;
            top: calc(50% - 15px); } }

    &--fit {
        padding-bottom: 40px;

        .slick-dots {
            @include position(absolute, null null 20px null); }

        .slick-prev,
        .slick-next {
            @include position(absolute, auto null -42px null); }

        .slick-prev {
            left: -20px; }

        .slick-next {
            right: -20px; } }

    &--resources {
        .slick-track {
            display: flex;

            .article {
                height: auto; } }

        .slick-prev {
            bottom: -30px;
            left: 20px;
            top: auto;
            transform: none; }

        .slick-next {
            bottom: -30px;
            right: 20px;
            top: auto;
            transform: none; } }

    &--dots-round {
        .slick-dots {
            button {
                border: 1px solid $cerulean;
                border-radius: 50%;
                height: 10px;
                padding: 0;
                width: 10px; }

            .slick-active {
                button {
                    // Because Slick
                    background-color: $white !important; } } } }

    &--dots-inverse {
        .slick-dots {
            button {
                background: transparent;
                border: 1px solid $cerulean; } } }

    &--dots-white {
        .slick-dots {
            button {
                background: transparent;
                border: 1px solid $white;

                &:hover {
                    background: $white; } }

            .slick-active {
                button {
                    // Because Slick
                    background-color: $white !important; } } } }

    &--arrows-rounded {
        .slick-arrow {
            border-radius: 50%;
            height: 78px;
            opacity: .85;
            padding: 0;
            width: 78px;

            &.slick-prev {
                left: -39px;

                .slider__icon {
                    right: -15px; } }

            &.slick-next {
                right: -39px;

                .slider__icon {
                    left: -15px; } }

            &:hover {
                opacity: 1; }

            .slider__icon {
                width: 17px; } } }

    &--overflow-hidden {
        overflow: hidden; }

    @media(min-width: $large) {
        &--dots-inverse {
            .slick-dots {
                button {
                    border: 1px solid $white;

                    &:hover {
                        background-color: $white; } }

                .slick-active {
                    button {
                        // Because Slick
                        background-color: $white !important; } } } } } }
