// event summary
//
// Markup: ./event-summary.hbs
//
// Styleguide components.event summary

.event-summary {
    $this: &;
    color: $mine-shaft;
    font-size: rem(14);
    font-weight: 600;
    text-align: left;

    &__icon {
        fill: $cerulean;
        margin-right: 10px; }

    &--light {
        color: $white;

        #{$this}__icon {
            fill: $white; } } }
