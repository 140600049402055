// basket notification
//
// Markup: ./basket-notification.hbs
//
// Styleguide components.basket notification

.basket-notification {
    $this: &;
    position: relative;

    &__count {
        @include position(absolute, 0 0 null null);
        align-items: center;
        background: $rose;
        border-radius: 50%;
        box-sizing: border-box;
        color: $white;
        display: flex;
        font-size: rem(11);
        height: 25px;
        justify-content: center;
        padding: 5px;
        width: 25px; } }
