// Typography
//***************************************************************************
$base-font-size:     18px;
$base-line-height:   30px;

// Headings
$heading-font-sizes: 48px, 30px, 24px, 22px, 18px, 18px;


// Fonts
//***************************************************************************
$default-font-stack: 'Open Sans', Arial, sans-serif;
$mono-font-stack:  'Andale Mono', 'Lucida Console', monospace;
$heading-font-stack: 'Hayseed W00 Regular', Georgia,Times,Times New Roman,serif;
$skippy-font-stack: 'Skippy Sharp W00 Reg', Georgia,Times,Times New Roman,serif;


// Grid
//***************************************************************************
$grids:         2;
$gutters:       0.1;
$gutter-styles: split;

$mobile-landscape-breakpoint: 480px, 'no-query' true;
$small-tablet-breakpoint: 600px, 'no-query' true;
$tablet-breakpoint: 768px, 'no-query' true;
$desktop-breakpoint: 960px, 'no-query' true;

$mobile-landscape: $mobile-landscape-breakpoint, 3, 0.1;
$small-tablet: $small-tablet-breakpoint, 4, 0.1;
$tablet: $tablet-breakpoint, 6, 0.2;
$desktop: $desktop-breakpoint, 9, 0.33333;

$breakpoint-contexts: $mobile-landscape $small-tablet $tablet $desktop;

@each $context in $breakpoint-contexts {
  $grids:       add-grid(   nth($context,2) at nth(nth($context,1),1) );
  $gutters:     add-gutter( nth($context,3) at nth(nth($context,1),1) ); }


//  Base colours
//****************************************************************************
$body-color:         #fff;

$brightblue:         #00aeef;
$lightblue:          #0176aa;
$midblue:            #00649d;
$darkblue:           #25408f;

$lightpink:          #e8008a;
$pink:               #c40076;
$palepink:           #f3d6e8;
$darkpink:           #c50076;

$yellow:             #fff33e;
$darkyellow:         #ffac12;
$paleyellow:         #f0f0bc;

$red:                #d2232a;
$darkred:            #7e0000;

$purple:             #7c51a1;
$darkpurple:         #5e3784;

$green:              #7bc143;
$darkgreen:          #338122;

$egg:                #f4f4ee;
$white:              #ffffff;
$black:              #3b3b3b;

$primary-color:      $midblue;
$secondary-color:    $pink;
$tertiary-color:     $yellow;

$light-grey:         #f3f3f3;
$mid-grey:           #dedede;
$dark-grey:          #a0a8ae;
$charcoal:           #333;

$text-color:         $black;
$header-color:       $primary-color;

$link-color:         $primary-color;
$link-hover-color:   $darkblue;

// Special colours
$error-color:       #c40076;

// Special variables
$footer-height:     255px;
$thepush:           -255px;

//  Forms
//****************************************************************************
$button-color:       $brightblue;
$button-hover-color: $lightblue;
$input-box-radius:   2px;
