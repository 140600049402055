// panel
//
// .panel--blue - Blue panel
// .panel--large - Larger padding
//
// Markup: ./panel.hbs
//
// Styleguide blocks.panel

.panel {
    $this: &;
    background: $affair;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    &__top-left {
        @include position(absolute, -62px null null -62px);
        fill: $seance; }

    &__bottom-right {
        @include position(absolute, null -62px -62px null);
        fill: $seance; }

    &__content {
        padding: 20px;
        position: relative;
        z-index: 1; }

    &--brand {
        background: $lily-white;

        #{$this}__top-left,
        #{$this}__bottom-right {
            fill: $cerulean2;
            opacity: .2; } }

    &--large-asset {
        #{$this}__top-left {
            @include position(absolute, -102px null null -102px); }

        #{$this}__bottom-right {
            @include position(absolute, null -102px -102px null); } }

    &--form {
        max-width: 658px; }

    @media(min-width: $medium) {
        &__media {
            margin-bottom: -40px;
            margin-top: -40px; } }
    &--blue {
        background: $lily-white;

        #{$this}__bottom-right {
            fill: rgba($cerulean, .2); } }

    &--large {
        padding: 80px 20px; }

    .card-carousel & {
        // Because Slick
        width: calc(100% - 20px) !important; } }
