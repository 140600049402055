.c-events-quote {
    background-color: $lily-white;
    position: relative;
    overflow: hidden;

    &__decoration {
        svg {
            position: absolute;
            fill: #479DDC;
            opacity: 0.3;
            width: 360px;
            height: 360px;

            &:first-child {
                top: 0;
                left: 0;
                transform: translate(-50%, -50%); }

            &:last-child {
                bottom: 0;
                right: 0;
                transform: translate(50%, 50%); } } }

    &__container {
        max-width: 958px;
        margin: 0 auto;
        padding: 112px 16px;
        text-align: center;
        font-family: $base-font-family;

        p {
            color: #000;
            font-size: 30px;
            line-height: 1.2;
            margin-bottom: 32px; }

        cite {
            color: $cerulean;
            font-weight: bold;
            font-size: 19px; } } }
