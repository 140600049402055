// Colors
//
// Color pallete for use throughout the site
//
// Color names generated from: http://chir.ag/projects/name-that-color/
//
// $black - #000
// $white - #fff
// $cerulean - #00acf1
// $cerulean2 - #009fe3
// $dull-lime - #2cbe4e
// $fire-engine-red - #cb2431
// $mine-shaft - #3b3b3b
// $rose - #e6007e
// $lily-white - #e3f7ff
// $mint-tulip - #d1eaf6
// $apple - #41a335
// $wild-sand - #f5f5f5
// $christine - #ec6408
// $thunderbird - #cd1719
// $alto - #d8d8d8
// $affair - #814997
// $lightning-yellow - #ffcd21
// $allports - #0071ab
// $red - #f80000
// $hollywoodcerise - #bc00b2
// $chambray - #3b5998
// $pictonblue - #38a1f3
// $deepcerulean - #0077b5
// $gray - #898989
// $bay-of-many - #283583
// $lipstick - #c40076
// $seance - #762282
// $concrete - #f2f2f2
// $lipstick - #C30055
// $rio-grande - #CBD400
//
// Styleguide utilities.Colors

$black: #000;
$white: #fff;
$cerulean: #00acf1;
$cerulean2: #009fe3;
$dull-lime: #2cbe4e;
$fire-engine-red: #cb2431;
$mine-shaft: #3b3b3b;
$rose: #e6007e;
$lily-white: #e3f7ff;
$apple: #41a335;
$wild-sand: #f5f5f5;
$christine: #ec6408;
$thunderbird: #cd1719;
$alto: #d8d8d8;
$affair: #814997;
$lightning-yellow: #ffcd21;
$allports: #0071ab;
$red: #f80000;
$hollywoodcerise: #bc00b2;
$chambray: #3b5998;
$pictonblue: #38a1f3;
$deepcerulean: #0077b5;
$gray: #898989;
$mint-tulip: #d1eaf6;
$bay-of-many: #283583;
$lipstick: #c40076;
$seance: #762282;
$concrete: #f2f2f2;
$lipstick: #C30055;
$rio-grande: #cbd400;

// ------------------------------------------------ //
// Validation Shortcuts
// ------------------------------------------------ //
$error: $fire-engine-red;
$success: $dull-lime;

// ------------------------------------------------ //
// Breakpoints
// ------------------------------------------------ //
$breakpoints: (small: 568px, medium: 768px, mlarge: 1140px, xlarge: 1280px);

$small: 568px;
$medium: 768px;
$large: 1024px;
$xlarge: 1280px;

// ------------------------------------------------ //
// Container
// ------------------------------------------------ //
$containers: (small: 750px, medium: 1000px, large: 1140px, xlarge: 1250px);
$container-width: 980px;
$container-padding: 20px;

// ------------------------------------------------ //
// Grid
// ------------------------------------------------ //
$grid-gutter: 20px;

// ------------------------------------------------ //
// Base Font Styles
// ------------------------------------------------ //
$base-color: $black;
$base-font-family: 'Open Sans', Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: normal;
$base-letter-spacing: 0;

// ------------------------------------------------ //
// Heading Font Styles
// ------------------------------------------------ //
$heading-font-family: 'Hayseed W00 Regular', Georgia, Times, Times New Roman, serif;
$heading-font-weight: $base-font-weight;
$heading-letter-spacing: $base-letter-spacing;
$heading-line-height: 1.5;

// ------------------------------------------------ //
// Animation classes
// ------------------------------------------------ //
$animate-class: '.page:not(.page-edit-mode) .animate';
$page-edit: '.page:not(.page-edit-mode)';
