//  Main navigation
//****************************************************************************

.nav-wrap {
  background-color: $primary-color;
  display: none;
  width: 100%;
  @include padding-leader(3);
  @include padding-trailer(1);
  @include breakpoint-context($desktop) {
    background: $white;
    display: block !important;
    position: relative;
    padding: 0; }
  &__center {
    max-width: 960px;
    margin: 0 auto;
    text-align: right; }
  &__curve {
    @include svg-background('../images/backgrounds/header_curve', $background-size: 600px auto);
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 100%;
    height: 40px;
    left: 0;
    right: 0;
    pointer-events: none;
    display: none;
    z-index: 2;
    @include breakpoint-context($desktop) {
      display: block;
      height: 152px;
      .svg &,
      & {
        background-size: 2560px auto; } } } }

#main_nav {
  @include legacy-pie-clearfix;
  overflow: visible;
  z-index: 5;
  li.level_1 {
    @include trailer(.6); }

  .menu_link {
    font-family: $heading-font-stack;
    @include transition-duration(200ms);
    @include transition-property('background-color');
    @include adjust-font-size-to(24px);
    @include border-radius(5px);
    @include padding-leader(.1);
    @include padding-trailer(.1);
    display: block;
    color: $white;
    background-color: $darkblue;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    &:hover {
      background-color: $brightblue;
      color: $white; }

    &.active {
      background-color: $tertiary-color;
      color: $darkblue; } } }

.extend {
  @extend .center;
  position: relative; }

#header_items {
  margin-top: 65px; }

#sub_nav_wrapper {
  .level-1 {
    a {
      color: $white;
      padding-left: 30px; } } }

// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  #main_nav {
    .menu_link {
      width: 330px;
      margin-left: auto;
      margin-right: auto; } }

  #sub_nav_wrapper {
    .level-1 {
      margin-left: auto;
      margin-right: auto;
      width: 330px; } } }

@include breakpoint-context($desktop) {

  #main_nav {
    @include leader(0);
    width: 100%;
    // float: right
    li {
      display: inline-block; }

    .menu_link {
      @include transition-duration(200ms);
      @include transition-property('background-image');
      @include padding-leader(.1);
      @include padding-trailer(.1);
      @include border-radius(0);
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      color: $primary-color;
      width: auto;
      background: transparent;
      height: 44px;
      span {
        @include box-sizing('border-box');
        padding-top: 5px;
        padding-bottom: 5px; }
      &:hover {
        background: transparent;
        @include svg-background('../images/backgrounds/primary_nav_line', $background-size: 100% 5px);
        background-position: bottom left;
        background-repeat: no-repeat;
        color: $primary-color; }

      &.active {
        padding-left: 0;
        height: 54px;
        background: transparent url("../images/backgrounds/menu_right_bkg.png") top right no-repeat;

        &:hover {
          cursor: default;
          background: transparent url("../images/backgrounds/menu_right_sml_bkg.png") top right no-repeat;
          background-size: 15px 54px; }

        span {
          padding-left: 15px;
          display: inline-block;
          height: 54px;
          padding-top: 10px;
          padding-bottom: 10px;
          color: $white;
          background: transparent url("../images/backgrounds/menu_left_bkg.png") top left no-repeat; } } } }

  #sub_nav_wrapper {
    .level-1 {
      a {
        color: $primary-color;
        padding-left: 0; } } } }

@include breakpoint( 1200px ) {
  #main_nav {
    width: 100%;
    .menu_link {
      padding-left: 20px;
      padding-right: 20px;
      &.active {
        &:hover {
          background: transparent url("../images/backgrounds/menu_right_bkg.png") top right no-repeat;
          background-size: 20px 54px; }
        span {
          padding-left: 20px; } } } } }


