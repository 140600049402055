.user_form {
  label,
  legend {
    color: $text-color; }

  .field,
  .form_errors,
  legend,
  fieldset {
    margin-left: 0;
    margin-right: 0; }

  .form_errors {
    width: 100%; }

  fieldset {
    @include trailer(0.25);
    width: 100%;
    min-width: 0; }

  legend {
    @include padding-leader(0.25);
    @include padding-trailer(0); }

  .button {
    margin-left: 0;
    max-width: 100%; }

  .check_box_fields,
  .radio_button_fields {
    label,
    .field {
      @include trailer(0);
      @include padding-trailer(0); } } }
