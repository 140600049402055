@import "_bourbon/bourbon";
@import "breakpoint-sass";

// Setup
//****************************************************************************
@import "breakpoint-sass";
@import "node_modules/compass-sass-mixins/lib/_compass";
@import "singularitygs";

@import "_setup/config";
@import "_setup/variables";
@import "_setup/functions";

// Mixins
//****************************************************************************
@import "_mixins/grid";
@import "_mixins/images";

@include global-reset;

// Placeholder classes
//****************************************************************************
@import "_placeholders/buttons";
@import "_placeholders/gradients";
@import "_placeholders/grid";
@import "_placeholders/images";
@import "_placeholders/typography";

// @import '../../node_modules/bourbon/core/_bourbon.scss'
@import '../../node_modules/slick-carousel/slick/slick.scss';

@import "settings/settings.sass";
@import "utils/grid.sass";
@import "elements/button.sass";
@import "elements/checkbox.sass";
@import "elements/field-label.sass";
@import "elements/form.sass";
@import "elements/hr.sass";
@import "elements/input-password.sass";
@import "elements/input-toggle.sass";
@import "elements/input-upload.sass";
@import "elements/input.sass";
@import "elements/ordered-list.sass";
@import "elements/parsley.sass";
@import "elements/select.sass";
@import "elements/table.sass";
@import "elements/textarea.sass";
@import "elements/typography.sass";
@import "elements/unordered-list.sass";
@import "elements/wysiwyg.sass";
@import "layouts/container.sass";
@import "layouts/core.sass";
@import "layouts/footer.sass";
@import "layouts/grid.sass";
@import "layouts/listing.sass";
@import "layouts/modal.sass";
@import "layouts/section.sass";
@import "layouts/site-header.sass";
@import "layouts/slider.sass";
@import "components/accordion-content.sass";
@import "components/accordion.sass";
@import "components/arrow.sass";
@import "components/background-flower.sass";
@import "components/basket-notification.sass";
@import "components/card.sass";
@import "components/contact-details.sass";
@import "components/cqc-widget.sass";
@import "components/event-form.sass";
@import "components/event-summary.sass";
@import "components/events.sass";
@import "components/events/event-banner.sass";
@import "components/events/events-5050.sass";
@import "components/events/events-btn.sass";
@import "components/events/events-quote.sass";
@import "components/events/events-summary.sass";
@import "components/events/icon-text.sass";
@import "components/filter.sass";
@import "components/link-arrow.sass";
@import "components/loading-ring.sass";
@import "components/media.sass";
@import "components/messages.sass";
@import "components/nav-icon.sass";
@import "components/pagination.sass";
@import "components/participant-management.sass";
@import "components/people-content.sass";
@import "components/people-listing.sass";
@import "components/progress-bar.sass";
@import "components/quote.sass";
@import "components/radio-toggle.sass";
@import "components/scroller.sass";
@import "components/search-field.sass";
@import "components/shops-sticky-btn.sass";
@import "components/shops.sass";
@import "components/slider-card.sass";
@import "components/social-nav.sass";
@import "components/tag.sass";
@import "components/toggle.sass";
@import "components/video.sass";
@import "blocks/application-form.sass";
@import "blocks/banner.sass";
@import "blocks/button-grid.sass";
@import "blocks/card-grid.sass";
@import "blocks/carousel.sass";
@import "blocks/contact-form.sass";
@import "blocks/contact-info.sass";
@import "blocks/contact-list.sass";
@import "blocks/content-block.sass";
@import "blocks/cta.sass";
@import "blocks/donate.sass";
@import "blocks/donation-form.sass";
@import "blocks/donation-reasons.sass";
@import "blocks/family-stories.sass";
@import "blocks/featured-article.sass";
@import "blocks/featured-events.sass";
@import "blocks/fundraising-form.sass";
@import "blocks/image-background.sass";
@import "blocks/image-popout.sass";
@import "blocks/media-cloud.sass";
@import "blocks/media-quote.sass";
@import "blocks/media-video.sass";
@import "blocks/newsletter.sass";
@import "blocks/ordered-list-block.sass";
@import "blocks/panel.sass";
@import "blocks/primary-nav.sass";
@import "blocks/progress.sass";
@import "blocks/sticky-cta.sass";
@import "blocks/text-media-popout.sass";
@import "blocks/text-media.sass";
@import "blocks/title-content.sass";
@import "blocks/upcoming-event-subscription.sass";
@import "blocks/vertical-tabs.sass";
@import "helpers/utilities.sass";

@import "_structure/content_main.sass";
@import "_structure/content_sub.sass";
@import "_navigation/main_nav.sass";
@import "_navigation/sub_nav.sass";
@import "_forms/_article_tag_filter.sass";
@import "_forms/_default.sass";
@import "_forms/_enewsletter.sass";
@import "_forms/_password_reset.sass";
@import "_forms/_postcode_search.sass";
@import "_forms/_search_results.sass";
@import "_forms/_search.sass";
@import "_forms/_stepped_form.sass";
@import "_forms/_user_forms.sass";
@import "_forms/remove-participant.sass";

@import "_articles/lists/_event.sass";
@import "_articles/lists/_family.sass";
@import "_articles/lists/_search.sass";
@import "_articles/lists/_shop_location.sass";
@import "_articles/lists/_shop.sass";
@import "_articles/lists/_tiles.sass";
@import "_articles/lists/_volunteer.sass";
@import "_articles/details/_default.sass";
@import "_articles/details/_event.sass";
@import "_articles/details/_shop.sass";

@import "_pages/account_pages.sass";
@import "_pages/donate.sass";
@import "_pages/event_image_basket.sass";
@import "_pages/event_image.sass";
@import "_pages/home.sass";
@import "_pages/justgiving.sass";
@import "_pages/landing-page.sass";
@import "_pages/newsletter_page.sass";
@import "_pages/overview.sass";
@import "_pages/sitemap.sass";
@import "_pages/support_us.sass";

@import "_content_blocks/_contact_details.sass";
@import "_content_blocks/_donate.sass";
@import "_content_blocks/_donation-cards.sass";
@import "_content_blocks/_double_link_block.sass";
@import "_content_blocks/_fundraising.sass";
@import "_content_blocks/_gallery.sass";
@import "_content_blocks/_image-slider.sass";
@import "_content_blocks/_link_block.sass";
@import "_content_blocks/_main_image.sass";
@import "_content_blocks/_map.sass";
@import "_content_blocks/_social.sass";
@import "_content_blocks/_text-image.sass";
@import "_features/checkout_basket.sass";
@import "_features/checkout_review.sass";
@import "_features/event_basket_popup.sass";
@import "_features/event_gallery.sass";
@import "_features/event_header.sass";
@import "_features/event_registration.sass";
@import "_features/event_sessions.sass";
@import "_features/event_submissions.sass";
@import "_features/footer_social.sass";
@import "_features/form_add.sass";
@import "_features/header_basket.sass";
@import "_features/key_message.sass";
@import "_features/maps.sass";
@import "_features/order_history.sass";
@import "_features/pagination.sass";
@import "_features/quote_block.sass";
@import "_features/step_nav.sass";
@import "_features/tac.sass";
@import "_features/text_footer.sass";
@import "_features/tooltip.sass";
@import "_features/ugc.sass";

@import "_special/_person_header.sass";

.ui-widget-overlay {
    z-index: 1000 !important; }

.ui-dialog-buttons {
    z-index: 2000 !important; }

.ui-widget-overlay {
    z-index: 1000 !important; }

.ui-dialog-buttons {
    z-index: 2000 !important; }

.cke_dialog {
    z-index: 2300 !important; }

.ui-dialog {
    z-index: 3000 !important; }

