.event_image_basket {
  #collection_total_cost {
    @extend %bold-font;
    @include adjust-font-size-to(h(3));
    @include trailer(1, h(3)); }

  .checkout_cta {
    @extend %follow-on-button;
    display: inline-block;
    width: auto; } }
