#gallery_wrapper {
  @include padding-leader(2);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 3em;
  display: block;
  position: relative;
  overflow: hidden; }

#shadow {
  @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.2));
  width: 100%;
  display: block;
  border-bottom: 1px solid $white; }

#carousel_container {
  position: relative;
  width: 70%;
  margin: 0 auto;
  overflow: visible; }

.carousel {
  width: 100%;
  position: relative;
  white-space: nowrap;
  background-color: $black;

  .slide {
    @include transition-property(background);
    @include transition-duration(100ms);
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    vertical-align: top;

    .slide_inner {
      position: relative; }

    .overlay {
      @include transition-property(background);
      @include transition-duration(200ms);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparentize($black, 0.1); }

    &.active {
      .overlay {
        background-color: transparent; } } } }

.gallery_image {
  width: 100%;
  height: auto;
  display: block; }

.carousel_control_previous {
  @include svg-background('../images/icons/arrow_left_blue', $background-size: 30px auto);
  background-position: center center;
  background-repeat: no-repeat;
  left: -50px; }

.carousel_control_next {
  @include svg-background('../images/icons/arrow_right_blue', $background-size: 30px auto);
  background-position: center center;
  background-repeat: no-repeat;
  right: -50px; }

.carousel_control_previous,
.carousel_control_next {
  @include hide-text;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: 45%;
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat; }

#gallery_nav {
  @include leader(1.5);
  text-align: center; }

.gallery_toggle {
  @include border-radius(50%);
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  background-color: $dark-grey;
  &:hover,
  &.active_tab {
    background-color: $brightblue; }
  &.clone {
    display: none; } }


// Responsive
//=====================================
@include breakpoint-context( $small-tablet ) {
  .carousel_control_previous,
  .carousel_control_next {
    top: 40%; } }

@include breakpoint-context( $tablet ) {
  #carousel_container {
    width: 600px; }

  .carousel_control_previous,
  .carousel_control_next {
    top: 45%; } }

@include breakpoint-context( $tablet ) {
  #carousel_container {
    width: 735px; } }

