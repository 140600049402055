// table
//
// Markup: ./table.hbs
//
// background: #e3f7ff
//
// Styleguide elements.table

.table {
    $this: &;
    margin-bottom: 1em;
    width: 100%;

    td {
        padding: 0 15px 3px 0;

        &.no-wrap {
            white-space: nowrap; } }

    thead {
        color: rgba($mine-shaft, .6);
        font-size: 12px; }

    tfoot {
        border-top: 1px solid $alto;
        font-size: rem(20);

        td {
            font-weight: 600;
            padding-top: 10px; } } }
