// image background
//
// Markup: ./image-background.hbs
//
// Styleguide blocks.image background

.image-background {
    $this: &;
    height: 75vw;
    overflow: hidden;
    position: relative;
    margin-top: -50px;

    &:before,
    &:after {
        height: 25px; }

    &:before {
        @include position(absolute, 0 null null 50%);
        background: $white;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        content: '';
        display: block;
        max-width: calc(100% - 40px);
        transform: translateX(-50%);
        width: 1330px; }

    &:after {
        @include position(absolute, null null 0 50%);
        background: $white;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        content: '';
        display: block;
        max-width: calc(100% - 40px);
        transform: translateX(-50%);
        width: 1330px; }

    @media(min-width: $medium) {
        height: 45vw;

        &:before,
        &:after {
            height: 40px; } }

    @media(min-width: $large) {
        &:before,
        &:after {
            height: 65px; } }

    @media(min-width: $xlarge) {
        &:before,
        &:after {
            height: 85px; } }

    + .section {
        padding-top: 0; } }
