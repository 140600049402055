// slider-card
//
// Markup: ./slider-card.hbs
//
// Styleguide components.slider-card

.slider-card {
    position: relative;
    background: $affair;
    border-radius: 12px;
    height: calc(100% - 20px);
    max-width: 568px;
    padding: 150px 50px 50px;
    text-align: center;
    transition: box-shadow .3s;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    &__flower {
        position: absolute;
        width: 240px;
        height: 240px;
        fill: $seance; }

    &__flower:first-child {
        top: 0;
        left: 0;
        transform: translate(-45%, -45%); }

    &__flower:last-child {
        bottom: 0;
        right: 0;
        transform: translate(45%, 45%); }

    &__title {
        font-size: 2.625rem;
        color: $rio-grande;
        line-height: 1.333; }

    &__content {
        font-size: 1.5rem;
        line-height: 1.45;
        color: #fff;
        margin-top: 20px; }

    .slider {
        padding: 0; } }
