.event_sessions {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);

  &--unavailable {
    opacity: .3; }

  li {
    background: #1ab5f3;

    &:hover {
      cursor: pointer; }

    &.parsley-error {
      background: red; }

    &:nth-of-type(even) {
      background: #00adf2; } }

  input[type=radio] {
    display: none;

    &:checked + label {
      background: #233e91;

      .copy:before {
        background: #233e91;
        box-shadow: inset 0px 0px 0px 8px #fff; } } }

  label {
    margin-bottom: 0px;
    overflow: hidden;
    padding: 14px 20px;
    display: block;

    .copy {
      font-size: 20px;
      float: left;
      margin-bottom: 0;

      &:before {
        @include size(30px);
        content: "";
        border-radius: 50%;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 10px 0px 0px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3); } }

    .spaces {
      float: right; } }

  @media all and (max-width: 767px) {
    label {
      .spaces, .copy {
        float: none; }

      .copy:before {
        @include size(15px);
        margin-right: 5px; } }

    input[type=radio]:checked + label .copy:before {
      box-shadow: inset 0px 0px 0px 2px #fff; } } }
