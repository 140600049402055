#article_filter {
  display: flex;

  .input-container:hover {
    border-color: #479DDC; }

  .input-container:active {
    border-color: #479DDC; }

  .input-container {
    background: #F4F4EF;
    border-radius: 16px;
    border: 2px solid #F4F4EF;
    padding: 0 16px;

    #location_icon {
      vertical-align: text-top;
      border: none;
      background-color: transparent; }

    #search_icon {
      background: #F4F4EF;
      border-radius: 16px;
      vertical-align: middle;
      margin-top: 10px; }

    input {
      height: 50px; } }
  label {
    color: $text-color;
    .events & {
      color: $white; } }

  #article_postcode_field {
    // width: calc( 100% - 80px)
    border-radius: 16px;
    background: #F4F4EF;
    width: 370px;
    border: none;

    &:focus-visible {
      border: none;
      outline: none;

      .input-container {
        border: 2px solid #479DDC; } }

    &:placeholder {
      color: #3B3B3B;
      text-align: center;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; } }

  .submit {
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 16px;
    background: var(--Primary-Default, #00ACF1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
    // +svg-background('../images/icons/search')
    // flex: 1 1 10%
    // background-position: center center
    // background-repeat: no-repeat
    // width: 80px
    // text-indent: -999px
    // background-color: #00ACF1
    height: 56px;
    padding: 0 24px; }

  #article_postcode_field,
  .submit {
    display: inline-block;
    vertical-align: top; } }

.cat_filter {
  .customSelect {
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4)); } }

.reset_filter {
  // +box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4))
  // +leader(1)
  // +trailer(2)
  // flex: 1 1 10%
  background-color: #E3F7FF;
  border-radius: 16px;

  // margin-left: gutter-span()
  color: #0171AB;
  padding: 10px 24px;
  display: inline-block;
  &:hover {
    background-color: $darkblue;
    color: $white; } }


// Responsive
//********************************************************

@include breakpoint-context('all') {
  .reset_filter {
 } }    // margin-left: gutter-span()

@include breakpoint-context($small-tablet) {
  .events {
    #article_filter {
      padding-left: gutter-span()*3;
      padding-right: gutter-span()*3;
      fieldset {
        width: auto; } } }

  #article_filter {
    fieldset {
      margin: 0; }

    .postcode_field {
      width: 300px;
      .submit {
        margin-left: 0; } }

    .postcode_field,
    .reset_filter {
      display: inline-block;
      vertical-align: top; }

    .reset_filter {
 } } }      // margin-top: 50px


@include breakpoint-context($tablet) {

  .events {
    #article_filter {
      padding-left: gutter-span()*3;
      padding-right: gutter-span()*3;

      fieldset {
        width: auto; }

      .cat_filter,
      .postcode_field {
        margin-left: 0; }

      .reset_filter {
        margin-left: 0; } } } }

@include breakpoint-context($desktop) {

  .events {
    #article_filter {
      padding-left: gutter-span()/2;
      padding-right: gutter-span()/2;

      .cat_filter {
        width: 330px; } } }

  #article_filter {
    .postcode_field {
      margin-left: gutter-span()/2; } } }
