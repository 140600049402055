.event_detail_info_block {
  @include trailer(1);
  @include border-radius(3px);
  background-color: $paleyellow;
  padding: 20px gutter-span();
  dt {
    font-family: $heading-font-stack;
    @include adjust-font-size-to(24px);
    color: $secondary-color; }
  dd {
    @include trailer(.5); }
  a {
    text-decoration: underline; } }

.event_shop_form {
  @include trailer(2);
  text-align: center;
  button {
    max-width: 300px; }

  .variations, .quantity {
    display: none; } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {
  .event_detail_info_block {
    padding: 20px gutter-span(); } }

@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet);

@include breakpoint-context($tablet) {
  .event_detail_info_block {
    padding: 20px gutter-span()*2;
    margin-left: - gutter-span()*2;
    margin-right: - gutter-span()*2; } }

@include breakpoint-context($desktop) {
  .event_detail_info_block {
    padding: 20px gutter-span()*4;
    margin-left: - gutter-span()*4;
    margin-right: - gutter-span()*4; } }
