// donate
//
// Markup: ./donate.hbs
//
// Styleguide blocks.donate

.donate {
    $this: &;
    padding-bottom: 50px;
    padding-top: 50px;

    &__title {
        margin-bottom: 50px; }

    &__item {
        margin: auto;
        max-width: 240px; }

    &__reasons {
        margin-bottom: 30px; }

    &__icon {
        margin-bottom: 20px;
        width: 100%;

        &--green {
            fill: $apple; }

        &--blue {
            fill: $cerulean2; }

        &--purple {
            fill: $affair; } }

    &__copy {
        margin-bottom: 0; } }
