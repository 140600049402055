// tag
//
// .tag--news - News tag
// .tag--event - Event tag
// .tag--story - Story tag
// .tag--incomplete - Incomplete tag
//
// Markup: ./tag.hbs
//
// Styleguide components.tag

.tag {
    $this: &;
    border-radius: 14px;
    color: $white;
    display: inline-block;
    font-size: rem(13);
    font-weight: 600;
    padding: 0 20px;
    text-transform: uppercase;

    &--features {
        border: 1px solid #eee;
        background-color: #fff;
        color: #3b3b3b; }

    &--news {
        background-color: $rose; }

    &--naomis-blog {
        background-color: $christine; }

    &--charity-news {
        background-color: $apple; }

    &--fundraising-news {
        background-color: $rose; }

    &--event {
        background-color: $apple; }

    &--story {
        background-color: $cerulean; }

    &--incomplete {
        background-color: $gray; }

    &--small {
        font-size: rem(8);
        line-height: 1.5;
        padding: 3px 10px; } }
