.page-template-news-single {

  .article_detail_meta {
    font-family: $heading-font-stack;
    @include trailer(1);
    @include adjust-font-size-to(24px);
    dt,
    dd {
      color: $text-color; }
    dt {
      float: left;
      padding-right: 10px; }
    dd {} }

  time.article_stub_date {
    font-family: $heading-font-stack;
    @include trailer(1);
    @include adjust-font-size-to(24px);
    display: block; } }
