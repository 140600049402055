.fundraising_wrapper {
  @extend %gradient_red;
  font-family: $heading-font-stack;
  @include padding-leader(3);
  @include padding-trailer(3);
  color: $white;

  .text_column {
    @include trailer(1);
    margin-left: gutter-span();
    margin-right: gutter-span();
    h2 {
      @include trailer(.5);
      color: $white; }
    p {
      @include adjust-font-size-to(24px); } }

  .target_amount {
    @include adjust-font-size-to(70px);
    @include svg-background('../images/backgrounds/fundraising_bkg');
    padding-top: 90px;
    background-position: center center;
    background-repeat: no-repeat;
    color: $yellow;
    width: 285px;
    height: 185px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center; } }


// Responsive
//********************************************************

@include breakpoint-context('all') {
  .fundraising_wrapper {
    .text_column {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }

@include breakpoint-context($tablet);


@include breakpoint-context($desktop) {
  .fundraising_wrapper {
    .text_column,
    .target_amount {
      display: inline-block;
      vertical-align: top; }

    .target_amount {
      float: left;
      margin-left: gutter-span(); }
    .text_column {
      width: column-span(5,0,9);
      margin-left: gutter-span()*2; } } }

