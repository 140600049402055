// content block
//
// Flower graphic is optional
//
// Markup: ./content-block.hbs
//
// Styleguide blocks.content block

.content-block {
    $this: &;

    &__icon {
        @include position(absolute, -105px 0 null null);
        fill: $lightning-yellow;
        height: 210px;
        width: 210px; } }
