// people content
//
// Markup: ./people-content.hbs
//
// Styleguide components.people-content

.c-people-content {
  padding: 50px 0;

  @media(min-width: 1024px) {
    padding: 150px 0; }

  > .container {
    position: relative; }

  &__title {
    h2 {
      font-size: 40px;
      margin-bottom: 30px; } }

  &__text {
    p {
      font-size: 30px;
      line-height: 38px; }

    p + p {
      font-size: 22px;
      line-height: 36px; } }

  &__button {
    padding-top: 30px; } }
