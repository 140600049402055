// primary nav
//
// Markup: ./primary-nav.hbs
//
// Styleguide blocks.primary nav


.primary-nav {
    $this: &;
    &__50 {
        display: flex;
        justify-content: center; }
    &__contact {
        margin-bottom: 20px; }
    &__donate {
        margin-bottom: 20px; }
    &__list {
        overflow: hidden;
        padding-bottom: 50px;
        width: 100%; }

    &__item {
        display: block; }

    &__link {
        border-bottom: 1px solid $mint-tulip;
        color: $bay-of-many;
        display: block;
        font-size: rem(20);
        font-weight: 600;
        line-height: 27px;
        padding: 11px 60px;
        position: relative;
        margin-left: 25px;
        margin-right: 25px;
        &--active {
            color: $rose; } }

    &__arrow {
        @include position(absolute, 50% 60px null null);
        fill: $rose;
        padding: 20px 0;

        &--right {
            transform: translateY(-50%) rotate(-90deg); }

        &--left {
            @include position(absolute, 50% null null 30px);
            transform: translateY(-50%) rotate(90deg); } }

    &__subnav {
        @include position(absolute, 0 null null 0);
        background: $lily-white;
        box-sizing: border-box;
        height: calc(100vh - 95px);
        opacity: 0;
        overflow: scroll;
        padding: 50px 0;
        pointer-events: none;
        transition: transform .5s ease, opacity .75s ease;
        z-index: 500;

        &--active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);
            width: 100%; } }

    &__buttons {
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        margin-right: 60px; }

    &__links {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;

        #{$this}__link {
            border-bottom: 0;
            margin-bottom: 20px;
            font-size: rem(18);
            font-weight: 600;
            padding: 0; } }

    @media(min-width: $small) {
        &__buttons {
            flex-direction: row; }

        &__button {
            margin-bottom: 0;
            margin-left: 10px;

            &:first-child {
                margin-left: 0; } } }

    @media(min-width: $large) {
        &__buttons {
            flex-direction: column; }

        &__button {
            margin-bottom: 10px;
            margin-left: 0; } }

    @media(min-width: $xlarge) {
        &__list {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
            padding: 0;
            padding-left: 220px;
            width: 100%; }

        &__item {
            border-radius: 14px 14px 0 0;
            display: block;
            padding: 14px 5px 14px 5px;

            &--back {
                display: none; }

            &:last-child {
                margin-right: 0; }

            #{$this}__subnav {
                @include position(absolute, 55px null null auto);
                background: $lily-white;
                border-radius: 14px;
                border-top-left-radius: 0;
                height: auto;
                margin-left: -20px;
                overflow: visible;
                padding: 0;
                pointer-events: none;
                visibility: hidden;
                width: 150%;
                z-index: 500; }

            &:hover {
                #{$this}__link {
                    color: $rose; } }

            &--has-dropdown {
                position: relative;

                &:hover {
                    background: $lily-white;

                    > #{$this}__subnav {
                        opacity: 1;
                        pointer-events: all;
                        visibility: visible;

                        #{$this}__item {
                            &:hover {
                                background-color: $lipstick;
                                border-radius: 0;

                                > #{$this}__link {
                                    color: white; } } }

                        #{$this}__link {
                            color: $bay-of-many; }

                        // Level 3
                        #{$this}__arrow {
                            &--right {
                                transform: translateY(-50%) rotate(-90deg); } }

                        #{$this}__subnav {
                            border-radius: 0 14px 14px 0;
                            position: absolute;
                            left: calc(100% + 20px);
                            top: 0; } }



                    #{$this}__link {
                        color: $rose; } } } }

        &__link {
            border: 0;
            font-size: rem(18);
            padding: 0; }

        &__buttons,
        &__links {
            display: none; }

        &__arrow {
            &--right {
                right: -20px;
                transform: translateY(-50%) rotate(0); }

            &--left {
                display: none; } } } }
