.link_block_wrapper {
  position: relative;
  background-color: $dark-grey;
  width: 100%;
  height: 100vh; }

.link_block_image {
  @extend %image_cover;
  height: 100vh; }

.link_block_icon {
  position: absolute;
  top: -60px;
  left: -60px;
  width: 140px;
  height: 140px;
  z-index: 1;
  background-repeat: no-repeat; }

.link_block_title {
  @include leader(1.5);
  margin-left: gutter-span();
  margin-right: gutter-span();
  position: relative;
  z-index: 2; }

.link_block_reveal {
  height: 250px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3; }

.link_block_content {
  height: 250px;
  &.yellow {
    @extend %gradient_yellow;
    .link_block_icon {
      @include svg-background('../images/icons/yellow_icon2', $background-size: 140px auto); }
    .link_block_title {
      color: $purple; } }
  &.blue {
    @extend %gradient_blue;
    .link_block_icon {
      @include svg-background('../images/icons/blue_sun', $background-size: 140px auto); }
    .link_block_title {
      color: $text-color; } }
  &.pink {
    @extend %gradient_pink;
    .link_block_icon {
      @include svg-background('../images/icons/pink_icon1', $background-size: 140px auto);
      top: -30px;
      left: auto;
      right: 0; }
    .link_block_title {
      color: $white; }

    .link_block_detail {
      .button {
        background-color: $darkpink;
        .btn_icon {
          background-color: $lightpink;
          &:hover {
            background-color: $darkpink; } } } } }

  &.purple {
    @extend %gradient_purple;
    .link_block_icon {
      @include svg-background('../images/icons/purple_sun', $background-size: 140px auto); }
    .link_block_title {
      color: $white; } }
  &.green {
    @extend %gradient_green;
    .link_block_icon {
      @include svg-background('../images/icons/green_sun', $background-size: 140px auto); }
    .link_block_title {
      color: $white; } }

  .center {
    position: relative; } }


.link_block_detail {
  height: 215px;
  .button {
    @extend %button;
    @include adjust-font-size-to(14px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; } }

// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($mobile-landscape) {

  .link_block_title {
    @include leader(1); }
  .link_block_reveal,
  .link_block_content {
    height: 150px; }

  .link_block_detail {
    height: 120px; } }


@include breakpoint-context($small-tablet) {

  .link_block_title {
    @include leader(1); }
  .link_block_reveal,
  .link_block_content {
    height: 250px; }

  .link_block_detail {
    height: 220px; } }

@include breakpoint-context($tablet) {

  .link_block_content {
    height: auto;
    .button {
      @include leader(1);
      position: relative;
      width: column-span(2.5,0,6);
      margin-left: auto;
      margin-right: auto;
      left: auto;
      right: auto;
      display: block; } } }

@media (min-height: 800px) and (min-width: 961px) {

  .link_block_image {
    height: 100vh; }

  .link_block_detail,
  .link_block_reveal {
    height: 160px; }

  .link_block_reveal {
    position: fixed; }

  .link_block_content {
    position: relative;
    p.link_block_title, {
      @include leader(1); }
    .button {
      @include leader(2);
      position: absolute;
      top: 0;
      right: 0; }


    p.link_block_title {
      @include adjust-font-size-to(22px);
      width: column-span(4,0,9);
      display: inline-block;
      vertical-align: top;
      margin-left: gutter-span()*3; }

    .button {
      @include adjust-font-size-to(20px);
      display: inline-block;
      vertical-align: top; } }

  .link_block_icon {
    width: 220px;
    height: 220px;
    top: -70px;
    left: -160px;
    .yellow &,
    .blue &,
    .purple &,
    .green &, {
      background-size: 220px 220px!important; } } }
