#outer_wrapper {
  .accounts_pages & {
    background-color: $purple;
    h1,
    .ugc {
      color: $white; } } }

#content_main {
  .accounts_pages & {
    @include padding-trailer(0); } }


#login_form {
  .forgotten_password {
    margin-left: gutter-span();
    margin-right: gutter-span(); }
  .button {
    @extend %input_btn_arrow; } }


#account_form {
  fieldset {
    @include trailer(1.5); }

  .button {
    @extend %input_btn_arrow; } }


// Responsive
//****************************************************************************
@include breakpoint-context('all') {

  #login_form {
    .forgotten_password {
      margin-left: gutter-span();
      margin-right: gutter-span(); } } }


@include breakpoint-context($tablet);


@include breakpoint-context($desktop);
