#event_image_image_column,
#event_image_description {
  @include trailer(1);
  margin-left: gutter-span();
  margin-right: gutter-span(); }

#event_image_frame {
  background-color: $mid-grey; }

#event_gallery_image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto; }

#event_image_navigation {
  @include padding-leader(0.5);
  @include padding-trailer(0.5);
  overflow: hidden;
  text-align: center;
  a {
    text-decoration: underline;
    color: $text-color;
    &:hover,
    &:focus {
      color: $link-color; } }

  .prev_image {
    @include float-left; }
  .next_image {
    @include float-right; } }


#event_image_description {
  p {
    @include trailer(1); }
  .button {
    @include box-sizing(border-box);
    background-position: right 10px center;
    padding-right: 40px;
    padding-left: 10px; } }

// Responsive
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@include breakpoint-context($mobile-landscape) {
  #event_image_image_column,
  #event_image_description {
    margin-left: gutter-span();
    margin-right: gutter-span(); } }

@include breakpoint-context($small-tablet) {
  #event_image_image_column,
  #event_image_description {
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  #event_image_image_column,
  #event_image_description {
    @include float-left;
    width: column-span(2,1) - gutter-span(); } }


@include breakpoint-context($tablet) {
  #event_image_image_column,
  #event_image_description {
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  #event_image_image_column {
    width: column-span(4,1) - gutter-span(); }
  #event_image_description {
    width: column-span(2,1) - gutter-span();
    .button {
      width: 100%;
      margin-left: 0; } } }


@include breakpoint-context($desktop) {
  #event_image_image_column {
    margin-left: grid-span(1, 1) + gutter-span()/2;
    margin-right: gutter-span()/2;
    width: column-span(5,2); }

  #event_image_description {
    margin-left: gutter-span()/2;
    margin-right: gutter-span()/2;
    width: column-span(3,7); } }
