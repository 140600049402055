// Container
//
// Markup: ./container.hbs
//
// .container--xlarge - width variation
// .container--large - width variation
// .container--medium - width variation
// .container--small - width variation
//
// Styleguide layouts.Container

.container {
    $this: &;
    @include margin(null auto);
    @include padding(null 20px);
    box-sizing: border-box;
    max-width: 1180px;
    width: 100%;

    @each $name, $width in $containers {
        &--#{$name} {
            max-width: $width + $container-padding*2; } }

    &--form {
        max-width: 370px;

        @media(min-width: 1024px) {
            max-width: 430px; } }

    &--styleguide {
        background-color: #ededed;
        border-radius: 6px;
        min-height: 50px; } }
