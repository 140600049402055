// banner
//
// A generic banner, can be used on any page.
//
// Markup: ./banner.hbs
//
// Styleguide blocks.banner

// banner - story
//
// Banner to be used on the story pages with specific brand themes.
//
// Markup: ./banner-story.hbs
//
// Styleguide blocks.banner story

// banner - event detail
//
// Banner for the event detail page.
//
// Markup: ./banner-event-detail.hbs
//
// Styleguide blocks.banner event detail

// banner - event registration
//
// Banner for the participant management section of the events area.
//
// Markup: ./banner-event-registration.hbs
//
// Styleguide blocks.banner event registration

.banner {
    $this: &;
    padding: 100px 0;
    position: relative;

    &__picture {
        display: block; }

    &__image {
        display: block;
        height: 100%;
        left: 0;
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%; }

    &__container {
        align-items: left;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1160px;
        padding: 20px;
        position: relative;
        width: 100%; }

    @media (min-width: 1024px) {
        .slick-slider {
            picture,
            img {
                aspect-ratio: 2 / 1; } } }

    &__slider {
        padding-bottom: 60px;
        overflow: hidden; }

    &__content {
        max-width: 465px; }

    &__heading {
        color: $white;
        line-height: 1;
        margin-bottom: 20px;

        &--has-icon {
            padding-left: 85px;
            position: relative; } }

    &__title {
        color: $rose; }

    &__copy {
        color: $white;
        font-size: rem(20);
        font-weight: 600;
        line-height: 1.6;
        margin-bottom: 0; }

    &__icon {
        @include position(absolute, -10% null null 0); }

    &__info {
        @include position(absolute, null null 0 null);
        background-color: $lipstick;
        border-top-left-radius: 100% 50%;
        border-top-right-radius: 100% 50%;
        box-sizing: border-box;
        margin-left: -5%;
        margin-right: -5%;
        opacity: .85;
        padding: 58px 5%;
        width: 110%; }

    &__event-summary {
        display: flex;
        font-size: rem(18);
        font-weight: 600;
        justify-content: center;
        margin-bottom: 10px;

        .event-summary__icon {
            height: 25px;
            width: 25px; } }

    &--graphic {
        padding: 0;

        #{$this}__content {
            margin-top: 20px;
            z-index: 2; } }

    &--align-left {
        #{$this}__container {
            align-items: left; } }

    &--full {
        #{$this}__content {
            max-width: none;
            text-align: center; } }

    &--has-border {
        border-bottom: 6px solid $cerulean2; }

    &--has-info-panel {
        box-sizing: border-box;
        min-height: 620px;
        overflow: hidden; }

    &--has-overlay {
        &:before {
            @include position(absolute, 0 0 0 0);
            background-color: rgba($mine-shaft, .75);
            content: '';
            z-index: 1; }

        #{$this}__content {
            z-index: 2; } }

    &--has-overlay-half {
        &:before {
            @include position(absolute, 0 0 0 0);
            background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 75%);
            content: '';
            z-index: 1; }

        #{$this}__content {
            z-index: 2; } }

    &__event-title {
        text-align: center;

        h1 {
            color: #fff; } }

    @media(min-width: 1024px) {
        &--center-align {
            .banner__picture + .container {
                position: static !important; }

            .banner__content {
                bottom: auto !important;
                margin-left: 10%;
                top: 50%;
                transform: translateY(-50%); } } }

    @media(min-width: 1023px) {
        &--graphic {
            #{$this}__picture {
                &:before {
                    @include position(absolute, 0 0 0 0);
                    background: linear-gradient(60deg, rgba(0, 0, 0, .3) 0%, rgba(255, 255, 255, 0) 100%);
                    content: '';
                    opacity: 60%;
                    pointer-events: none;
                    z-index: 1; } } }

        &__content {
            text-shadow: 1px 1px rgba(0, 0, 0, 0.4); } }

    @media(min-width: 1024px) {
        padding: 200px 0;

        &__summary {
            display: flex;
            justify-content: center; }

        &__event-summary {
            margin-right: 45px;

            &:last-child {
                margin-right: 0; } }

        &__content {
            &--half {
                max-width: 50%; } }

        &__slider {
            padding-bottom: 0; }

        &__title {
            color: $white; }

        &--graphic {
            padding: 0;

            #{$this}__content {
                bottom: 100px;
                color: $white;
                left: 20px;
                margin-top: 0;
                position: absolute; } } } }
