// card default
//
// This card contains the most information seen on any type of card.
//
// Markup: ./card.hbs
//
// background: #e3f7ff
//
// Styleguide components.card.default

// card tile
//
// Markup: ./card-tile.hbs
//
// background: #e3f7ff
//
// Styleguide components.card.tile

// card horizontal
//
// Markup: ./card-horizontal.hbs
//
// Styleguide components.card.horizontal

// card job
//
// Markup: ./card-job.hbs
//
// Styleguide components.card.job

// card featured
//
// Markup: ./card-featured.hbs
//
// Styleguide components.card.featured

// card family stories
//
// Markup: ./card-family-stories.hbs
//
// Styleguide components.card.family stories

.card {
    $this: &;
    background: $white;
    border-radius: 14px;
    box-shadow: 0 2px 8px 0 rgba($black, .13);
    display: block;
    height: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 425px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    width: 100%;

    &:hover {
        box-shadow: 0 2px 8px 0 rgba($black, .23); }

    &__article {
        height: 100%;
        display: flex;
        flex-direction: column; }

    &__media-container {
        background: $wild-sand;
        flex-shrink: 0;
        height: 210px;
        overflow: hidden;
        position: relative; }

    &__media {
        @include position(absolute, 50% null null 50%);
        display: block;
        height: 100%;
        object-fit: cover;
        transform: translateY(-50%) translateX(-50%);
        width: 100%; }

    &__tags {
        margin-bottom: 15px;
        margin-top: -40px;
        z-index: 10; }

    &__tag {
        align-self: flex-start; }

    &__content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px; }

    &__heading {
        color: $cerulean;
        font-size: rem(36);
        margin-bottom: 15px; }

    &__copy {
        color: $mine-shaft;
        font-size: rem(18);
        margin-bottom: 15px;
        word-wrap: break-word; }

    &__meta {
        margin-top: auto; }

    &__date {
        color: $mine-shaft;
        font-size: rem(14); }

    &--tile {
        #{$this}__article {
            position: relative; }

        #{$this}__heading {
            color: $white;
            font-size: rem(36); }

        #{$this}__content {
            @include position(absolute, 0 null null 0);
            background-color: rgba($mine-shaft, .75);
            height: 100%;
            justify-content: center;
            width: 100%; }

        #{$this}__copy {
            color: $white;
            font-size: rem(12);
            margin-bottom: 0; } }

    &--horizontal {
        max-width: 950px;

        #{$this}__article {
            display: flex; }

        #{$this}__media-container {
            height: auto;
            width: 407px; }

        @media(max-width: 1024px) {
            #{$this}__article {
                flex-direction: column; }

            #{$this}__media-container {
                height: 210px;
                width: auto; } } }

    &--featured {
        max-width: 1139px;

        #{$this}__article {
            display: flex; }

        #{$this}__media-container {
            height: auto;
            width: 754px; }

        #{$this}__content {
            padding: 40px; }

        @media(max-width: 1024px) {
            #{$this}__article {
                flex-direction: column; }

            #{$this}__media-container {
                height: 210px;
                width: auto; } }

        @media(min-width: 1024px) {
            #{$this}__content {
                width: 385px; } } }


    $featured-gutter: 12;

    &--event-featured {
        #{$this}__article {
            background-color: $pink; }

        #{$this}__media-container {
            border-radius: 10px 10px 0 0;
            margin: #{$featured-gutter}px #{$featured-gutter}px 0;
            height: #{210 - $featured-gutter}px; }

        #{$this}__content {
            background-color: #fff;
            border-radius: 0 0 10px 10px;
            margin: 0 #{$featured-gutter}px #{$featured-gutter}px; } }


    &--page-featured {
        @media (min-width: $medium) {
            max-width: none;

            #{$this}__article {
                flex-direction: row; }

            #{$this}__media-container, #{$this}__content {
                flex: 1; }

            #{$this}__media-container {
                height: auto;
                order: 2; } } }

    .card-carousel & {
        // Because Slick
        width: calc(100% - 20px) !important; } }

.card-carousel {
    width: 100%;

    .slick-list {
        overflow: visible; }

    .slick-arrow {
        background-color: $white;
        box-shadow: 0 0 2px rgba($black, .1);

        @media (max-width: $large) {
            // Because Slick
            display: none !important; } }

    .slick-prev,
    .slick-next {
        position: absolute;
        bottom: auto;
        left: auto;
        top: -135px;
        transform: none;
        z-index: 10;
        text-indent: -99999px;

        border: none;
        cursor: pointer;
        height: 44px;
        width: 44px; }

    .slick-prev {
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
        right: 45px; }

    .slick-next {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
        right: 0; }

    &__icon {
        fill: $rose;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(50%) rotate(90deg);

        &--reverse {
            transform: translateY(-50%) translateX(50%) rotate(-90deg); } }

    &--match-height {
        .slick-track {
            display: flex !important; }

        .slick-slide {
            height: inherit !important;
            padding: 10px;
            > div {
                height: 100%; } } } }
