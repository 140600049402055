.contact-us {
  #content {
    color: $white;
    h2 {
      @include leader(.5);
      @include trailer(.2);
      color: $white; }
    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }

    .contact_blocks_item {
      margin-left: gutter-span();
      margin-right: gutter-span(); }

    .sun_icon {
      @include svg-background('../images/icons/yellow_sun', $background-size: 260px auto);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      bottom: -280px;
      left: 0;
      width: 260px;
      height: 260px; }

    .center {
      position: relative; } } }


.contact-us {
  #content {
    .fundraise & {
      @include padding-trailer(6);
      background-color: $purple;
      overflow: hidden;

      .title {
        @include adjust-font-size-to(36px);
        @include trailer(1);
        margin-left: gutter-span();
        margin-right: gutter-span(); } } } }

// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {
  .contact-us {
    #content {
      .contact_blocks_item {
        margin-left: gutter-span();
        margin-right: gutter-span(); } } }

  .contact-us {
    #content {
      .fundraise & {
        .title {
          margin-left: gutter-span();
          margin-right: gutter-span(); } } } } }


@include breakpoint-context($small-tablet);

@include breakpoint-context($tablet) {
  .contact-us {
    #content {
      margin-left: column-span(.5,0) + gutter-span();
      margin-right: column-span(.5,0) + gutter-span();
      .contact_blocks_item {
        width: calc(50% - 30px);
        vertical-align: top;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px; } } }


  .contact-us {
    #content {
      .fundraise & {
        margin-left: 0;
        margin-right: 0;

        .title {
          margin-left: auto;
          margin-right: auto;
          text-align: center; }

        .contact_blocks_item {
          @include trailer(1);
          width: column-span(8,0,9);
          display: block;
          margin-left: auto;
          margin-right: auto; }

        .split_block_left,
        .split_block_right {
          display: inline-block;
          vertical-align: top;
          width: 50%; } } } } }


@include breakpoint-context($desktop) {

  .contact-us {
    #content {
      width: column-span(6.5,0,9);
      margin-left: auto;
      margin-right: auto;
      .contact_blocks_item {
        margin-left: 0;
        margin-right: gutter-span(); } } }


  .contact-us {
    #content {
      .fundraise & {
        @include padding-trailer(2);
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .sun_icon {
          bottom: -150px;
          left: -100px; }

        .contact_blocks_item {
          width: column-span(6,0,9); } } } } }
