html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

body {
    background-color: $concrete;
    color: $text-color;
    height: 100%;
    overflow-x: hidden!important; }

img {
    display: inline-block;
    height: auto;
    max-width: 100%; }

.wrapper {
    background-color: $white;
    margin: auto;
    width: 100%; }

img {
    display: block;
    height: auto;
    max-width: 100%; }

@media (min-width: $large) {
    #content:not(.form-control) {
        padding-top: 5.625em;

        &.padding-bottom {
            padding-bottom: 100px; } } }
