.c-event-banner {
    position: relative;

    &__image {
        position: absolute;
        inset: 0;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: #000;
            opacity: 0.6; }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center; } }

    &__content {
        position: relative;
        z-index: 1;
        color: #fff;
        max-width: 1180px;
        margin: 0 auto;
        padding: 207px 20px 142px;

        h1 {
            color: inherit;

            + * {
                margin-top: 22px; } } } }
