// Maps
//**********************************************************************
#google_map_wrapper {
  @extend .center;
  @include clearfix;
  @include leader(1);
  @include trailer(1);
  background-color: $light-grey;
  display: flex;
  height: 720px;
  .google_map {}

  #shop_location_listing {
    width: 450px;
    overflow-y: auto;
    .location_results {} } }


#google_map {
  width: 100%;
  height: rhythm(10);
  clear: both;
  a.contact_number:after {
    content: " • "; } }

.gm-style.gm-style-iw,
.gm-style .gm-style-iw a,
.gm-style .gm-style-iw span,
.gm-style .gm-style-iw label,
.gm-style .gm-style-iw div {
  font-size: 1em;
  font-weight: inherit;
  a.contact_number:after {
    content: " • "; } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($mobile-landscape);

@include breakpoint-context($small-tablet);

@include breakpoint-context($tablet) {
  #google_map {
    height: rhythm(20); } }

@include breakpoint-context($desktop) {
  #google_map {
    height: rhythm(24); } }
