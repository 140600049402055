// video
//
// Markup: ./video.hbs
//
// Styleguide components.video

.video {
    $this: &;
    background-color: $black;
    border-radius: 12px;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        display: block;
        // 16:9
        padding-top: 56.25%; }

    &__poster {
        @include position(absolute, 0 0 0 0);
        cursor: pointer;
        overflow: hidden;
        transition: opacity .3s, visibility 0 .3s;
        z-index: 10;

        &:before {
            @include position(absolute, 50% null null 50%);
            background-color: rgba($cerulean, .9);
            border-radius: 50%;
            content: '';
            height: 75px;
            max-height: 75vw;
            max-width: 75vh;
            pointer-events: none;
            transform: translate(-50%, -50%);
            width: 75px; }

        &:after {
            @include position(absolute, 50% null null 50%);
            border-color: transparent transparent transparent $white;
            border-radius: 3px;
            border-style: solid;
            border-width: 15px 0 15px 21px;
            content: '';
            height: 0;
            margin-left: 2px;
            pointer-events: none;
            transform: translate(-50%, -50%);
            width: 0; }

        &--hide {
            opacity: 0;
            visibility: hidden; } }

    &__picture {
        &:after {
            @include position(absolute, 0 0 0 0);
            background: linear-gradient(0deg, rgba($black, .75) 0%, rgba($white, 0) 50%);
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            z-index: 10; } }

    &__image {
        display: block;
        width: 100%; }

    &__info {
        @include position(absolute, null null 15px 20px);
        color: $white;
        z-index: 15; }

    &__title {
        display: block;
        font-weight: 600; }

    &__iframe {
        @include position(absolute, 0 null null 0);
        border: 0;
        height: 100%;
        width: 100%; }

    @media(max-width: $medium) {
        &__info {
            font-size: rem(12);
            line-height: 1.5; } } }
