#footer_social_nav {
  @include leader(1.5);
  margin-left: gutter-span();
  margin-right: gutter-span();
  li {
    display: inline-block;
    margin-left: -5px; } }

.footer_social_item {
  @include transition-property('background-size');
  @include transition-duration(200ms);
  @include svg-background('../images/backgrounds/bkg_white_shape', $background-size: 44px auto);
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 59px;
  height: 56px;
  &:hover {
    @include svg-background('../images/backgrounds/bkg_white_shape', $background-size: 59px auto); }

  span {
    @include svg-background('../images/icons/footer_social');
    width: 29px;
    padding-top: 29px;
    height: 0;
    display: inline-block;
    overflow: hidden;
    margin: 14px 0 0 16px; }

  &.youtube {
    span {
      background-position: 0 0; } }
  &.instagram {
    span {
      background-position: -29px 0; } }
  &.linkedin {
    span {
      background-position: -58px 0; } }
  &.facebook {
    span {
      background-position: -87px 0; } }
  &.twitter {
    span {
      background-position: -116px 0; } } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' ) {
  #footer_social_nav {
    margin-left: gutter-span();
    margin-right: gutter-span(); } }

@include breakpoint-context ( $tablet ) {
  #footer_social_nav {
    @include trailer(1);
    @include leader(1.5);
    float: right;
    margin-left: column-span(.5,0,6);
    margin-right: column-span(.5,0,6); } }

@include breakpoint-context( $desktop ) {
  #footer_social_nav {
    margin-left: gutter-span()/2;
    margin-right: gutter-span()/2;
    li {
      margin-left: -5px; } } }

