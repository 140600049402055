// quote
//
// Markup: ./quote.hbs
//
// Styleguide components.quote

.quote {
    $this: &;
    color: $mine-shaft;
    font-family: $base-font-family;
    font-size: rem(18);
    margin: 20px auto;
    max-width: 960px;
    padding: 0 75px;
    position: relative;

    &__footer {
        color: $cerulean2;
        font-size: rem(18);
        font-style: normal; }

    &__icon {
        &--open {
            @include position(absolute, 0 null null 0); }

        &--close {
            @include position(absolute, null 0 0 null);
            transform: rotate(180deg); } } }
