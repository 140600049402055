.article_listing {
  @include padding-trailer(2);
  overflow: hidden;

  .article_stub_item {
    width: 100% - gutter-span()*2;
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  .article_stub_link {
    @include trailer(.5);
    display: block;
    padding-top: 5px; }

  .article_stub_content {
    @include transition-property('margin, padding');
    @include transition-duration(200ms);
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
    background-color: $egg;
    padding: 20px;
    overflow: hidden;

    &:hover {
      background-color: $white;
      margin-top: -5px; } }

  h2.article_stub_title {
    @include trailer(.2);
    display: inline-block;
    color: $primary-color;
    width: 100%;
    span {
      @include svg-background('../images/icons/arrow_right_blue', $background-size: 18px auto);
      background-position: right center;
      background-repeat: no-repeat;
      padding-right: 30px; }
    &:hover {
      text-decoration: underline; } }

  .article_stub_meta {
    @include trailer(1);
    @include adjust-font-size-to(16px);
    dt,
    dd {
      color: $text-color; }
    dt {
      float: left;
      padding-right: 10px; }
    dd {} }

  .article_venue,
  .article_stub_date,
  .article_stub_abstract {
    color: $text-color; }

  .article_stub_image_wrapper {
    margin-bottom: 1.5em;
    height: 166px;
    overflow: hidden;

    img.article_stub_image {
      width: 100%;
      height: auto;
      display: block; }

    .events & {
      height: 123px; } }

  .article_venue,
  .article_stub_date {
    @include adjust-font-size-to(16px);
    display: inline-block;
    line-height: 1.4em; }

  .article_stub_abstract {
    @include leader(1); } }

// Responsive
//********************************************************
@include breakpoint-context($small-tablet) {

  .article_listing {
    padding-left: gutter-span()*3;
    padding-right: gutter-span()*3;

    .article_stub_item {
      width: 50% - gutter-span();
      display: inline-block;
      vertical-align: top;
      margin-left: gutter-span()/2;
      margin-right: gutter-span()/2; }

    .article_stub_link {
      height: 590px;

      .jobs &,
      .volunteer & {
        height: 390px; } }

    .article_stub_content {
      height: 540px;

      .jobs &,
      .volunteer & {
          height: 340px; } } } }

@include breakpoint-context($desktop) {
  .article_listing {
    padding-left: 0;
    padding-right: 0;

    .article_stub_item {
      width: 33% - gutter-span();

      .volunteer & {
          width: 50% - gutter-span(); } } } }
