.event-header {
  @include position(absolute, null null 0px 0px);
  width: 100%;
  text-align: center;
  padding-bottom: 20px; }

.event-header__title {
  color: #fff;
  margin-bottom: 15px; }

.event-header__date {
  padding: 12px 40px;
  display: inline-block;
  border-radius: 100px;
  color: #fff;
  background: #233e91;
  font-family: $heading-font-stack;
  font-size: 22px;
  margin-bottom: 15px; }

.event-header__copy {
  color: #fff;
  font-size: 18px;

  span {
    font-family: $heading-font-stack;
    font-size: 30px; } }


.event-header__session {
  @include position(absolute, null null 0px 50%);
  @include transform(translate(-50%, 50%));
  padding: 12px 40px;
  display: inline-block;
  background: #fff;
  border-radius: 100px;
  font-family: $heading-font-stack;
  font-size: 22px;
  white-space: nowrap; }

@include breakpoint-context($small-tablet) {
  .event-header {
    padding-bottom: 80px; } }
