.page-text {
  padding: 1em 20px;
  color: $white;

  &.green {
    background-color: $green; }
  &.blue {
    background-color: $brightblue; }
  &.purple {
    background-color: $purple; }
  &.red {
    background-color: $red; }
  &.yellow {
    background-color: $yellow;
    color: $purple;

    h2,
    h3 {
        color: $purple; } }

  &.text-center {
    text-align: center; }

  h2 {
    font-family: "Hayseed W00 Regular",Georgia,Times,Times New Roman,serif;
    color: $white;
    margin-bottom: 32px;
    font-size: 40px; }

  h3 {
    font-family: "Hayseed W00 Regular",Georgia,Times,Times New Roman,serif;
    color: $white;
    margin-bottom: 16px;
    font-size: 26px; }

  ul {
    list-style: initial;
    margin-left: 30px; }

  .button {
    @extend %button;
    max-width: 320px;
    margin: 50px auto 0; }

  .text-half {
    width: 50%;
    display: inline-block; }

  .page-header--no-margin {
    h1,
    h2,
    h3 {
      margin-bottom: 0; } }

  &--no-padding {
    padding: 0!important; }

  @include breakpoint-context($tablet) {
    padding: 5em 20px 6.66667em; } }
