.double_link_block_wrapper {
  position: relative;
  background-color: $dark-grey;
  width: 100%;

  .link_block_main_title {
    font-family: $heading-font-stack;
    @include box-sizing('border-box');
    @include border-radius(30px);
    @include adjust-font-size-to(24px);
    background-color: $purple;
    color: $white;
    position: absolute;
    display: inline-block;
    padding: 20px;
    z-index: 3;
    width: 240px;
    left: 50%;
    margin-left: -120px;
    top: 50px;
    text-align: center; }

  .link_block_detail {
    @include padding-leader(.4);
    position: relative;
    z-index: 1;
    margin-left: gutter-span();
    margin-right: gutter-span(); }

  .double_block_link {
    color: $white;
    h2 {
      color: $white; } }


  .link_block_content {
    &.yellow {
      h2,
      .double_block_link {
        color: $purple; } } } }



// Responsive
//********************************************************
@include breakpoint-context( 'all' );


@media (min-height: 800px) and (min-width: 961px) {

  .double_link_block_wrapper {

    .link_block_main_title {
      width: 300px;
      margin-left: -150px;
      top: 100px; }

    .link_block_wrapper {
      display: inline-block;
      width: 50%;
      margin-bottom: -10px;

      &.left_side,
      &.right_side {
        width: 50%; }

      &.left_side {
        .link_block_reveal {
          right: 50%; }
        .link_block_content_inner {
          right: 30px; } }

      &.right_side {
        .link_block_reveal {
          left: 50%; }
        .link_block_content_inner {
          left: 30px; } }
      .link_block_icon {
        right: 0;
        left: auto;
        top: -120px; } }

    .link_block_content_inner {
      max-width: 500px;
      position: absolute;
      top: 0; }

    .link_block_content {
      height: 160px; } } }

