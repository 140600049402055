.checkout {
  .ugc {
    margin-bottom: 48px; } }

.checkout-review {
  @include clearfix();
  margin-bottom: 48px;

  &__column {
    margin-bottom: 16px;

    @include breakpoint-context($small-tablet) {
      float: left;
      margin-left: 5px;
      margin-right: 5px;
      width: calc(50% - 10px);
      word-break: break-word; } } }
