// progress
//
// Markup: ./progress.hbs
//
// Styleguide blocks.progress

.progress {
    $this: &;
    text-align: center;

    &__label {
        color: rgba($mine-shaft, .8);
        font-size: rem(14);
        margin-bottom: 15px; } }
