// input upload
//
// Markup: ./input-upload.hbs
//
// Styleguide elements.form.input upload

.input-upload {
    $this: &;

    &:focus {
        #{$this}__label,
        #{$this}__label:hover {
            background-color: $cerulean;
            color: $white;
            outline: 1px dotted $mine-shaft;
            outline: -webkit-focus-ring-color auto 5px; } }

    &__input {
        height: .1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        width: .1px;
        z-index: -1;

        &.parsley-error {
            & + #{$this}__label {
                border-color: $error;
                color: $error; } } }

    &__label {
        align-items: center;
        background: $white;
        border: 2px solid $cerulean;
        border-radius: 14px;
        color: $cerulean;
        cursor: pointer;
        display: inline-flex;
        font-size: rem(18);
        font-weight: 700;
        padding: 10px;

        & * {
            pointer-events: none; } }

    &__icon {
        margin-right: 10px; }

    &__information {
        display: none;

        &--active {
            display: flex; } }

    &__name {
        color: $mine-shaft;
        display: inline-block;
        font-size: rem(12);
        font-weight: 600;
        margin-right: 5px;
        opacity: .8; }

    &__size {
        color: $mine-shaft;
        display: inline-block;
        font-size: rem(12);
        margin-right: 5px;
        opacity: .8; }

    &__remove {
        cursor: pointer;
        display: inline-block; } }
