.donation-cards {
  text-align: center;

  &__card {
    background-color: $white;
    box-shadow: 0 10px 10px 0 rgba($black, .4);
    display: inline-block;
    margin: 0 auto 32px;
    max-width: 400px;
    vertical-align: top; }

  &__media {
    display: block;
    width: 100%; }

  &__text {
    color: $black;
    padding: 30px 20px; }

  @include breakpoint-context($tablet) {
    &__card {
      width: 30.14288%;
      margin-left: 1.16279%;
      margin-right: 1.16279%; } } }
