#password_reset_form {
  .button {
    @extend %input_btn_arrow; } }



// Responsive
//********************************************************

@include breakpoint-context('all') {
  #password_reset_form {
    .button {
      margin-left: 0; } } }

@include breakpoint-context($tablet);

@include breakpoint-context($desktop);
