// hr
//
// Markup: ./hr.hbs
//
// Styleguide elements.hr

.hr {
    $this: &;
    background-color: $alto;
    border: 0;
    height: 1px;
    margin: 20px 0;
    width: 100%; }
