#outer_wrapper {
  .volunteer & {
    @extend %gradient_yellow; } }


#volunteer_opportunity_listing {
  @include padding-trailer(2);

  .article_stub_link {
    @include trailer(1);
    @include transition-property('padding');
    @include transition-duration(200ms);
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0;
    margin-right: 0; }

  .article_stub_content {
    @include transition-property('margin');
    @include transition-duration(200ms);
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
    background-color: $egg;
    padding: 20px;
    margin: 0;

    &:hover {
      background-color: $white;
      margin-top: -5px;
      margin-bottom: 5px; } }

  h2.article_stub_title {
    @include trailer(.2);
    display: inline-block; }

  .article_stub_abstract {
    color: $text-color; } }


// Responsive
//********************************************************

@include breakpoint-context($tablet);

@include breakpoint-context($desktop) {

  #volunteer_opportunity_listing {
    .article_stub_content {
      height: 180px;
      padding: 40px 60px; } } }
