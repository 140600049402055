.sitemap {
  #sub_nav {
    li {
      min-height: 30px; }

    a {
      &.menu_link {
        font-family: 'Helvetica W01 Roman', Arial, sans-serif;
        padding: 0;
        font-size: 18px; } }

    .dropdown {
      font-family: 'Helvetica W01 Roman', Arial, sans-serif;
      margin-left: 0; } } }
