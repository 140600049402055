#site_search {
  text-align: center;
  .field {
    margin: 0; }

  fieldset {
    overflow: visible;
    display: inline-block;
    width: 100%;
    max-width: 330px;
    margin: 0;
    padding: 0 7%;
    @include breakpoint-context($tablet) {
      padding: 0; } }

  @include trailer(1.5);
  label {
    display: none; }

  input,
  #site_search_submit {
    margin: 0;
    display: inline-block; }

  input {
    @extend %upcase;
    @include adjust-font-size-to(14px);
    @include box-shadow( none );
    vertical-align: top;
    padding: rhythm(0.6) 25px;
    height: 50px;
    background-color: $darkblue;
    color: $white;
    width: calc(100% - 85px); }

  // #TODO make this reusable
  ::-webkit-input-placeholder {
    color: $white; }
  -moz-placeholder: {
    color: $white;
    opacity: 1; }
  ::-moz-placeholder {
    color: $white;
    opacity: 1; }
  -ms-input-placeholder: {
    color: $white;
    opacity: 1; }

  .field {
    @include box-shadow( 0px 10px 10px 0px rgba(0,0,0,0.4));
    height: 50px;
    padding: 0;
    width: 100%; }

  #site_search_submit {
    @include svg-background('../images/icons/search');
    @include transition-duration(200ms);
    @include transition-property('background-color');
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $button-color;
    width: 85px;
    border: none;
    height: 0;
    padding-top: 50px;
    padding-bottom: 0;
    overflow: hidden;
    cursor: pointer;
    font-size: 0;
    &:hover {
      background-color: $button-hover-color; } } }


// Responsive
//********************************************************

@include breakpoint-context($tablet) {
  #site_search {
    margin-top: 40px; } }

@include breakpoint-context($desktop) {

  #site_search {
    position: absolute;
    margin-top: 0;
    top: -93px;
    left: 40%;
    margin-left: -150px;
    width: 300px;
    fieldset {
      width: 100%; }
    .field {
      float: right; } } }
