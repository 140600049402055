// Modal
//
// Markup: ./modal.hbs
//
// Styleguide layouts.modal

.modal {
    $this: &;
    @include position(fixed, 0 0 0 0);
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 999;

    &__dialog {
        box-sizing: border-box;
        height: 100%;
        padding: 20px;
        pointer-events: none;
        position: relative;
        width: auto;
        z-index: 200; }

    &__content {
        @include position(absolute, 50% null null 50%);
        background: $lily-white;
        background-clip: padding-box;
        border-radius: 14px;
        box-shadow: 1px 2px 8px 8px rgba($black, .01);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        outline: 0;
        overflow: hidden;
        padding: 20px;
        pointer-events: auto;
        transform: translate(-50%, -50%);
        width: calc(100% - 40px);

        @media(min-width: $small) {
            margin: auto;
            width: 80%; }

        @media(min-width: $medium) {
            max-height: 80vh;
            padding: 50px; }

        @media(min-width: $xlarge) {
            width: 1140px; } }

    &__scroller {
        box-sizing: border-box;
        height: 100%;
        margin: auto;
        max-height: 80vh;
        max-width: 720px;
        overflow: auto;
        padding: 10px;
        position: relative;
        width: 100%; }

    &__close {
        @include position(absolute, 25px 25px null null);
        align-items: center;
        background: transparent;
        border: 0;
        cursor: pointer;
        display: flex;
        fill: $cerulean;
        height: 40px;
        justify-content: center;
        width: 40px; }

    &__decoration {
        fill: $cerulean;
        height: 250px;
        opacity: .2;
        pointer-events: none;
        width: 250px;

        &--top-left {
            @include position(absolute, -110px null null -110px); }

        &--bottom-right {
            @include position(absolute, null -110px -110px null); } }

    &:after {
        @include position(fixed, 0 0 0 0);
        background-color: rgba($white, .9);
        content: '';
        z-index: 100; }

    &--open {
        display: block; }

    &--styleguide {
        position: relative;

        #{$this}__dialog {
            min-height: 500px; } } }
