// radio-toggle
//
// Markup: ./radio-toggle.hbs
//
// Styleguide components.radio-toggle

.radio-toggle {
    $this: &;
    display: inline-flex;

    &__label {
        cursor: pointer;
        display: block;
        padding: 13px 10px; }

    &__button {
        background: $white;
        border: 2px solid $cerulean;
        color: $cerulean;
        font-weight: 600;
        max-width: 50%;
        text-align: center;

        &:first-child {
            border-radius: 10px 0 0 10px; }

        &:last-child {
            border-radius: 0 10px 10px 0; }

        &--active {
            background: $cerulean;
            color: $white; } }

    @media(min-width: $medium) {
        &__button {
            width: 300px; } } }
