#event_gallery {
  @include padding-leader(1);
  @include padding-trailer(1);
  text-align: center;
  margin-left: gutter-span();
  margin-right: gutter-span();
  .heading {
    @include trailer(1, h(2));
    text-align: left; }
  .more_cta {
    @extend %upcase;
    @include box-shadow(0 5px 10px rgba(0,0,0,0.25));
    display: inline-block;
    padding: rhythm(0.5) 30px;
    color: #fff;
    background-color: $button-hover-color;
    &:hover,
    &:focus {
      background-color: $button-color; } } }

#event_gallery_images {
  @include trailer(1); }

.gallery_thumb_item {
  @include trailer(1);
  display: inline-block;
  width: 100%;
  text-align: center;
  vertical-align: center;

  .gallery_thumb_frame {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    .delete {
      @include svg-background('../images/icons/delete');
      @include border-radius(50%);
      width: 50px;
      padding-top: 50px;
      height: 0;
      overflow: hidden;
      position: absolute;
      right: -26px;
      top: -26px;
      background-color: $error-color;
      background-repeat: no-repeat;
      background-position: center center;
      border: 3px solid #fff; } } }

.gallery_thumb_link:hover,
.gallery_thumb_link:focus {
  .gallery_thumb {
    @include scale(1.2); } }

.gallery_thumb {
  @include box-shadow(0 5px 10px rgba(0,0,0,0.25));
  @include transition-duration(200ms);
  @include transition-property(transform);
  border: 5px solid #fff;
  vertical-align: middle; }


// Responsive
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@include breakpoint-context( $mobile-landscape ) {
  #event_gallery {
    margin-left: gutter-span();
    margin-right: gutter-span(); }
  .gallery_thumb_item {
    width: 50%; } }


@include breakpoint-context($small-tablet) {
  #event_gallery {
    margin-left: gutter-span();
    margin-right: gutter-span(); }
  .gallery_thumb_item {
    width: 33.3%; } }


@include breakpoint-context($tablet) {
  #event_gallery {
    margin-left: auto;
    margin-right: auto;
    width: column-span(5,0,6); }
  #event_gallery_images {
    margin-left: gutter-span()*-2;
    margin-right: gutter-span()*-2; } }


@include breakpoint-context($desktop) {
  #event_gallery {
    width: column-span(6.5,0,9); }
  #event_gallery_images {
    margin-left: gutter-span()*-4;
    margin-right: gutter-span()*-4; }

  .gallery_thumb_item {
    width: 25%; } }

