#user_order_details {
  @include padding-trailer(2);
  margin-left: gutter-span();
  margin-right: gutter-span();
  h2 {
    @include leader(.5); }
  dl {
    dd {
      color: $text-color; }
    dt {
      font-weight: bold; } } }


// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($small-tablet) {
  #user_order_details {
    dl {
      @include padding-trailer(.5);
      dt {
        float: left;
        clear: left;
        width: 150px; }
      dd {
        margin: 0 0 0 160px;
        padding: 0 0 0.5em 0; } } } }

@include breakpoint-context($tablet);
