// button
//
// .button--secondary - Secondary button colour
// .button--tertiary - Tertiary button colour
// .button--has-icon - When a button has an icon
// .button--wide - A wide button
// .button--inline - Flex inline
//
// Markup: ./button.hbs
//
// Styleguide elements.button

// button link
//
// Markup: ./button-link.hbs
//
// Styleguide elements.button link

.btn,
.button {
    $this: &;
    -webkit-appearance: none;
    align-items: center;
    background: $cerulean;
    border: 0;
    border-radius: 12px;
    color: $white;
    cursor: pointer;
    display: inline-flex;
    font-size: rem(18);
    font-weight: 600;
    justify-content: center;
    line-height: 24px;
    padding: 13px 15px;

    &:hover {
        color: $white;
        opacity: .8; }

    &__label {
        cursor: pointer; }

    &--secondary {
        background: $rose;

        &:hover {
            color: $white; } }

    &--tertiary {
        background: $apple;

        &:hover {
            color: $white; } }

    &--subtle {
        background: $lily-white;
        color: $allports;

        &:hover {
            color: $allports; } }

    &--inverse {
        background: $white;
        border: 2px solid $cerulean;
        color: $cerulean;

        &:hover {
            color: $cerulean; } }

    &--has-icon {
        display: flex;
        display: inline-flex;

        #{$this}__icon {
            fill: $cerulean;
            margin-right: 5px; } }

    &--wide {
        max-width: 100%;
        width: 240px; }

    &--full {
        box-sizing: border-box;
        width: 100%; }

    &--link {
        background: transparent;
        border: 0;
        color: $cerulean;
        cursor: pointer;
        display: inline-flex;
        font-weight: 500;
        padding: 0;
        width: auto;

        &:hover {
            color: darken($cerulean, 10%);

            #{$this}__icon {
                fill: darken($cerulean, 10%); } } }

    &--inline {
        display: inline-flex; }

    @media(min-width: $small) {
        width: auto;

        &--full {
            width: 100%; }

        &--wide {
            width: 240px; } } }

.button-group {
    .button {
        margin-bottom: 10px;

        & + .button {
            margin-left: 10px; } } }
