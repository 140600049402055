#overview_leader {
  @include padding-leader(2);
  @include padding-trailer(2); }

#overview_container {
  background-color: $yellow; // Old browsers
  @include filter-gradient($yellow, $darkyellow, vertical); // IE6-9
  @include background(linear-gradient(top, $yellow 40%, $darkyellow 100%));
  overflow: hidden;

  .center {
    @include padding-leader(4);
    @include padding-trailer(4);
    position: relative; }

  .icon_top,
  .icon_bottom {
    position: absolute;
    width: 140px;
    height: 140px;
    background-position: center center;
    background-repeat: no-repeat; }

  .icon_top {
    @include svg-background("../images/icons/yellow_icon1");
    top: -30px;
    left: -30px; }

  .icon_bottom {
    @include svg-background("../images/icons/yellow_icon2");
    bottom: -30px;
    right: -30px; }

  .overview_block {
    @include trailer(2);
    position: relative;
    .overview_abstract {
      margin-left: gutter-span();
      margin-right: gutter-span(); } }

  .button {
    @extend %button;
    @include leader(1);
    @include adjust-font-size-to(16px);
    width: 100%; }


  .odd {
    .button {
      background-color: $button-color;
      span.btn_icon {
        background-color: $darkblue;
        &:hover {
          background-color: $button-hover-color; } } } } }

// Responsive
//********************************************************
@include breakpoint-context( 'all' );

@include breakpoint-context($tablet) {
  #overview_leader {
    &.single_column {
      @include padding-leader(3);
      .page_title,
      .ugc {
        margin-left: auto;
        margin-right: auto;
        width: column-span(5,0,6); } } }


  #overview_container {
    .overview_block {
      margin-left: auto;
      margin-right: auto;
      width: column-span(4,0,6);

      .overview_abstract {
        margin-left: 0;
        margin-right: 0; } } } }


@include breakpoint-context($desktop) {

  #overview_leader {
    &.single_column {
      @include padding-leader(0);
      .page_title,
      .ugc {
        width: column-span(6.5,0,9); } } }


  #overview_container {
    .overview_block {
      margin-left: 0;
      margin-right: 0;
      display: inline-block;
      vertical-align: top;
      width: column-span(3.5,0,9);
      margin-left: column-span(1,0,9);

      .overview_abstract {
        height: 80px; } }

    .button {
      @include leader(.8);
      @include adjust-font-size-to(20px); } } }

@include breakpoint( 1200px ) {
  #overview_container {
    .icon_top {
      left: -90px; }
    .icon_bottom {
      right: -90px; } } }
