%button {
  @extend %upcase;
  @include box-sizing("border-box");
  @include svg-background('../images/icons/arrow_right', $background-size: 15px auto);
  @include adjust-font-size-to(20px);
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
  background-position: right 30px center;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 58px;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: $darkblue;
  color: $white;

  span.btn_icon {
    @include box-sizing("border-box");
    @include transition-property('background-color');
    @include transition-duration(200ms);
    background-color: $button-color;
    width: calc(100% - 80px);
    padding: 14px 0 16px 16px;
    display: inline-block;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    text-align: left; }

  &:hover {
    span.btn_icon {
      background-color: $button-hover-color; } } }

%input_btn_arrow {
  .svg & {
    background-image: url("../images/icons/input_btn_arrow_right.png");
    background-size: 90px 60px;
    padding-right: 90px; }
  background-position: top right;
  background-repeat: no-repeat;
  background-color: $brightblue;
  &:hover {
    background-color: $button-hover-color; } }


%input_btn_down_arrow {
  .svg & {
    background-image: url("../images/icons/input_btn_arrow_down.png");
    background-size: 90px 60px;
    padding-right: 90px; }
  background-position: top right;
  background-repeat: no-repeat;
  background-color: $brightblue;
  &:hover {
    background-color: $button-hover-color; } }





%follow-on-button {
  @extend %upcase;
  @include box-shadow(0 5px 10px rgba(0,0,0,0.25));
  @include box-sizing(border-box);
  @include adjust-font-size-to(14px);
  display: block;
  position: relative;
  background-color: $link-hover-color;
  color: #fff;
  padding: rhythm(0.5, 14px) 75px rhythm(0.5, 14px) 15px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  &:after {
    @include adjust-font-size-to(64px, 2, 14px);
    @include svg-background('../images/icons/arrow_right');
    content: "";
    position: absolute;
    height: 100%;
    width: 60px;
    top: 0;
    right: 0;
    background-color: $button-color;
    background-position: center center;
    background-repeat: no-repeat; }
  &:hover,
  &:focus {
    background-color: $link-color; } }
