// vertical tabs
//
// Markup: ./vertical-tabs.hbs
//
// Styleguide blocks.vertical tabs

.tabs {
    $this: &;
    border-bottom: 1px solid $alto;
    width: 100%;

    &__text {
        pointer-events: none;
        position: relative; }

    &__link {
        background: $affair;
        border-bottom: 4px solid transparent;
        bottom: -1px;
        box-sizing: border-box;
        color: $white;
        cursor: pointer;
        display: block;
        font-size: rem(18);
        font-weight: 700;
        line-height: 1.2;
        overflow: hidden;
        padding: 15px 10px;
        position: relative;
        text-decoration: none;

        &--active {
            color: $affair; }

        &:hover {
            color: $affair; } }

    &__content {
        height: 0;
        opacity: 0;
        pointer-events: none;

        &--active {
            height: auto;
            opacity: 1;
            pointer-events: all; } }

    &--dark {
        border-color: rgba($white, .2);

        #{$this}__link {
            color: $white;

            &--active {
                color: $affair; }

            &:hover {
                color: $affair; } } }

    @include breakpoint(medium, min) {
        display: flex;
        justify-content: center;

        &__link {
            flex: 1 1 100%;
            text-align: center;

            &--active {
                border-color: $affair;
                color: $affair; } } } }

.tabs-vertical {
    .select {
        display: none; }

    &__content {
        width: 100%; }

    .tabs {
        &__link {
            background-color: $affair;
            border-radius: 8px;
            bottom: 0;
            color: $white;
            overflow: hidden;
            padding: 20px 65px 15px 20px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--active {
                background-color: $rose;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                margin-bottom: 12px;
                position: relative;

                .tabs__arrow {
                    transform: translateY(-50%) rotate(180deg); } } }

        &__content {
            &--active {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-bottom: 12px;
                padding: 30px 0;
                position: relative;

                &:after {
                    @include position(absolute, 0 null null 0);
                    background: $white;
                    content: '';
                    height: 30px;
                    width: 100%; } } }

        &__group {
            border-radius: 8px;
            box-shadow: 2px 3px 16px rgba($black, .23667);
            margin-bottom: 12px; }

        &__arrow {
            @include position(absolute, 50% 22px null null);
            fill: $white;
            pointer-events: none;
            transform: translateY(-50%); } }

    @media(min-width: 768px) {
        border-radius: 8px;
        box-shadow: 2px 3px 16px rgba($black, .23667);
        display: flex;
        margin: 0 15px;
        overflow: hidden;
        padding: 0;

        .tabs {
            border-bottom: 0;
            flex-direction: column;

            &__link {
                border: 0;
                border-radius: 0;
                box-shadow: none;
                margin-bottom: 0;
                overflow: inherit;
                padding: 20px 40px;
                text-overflow: inherit;
                white-space: inherit;

                &--active {
                    background: $rose;

                    &:after {
                        content: none; } }

                .tabs__arrow {
                    transform: translateY(-50%) rotate(-90deg); } }

            &__content {
                width: auto;

                &--active {
                    box-shadow: none; } }

            &__group {
                border-radius: 0;
                box-shadow: none;
                margin-bottom: 0; } }

        &__container {
            background: $affair;
            min-width: 330px;
            padding: 40px 0; } }

    @media(min-width: 1280px) {
        &__container {
            min-width: 430px; }

        .tabs {
            &__content {
                &--active {
                    padding: 8%; } } } } }
