// field label
//
// .parsley-error - Error styling
//
// Markup: ./field-label.hbs
//
// Styleguide elements.form.field label

.field-label {
    display: block;
    font-size: rem(12);

    &.parsley-error {
        color: $error; } }

.control-label {
    display: block;
    font-size: rem(12); }
